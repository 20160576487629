/**
 * @class Market Transaction response after a purchase/cancel
 * @property All these field are not well documented by M1.
 *           https://developer.m1amdocs.com/api/
 * This an nested class with represents the backend response.
 * Should NOT be used OUTSIDE M1 APIS !!!
 */
export class M1TransactionResponse {
    constructor(props) {
        this.type = props.type
        this.status = props.status
        this.transactionId = props.transactionId // Used for cancellation
        // All these metadatas are inherited from 'purchaseAttribute' node
        this.productId = props.productId
        this.subscription = props.subscription || {}
        this.transaction = props.transaction || {} // TODO for splitting object values
    }

    static STATUS = {
        activating: 'activating',
        activated: 'activated',
        suspended: 'suspended',
        deactivated: 'deactivated',
        pending: 'pending',
        unknown: 'unknown',
    }

    /**
     * To be used for Debug and log
     */
    toString() {
        return `status: ${this.status} || ProductId: ${this.productId} || TransactionId: ${this.transactionId} || billingState: ${this.billingState} || subscriptionStatus: ${this.subscriptionStatus} || subscriptionStartDate: ${this.subscriptionStartDate} || subscriptionEndDate: ${this.subscriptionEndDate}`
    }
}

/*
============================== >Transaction API V2 Response ==============================
{
    "subscription": {
        "autoBillId": "AutoBill-23afb261-16f8-4365-ab1c-3b83bbc46f1e",
        "paymentMethod": "MerchantAcceptedPayment",
        "billingStatus": "Active",
        "billingState": "Good Standing",
        "startDate": "2021-10-19T15:24:51+00:00",
        "billingDay": 19,
        "nextBillingDate": "2021-11-19T00:00:00+00:00",
        "currency": "USD",
        "items": [
            {
                "productId": "nickplus-regular",
                "productDescription": "Regular Nick+ Access"
            }
        ]
    },
    "transaction": {
        "transactionId": "NICKT0100000069",
        "createdDate": "2021-10-19T15:24:51+00:00",
        "amount": 1,
        "currency": "USD",
        "items": [
            {
                "type": "Purchase",
                "name": "Recurring Charge, Billing Plan nick-monthly",
                "price": 0.5,
                "quantity": 1,
                "taxType": "Exclusive Sales",
                "subTotal": 0.5,
                "total": 0.5
            }
        ]
    }
}
============================== >Transaction API V2 Response with IAP ==============================
{
    "subscription": {
        "metadata": {
            "Order_Id": "GPA.3357-0078-0189-98810",
            "purchaseToken": "hhbpdnkojdigkajgjambhelh.AO-J1Ozd5h3dZSyx2NJAK7Hb68P3vLlB-lzz_Qhgm33yrFAHQadd9TdA_tkBmWE9C7eLgK2L1FTGg2jciSmbYXEsgHVMOk_d2eAwD0B76QqYIqmc3StNWjM",
            "quantity": "1",
            "productId": "nickplus.regular",
            "startTimeMillis": "1634848747599",
            "orderId": "GPA.3357-0078-0189-98810",
            "Product_Id": "nickplus.regular",
            "expiryTimeMillis": "1634849165296",
            "kind": "androidpublisher#subscriptionPurchase",
            "acknowledgementState": "1",
            "Transaction_Date": "1634848747599",
            "priceCurrencyCode": "EUR",
            "transactionId": "hhbpdnkojdigkajgjambhelh.AO-J1Ozd5h3dZSyx2NJAK7Hb68P3vLlB-lzz_Qhgm33yrFAHQadd9TdA_tkBmWE9C7eLgK2L1FTGg2jciSmbYXEsgHVMOk_d2eAwD0B76QqYIqmc3StNWjM",
            "purchaseType": "0",
            "priceAmountMicros": "11990000",
            "service": "google",
            "countryCode": "FR",
            "packageName": "com.ifeelsmart.iaptester",
            "paymentState": "1",
            "autoRenewing": "true",
            "status": "active",
            "expirationDate": "1634849165296"
        }
    }
}
============================== >Transaction API V1 Response ==============================
{
    "subscription": {
        "attributes": {
            "purchaseAttribute": [
                {
                    "id": 344,
                    "providerSubscriptionId": "6b701ae7fe7f283b7144be3b6cba346f89998402",
                    "internalProviderSubscriptionId": "AutoBill-2ce8d581-4957-409d-bdbd-d0432f08f0c4",
                    "productId": "Vidio Premium",
                    "subscriptionStartDate": 1634658911000,
                    "subscriptionEndDate": 1637539200000,
                    "maxUser": 5,
                    "productDescription": "Vidio premium monthly subscription",
                    "billingState": "UNBILLED",
                    "subscriptionStatus": "PROCESSING",
                    "nextSubscriptionBillingDate": 1637280000000,
                    "nextSubscriptionPrice": 58000,
                    "nextSubscriptionCurrency": "IDR",
                    "subscriptionCurrency": "IDR",
                    "purchaseSource": "MARKETPLACE",
                    "serviceType": "RECURRING",
                    "additionalFields": "{\"com.uxpsystems.ulm.subscription.PurchaseSource\":\"MARKETPLACE\"}",
                    "paymentMethod": {
                        "id": 199,
                        "paymentMethodId": "9911KUM02351",
                        "paymentMethodName": "f*****5",
                        "active": true,
                        "primary": true,
                        "billingAccountNumber": "9911KUM02351",
                        "classType": "com.uxpsystems.mint.attributes.ExternalBilling"
                    },
                    "additionalFieldsAsMap": {
                        "com.uxpsystems.ulm.subscription.PurchaseSource": "MARKETPLACE"
                    }
                }
            ]
        },
        "displayName": "Vidio premium monthly subscription",
        "id": 347,
        "status": "activated",
        "type": "vindicia.Vidiopremiummonthlysubscription"
    },
    "transaction": {
        "id": "XLA10019577",
        "created": "2021-10-19T15:55:11+00:00",
        "amount": 58000,
        "currency": "IDR",
        "metadata": {
            "vin:BillingPlanUnit": "month",
            "vin:BillingPlanLength": "1",
            "vin:BillingPlanDurationInSeconds": "0",
            "vin:FutureAmount": "58000.00"
        },
        "items": [
            {
                "item_type": "Exclusive Sales",
                "name": "Vidio monthly subscription",
                "price": 29000,
                "quantity": 1,
                "tax_type": "Exclusive Sales",
                "subtotal": 29000,
                "total": 29000
            },
            {
                "item_type": "Exclusive Sales",
                "name": "Vidio premium monthly subscription",
                "price": 29000,
                "quantity": 1,
                "tax_type": "Exclusive Sales",
                "subtotal": 29000,
                "total": 29000
            },
            {
                "item_type": "Exclusive Sales",
                "name": "Total Tax",
                "price": 0,
                "quantity": 1,
                "tax_classification": "TaxExempt",
                "tax_type": "Exclusive Sales",
                "discount": 0,
                "subtotal": 0,
                "total": 0
            }
        ],
        "subscription_sequence": 1,
        "billing_plan_sequence": 1,
        "original_billing_date": "2021-10-19T00:00:00+00:00"
    }
}
*/
