// import {logActionsMiddleware} from './middlewares/logActionsMiddleware'
import {getBrowserName, getDeviceData} from '../utils'
import storage from '../utils/storage'
import {logActionsMiddleware} from './middlewares/logActionsMiddleware'
import {getVoltApiInstance} from '../voltAPI'

export const getStore = async (configureStoreFactory, config, voltApi) => {
    const {ConfigurationApi, Constants, Models, VoltError} = voltApi

    // const getFirebaseAnalyticsInstance = () => {
    //     const firebase = Firebase.getInstance(config?.firebaseConfig)
    //     if (firebase?.analytics) {
    //         return new AnalyticsStoreWrapper(firebase?.analytics, true, firebase.emit, false)
    //     }
    // }

    // const voltAPI = new VoltApi({
    //     ...config,
    //     platform: getBrowserName(voltApi),
    //     analyticsFirebaseInstance: getFirebaseAnalyticsInstance(),
    // })

    const voltApiInstance: any = getVoltApiInstance(config)

    //await voltAPI.emiApi.getDynamicModules().toPromise()
    await voltApiInstance?.emiApi?.getDynamicModules().toPromise()

    const newConfig = {
        ...config,
        // emiModules: voltAPI.emiApi.config.emiModules,
        emiModules: voltApiInstance?.emiApi.config.emiModules,
    }

    const configureStore = configureStoreFactory({
        setUserStorageKey: userId => {},
        initialState: {},
        config: newConfig,
        Constants,
        ConfigurationApi,
        env: {},
        // eventListenergetter: client.helpers.getPlayerEventListener,
        isTablet: false,
        // licenseServerUrlGetter: client.helpers.getLicenseServerUrl,
        Models,
        OS: getBrowserName(voltApi),
        // streamUrlConverter: streamUrl => new Promise(() => streamUrl),
        // streamUrlConverter: client.helpers.convertStreamUrl,
        VoltError,
        assets: {
            images: {},
        },
        // customNodes: customNodes(),
    })

    return new Promise((resolve, reject) => {
        console.log('getStorePromise')
        resolve(
            configureStore({
                deviceProperties: {
                    platform: getBrowserName(voltApi),
                },
                isMobile: false,
                initialState: {},
                getApiInstance: () => voltApiInstance,
                crashLogger: {
                    registerUser: () => {},
                    unregisterUser: () => {},
                    log: () => {},
                    recordError: () => {},
                    crash: () => {},
                },
                errorLogger: undefined,
                isInternetReachable: () => Promise.resolve(true),
                OS: getBrowserName(voltApi),
                isTablet: false,
                exitApp: () => {},
                NavigationService: () => {},
                storage,
                screenDimensions: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
                debug: {
                    maxAge: 1000,
                    recordReduxChanges: true,
                },
                getDeviceData: () => new Promise((resolve, reject) => resolve(getDeviceData())),
                extraMiddlewares: [logActionsMiddleware],
                // enableReduxLogger: true, // for dev only})
            })
        )
    })
}
