import { Channel } from 'models'
import { IChannel } from '@typings/tile'
import { Abstract } from '../Abstract'

/**
 * factories exported from within Models
 */
export class Factories extends Abstract {
    /**
     * create a Channel object from it's properties
     * - used when restoring channels from local storage in degraded mode
     *
     * @param {IChannel} props all channel's properties
     */
    channelFactory = (props: IChannel) => new Channel(props)
}
