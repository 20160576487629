import { of } from 'rxjs'
import { map } from 'rxjs/operators'

/**
 * Deals with Channels (thanks captain obvious)
 */
export default class ChannelApi {
    constructor(config, { metaApi } = {}) {
        this.config = config
        this.metaApi = metaApi
    }
    /**
     * Get all channels data from Platform (auto-paginated)
     *
     * @param {Object} [opts]
     * @param {Number} [opts.pageSize=100] nb of items to fetch by page
     * @param {string} [opts.market] market of the user, specific to WOW
     * @param {string} [opts.subMarket] subMarket of the user, specific to WOW
     * @param {Channel.SOURCE_MODE} [opts.sourceMode] subMarket of the user, specific to WOW
     * @return {Observable<Array<ApiChannelData>>}
     */
    getChannelsData({ pageSize = 100, market, subMarket } = {}) {
        return of([])
    }

    /**
     * Get channels data by merging Proxy & Platform data that could be retrieved.
     *
     * Note: Are kept only channels which are known from the Platform, otherwise they are filtered out
     *
     * Return an Ajax Observable which emit identical returned structure from {@link ProxyApi#getChannels}
     *
     * @param {Object} [opts]
     * @param {Number} [opts.pageSize] pageSize used when fetch channels data from Kaltura platform
     * @param {string} [opts.market] market of the user, specific to WOW
     * @param {string} [opts.subMarket] subMarket of the user, specific to WOW
     * @param {string} [opts.tvProfile] User TV Profile (eligibility)
     * @param {Channel.SOURCE_MODE} [opts.sourceMode] subMarket of the user, specific to WOW
     * @return {Observable<ChannelsData>}
     */
    getChannels(opts) {
        // Stub security for testing
        if (this.config.useProxyChannelsOnly) {
            return this.metaApi.getChannels().pipe(
                map((proxyResult) => {
                    if (proxyResult && !!Object.keys(proxyResult).length) {
                        return Object.values(proxyResult).reduce((acc, channel) => {
                            acc[channel.id] = channel.update({
                                canWatch: true,
                                rollingBufferRetrieved: true,
                                rollingBufferPending: false,
                            })
                            return acc
                        }, proxyResult)
                    }

                    return {}
                })
            )
        }
        return of({})
    }

    /**
     * @param {String} profileId
     * @param {String} eventId
     * @return {Observable}
     */
    addReminder() {
        return of(true)
    }

    /**
     * @param {String} profileId
     * @param {String} eventId
     * @return {Observable}
     */
    removeReminder() {
        return of(true)
    }

    /**
     * @param {String} profileId
     * @param {Date|String|Number} startTime lower datetime boundary
     * @param {Date|String|Number} endTime upper datetime boundary
     * @return {Observable<ApiRemindersList>}
     */
    getReminderList() {
        return of({ reminders: [] })
    }

    /**
     * Get RollingBuffer data for a specific channelId
     *
     * @param {String|Number} channelId
     * @return {Observable<ApiRollingBufferData>}
     */
    getRollingBuffer() {
        return of({})
    }

    /**
     * Get favorites channels of the current user
     *
     * @return {Observable<Array<String>>} Observable which emit an Array of Platform Channel identifiers
     */
    getFavoritesChannel() {
        return of([])
    }

    /**
     * Set a channel as favorite for the current user
     *
     * @param {String|Number} platformChannelId
     * @return {Observable}
     */
    setFavoriteChannel(platformChannelId) {
        return of({})
    }

    /**
     * Remove a channel as favorite for the current user
     *
     * @param {String|Number} channelId
     * @return {Observable}
     */
    removeFavoriteChannel(channelId) {
        return of({})
    }

    /**
     * Retrieve currently broadcasted Live programs from metaApi
     *
     * @deprecated Could not works with available parameters on Maculosa startTimeStamp and endTimeStamp refers to the "begin" (ie broadcast time) and act as boundaries
     *
     * @param {Number} channelId Proxy channel identifier
     * @return {Observable} Observable, see return of {@link ProxyApi#getChannelsData}
     */
    getOnTvNow() {
        return of({})
    }

    /**
     * Gets restricted channel IDs
     *
     * @returns {Observable<Array<String>>} returns an array of restricted channel IDs
     */
    getChannelsRestriction() {
        return of([])
    }

    /**
     * Sets channel as restricted
     *
     * @param {Array<String>} channelIds - channel IDs
     * @param {Boolean} isLock - define if channel should be restricted or not
     * @returns {Boolean} returns true if setting is successful
     */
    setChannelRestriction(channelIds, isLock) {
        return of(true)
    }
}
