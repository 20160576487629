import {createIntl, createIntlCache} from '@formatjs/intl'
import {i18nProviderProps} from './i18nProviderProps'
import {i18nProviderContext} from './i18nProviderContext'

export const I18nProvider = (props: i18nProviderProps) => {
    const cache = createIntlCache()

    const intl = createIntl(
        {
            locale: props.locale,
            messages: props.messages,
        },
        cache
    )

    return <i18nProviderContext.Provider value={intl}>{props.children}</i18nProviderContext.Provider>
}
