import { of } from 'rxjs'

/**
 * Deal with Devices action (add/remove/get)
 */
export default class DeviceApi {
    /**
     * Get the account's device list containing
     * @param {Boolean} includeInactiveDevices Returns also inactive device
     * @returns {Observable<BackendDevice>}
     */
    getDeviceList({ includeInactiveDevices = false } = {}) {
        return of([])
    }

    /**
     * Retrieve the device of the user
     * @returns {Observable<BackendDevice>}
     */
    getUserDevice() {
        return of(null)
    }

    /**
     * Modifies the user's device, specifically device name
     *
     * @param {object} args
     * @param {object} args.deviceNewName New device name for modification
     * @param {object} args.deviceId The device id
     * @param {object} args.deviceType The device type according to Huawei device types
     * @returns Observable
     */
    modifyDevice = ({ deviceNewName, deviceId, deviceType }) => of(true)

    /**
     * Register a device in the backend
     * @param {Object} data
     * @param {Object} [data.screenDimensions]
     * @param {Object} [data.deviceName]
     * @returns {Observable<Boolean>}
     */
    addDevice({ screenDimensions, deviceName }) {
        return of(true)
    }

    /**
     * Delete a device in the backend
     * @param {Object} data
     * @param {Object} [data.backendDeviceId]
     * @returns {Observable<Boolean>}
     */
    deleteDevice({ backendDeviceId } = {}) {
        return of(true)
    }

    /**
     * This method verifies if the device exist in the backend
     * @param {String} deviceId Backend device ID
     * @returns {Observable<Boolean>} boolean indicating is the device exists or not
     */
    isExistingDevice({ deviceId } = {}) {
        return of(true)
    }

    /**
     * This method is called at the initialization to initialize the device service
     * In some backend it is the moment to create a device and initialize the list of the devices
     * @param {Object} deviceProperties
     * @param {Object} screenDimensions
     * @returns
     */
    initializeUserDevice({ deviceProperties, screenDimensions } = {}) {
        return of(true)
    }

    /**
     * This method sends keepAlive request to the backend
     */
    keepAliveDevice() {
        return of(true)
    }
}
