import {Context, createContext} from 'solid-js'
import {AuthorizationProviderBehavior} from './AuthorizationProvider'
import {AuthorizationHandler, AuthorizationType} from './authorizationHandler'
import {GRANT_TYPE_REFRESH_TOKEN} from '@openid/appauth'

export const AuthorizationContext: Context<AuthorizationProviderBehavior> = createContext({
    authorizationHandler: () =>
        new AuthorizationHandler({
            authorizationType: AuthorizationType.REDIRECT,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            allowGuest: false,
            authorityConfiguration: {
                authorization_endpoint: '',
                token_endpoint: '',
                revocation_endpoint: '',
                registration_endpoint: '',
            },
            clientId: '',
            clientSecret: '',
            redirectUri: '',
            scope: '',
        }),
})
