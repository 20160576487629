import { M1TransactionResponse } from '../models/M1TransactionResponse'
import { M1ApiVersion } from '../models/M1ApiVersion'

/**
 * Parse the response of the backend to determine if the transaction has succeeded or not (i.e Purchase, cancel)
 * @param {Object} data Response from Market One after a transaction
 * @returns {M1TransactionResponse}
 */
export const parseTransactionResponse = (data, apiVersion) => {
    if (M1ApiVersion.API_VERSION.MARKETONE_V2 === apiVersion) {
        const { subscription = {}, transaction = {} } = data || {}
        const { items = [], metadata } = subscription
        // Add more robustness on this condition while getting real APPLE payload (Unclear Heuristic : presence of metadata in the response = Google/Apple In App Purchase)
        if (metadata?.productId && metadata?.transactionId) {
            const { transactionId, productId } = metadata || {}
            // Google or Apple In App Purchase
            return new M1TransactionResponse({
                status: M1TransactionResponse.STATUS.activated,
                productId: productId,
                transactionId: transactionId, // By default, not a marketone transactionId
                subscription,
                transaction,
            })
        }
        // Regular purchase
        return new M1TransactionResponse({
            status:
                subscription.billingStatus === 'Active'
                    ? M1TransactionResponse.STATUS.activated
                    : M1TransactionResponse.STATUS.deactivated,
            productId: items.length > 0 && items[0].productId,
            transactionId: subscription.autoBillId,
            subscription,
            transaction,
        })
    }

    const { subscription = {}, transaction = {} } = data || {}
    const { status, /*type,*/ attributes: { purchaseAttribute = [] } = {} } = subscription
    return new M1TransactionResponse({
        status,
        transactionId: purchaseAttribute.length > 0 && purchaseAttribute[0].id,
        productId: purchaseAttribute.length > 0 && purchaseAttribute[0].productId,
        subscription,
        transaction,
    })
}
