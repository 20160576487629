import MockLogger from 'MockLogger'
import MockFirebaseAnalyticsInstance from 'MockFirebaseAnalyticsInstance'
import { from, of, forkJoin } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import Constants from 'api-constants'
import { isEmpty } from 'lodash'
import ConfigHelper from 'framework/helpers/config'

const FUNEL_SCREEN_PREFIX = 'Page_'
const CONTENT_CLICK = 'content_click'

const assertMandatory = (value, name) => {
    if (!value) throw new Error(`${name} is mandatory`)
}

const analyticsStorageKey = 'firebaseAnalyticsEnabled'
const supportedApis = [
    'setAnalyticsCollectionEnabled',
    'resetAnalyticsData',
    'logScreenView',
    'logEvent',
    'logAppOpen',
    'logSelectContent',
    'logSelectItem',
    'logSelectPromotion',
    'logTutorialComplete',
    'logTutorialBegin',
    'logSignUp',
    'logLogin',
    'logAppOpen',
    'logSearch',
    'setUserId',
    'setUserProperty',
    'setUserProperties',
    // 'setDefaultEventParameters',
    'setSessionTimeoutDuration',
]

/**
 * Wrapper to Firebase Analytics
 */
export default class analyticsFirebaseApi {
    constructor(config) {
        this.config = config
        this.analyticsApi = config.analyticsFirebaseInstance || MockFirebaseAnalyticsInstance
        this.userProperties = {}
        this.logger = (config.logger || MockLogger)
            .createChildInstance('analytics')
            .createChildInstance(this.analyticsApi.isStub ? 'stub' : 'firebase')

        assertMandatory(this.config.persistentStorage, 'persistentStorage')
        assertMandatory(this.analyticsApi, 'analyticsApi')
        supportedApis.forEach((api) => assertMandatory(this.analyticsApi[api], api))
    }

    /**
     * Retrieves User Consent dealing with Analytics
     * @returns {Observable<String>} Analytics user consent
     * - Constants.analytics.USER_CONSENT.YES
     * - Constants.analytics.USER_CONSENT.NO
     * - Constants.analytics.USER_CONSENT.PENDING
     */
    getAnalyticsUserConsent() {
        this.logger.info(`Getting user consent...`)
        return from(this.config.persistentStorage.getItem(analyticsStorageKey)).pipe(
            mergeMap((userConsent) => {
                if (userConsent === null) return of(Constants.analytics.USER_CONSENT.PENDING)
                return userConsent
            })
        )
    }

    /**
     * Save User consent for Analytics
     * @param {String} enabled Enable (Constants.analytics.USER_CONSENT.YES) or Disable (Constants.analytics.USER_CONSENT.NO)
     * @return Observable
     */
    _saveAnalyticsUserConsent(userConsent) {
        this.logger.info(`Getting user consent... ${userConsent}`)
        return from(this.config.persistentStorage.setItem(analyticsStorageKey, userConsent))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#resetAnalyticsData
     *
     * If true, allows the device to collect analytical data and send it to Firebase. Useful for GDPR.
     * @param {Boolean} enabled Enable (true) or Disable (false)
     * @return Observable
     */
    enableAnalyticsCollection = (enabled = true) => {
        this.logger.info(`${enabled ? '[ENABLE]' : '[DISABLE]'} Analytics Collection !!!`)
        return from(this.analyticsApi.setAnalyticsCollectionEnabled(enabled ? true : false)).pipe(
            mergeMap(() => {
                return this._saveAnalyticsUserConsent(
                    enabled
                        ? Constants.analytics.USER_CONSENT.YES
                        : Constants.analytics.USER_CONSENT.NO
                )
            })
        )
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#resetAnalyticsData
     *
     * Clears all analytics data for this instance from the device and resets the app instance ID.
     * @return Observable
     */
    resetAnalyticsData = () => {
        this.logger.info(
            `Clear Analytics Data (Clears all analytics data for this instance from the device and resets the app instance ID)`
        )
        return from(this.analyticsApi.resetAnalyticsData())
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logScreenView
     *
     * Sets or clears the screen name and class the user is currently viewing
     * @param {String} screenClass Current class associated with the view the user is currently viewing.
     * @param {String} screenName Screen name the user is currently viewing.
     * @return Observable
     */
    _logScreenView = ({ screenClass = 'MainActivity', screenName } = {}) => {
        this.logger.info(`[LOG] SCREEN VIEW => screenClass=${screenClass} screenName=${screenName}`)
        return from(
            this.analyticsApi.logScreenView({
                screen_class: screenClass,
                screen_name: screenName,
            })
        )
    }

    logScreenView = ({ screenClass = 'MainActivity', screenName = '' }) => {
        this.logger.info(`[LOG] SCREEN VIEW => screenClass=${screenClass} screenName=${screenName}`)
        return forkJoin([
            // We log both, the screen view using dedicated API
            this._logScreenView({ screenClass, screenName }),
            // Both also the following hack to be able to extract funnels from Google dashboard otherwise require Big Query to extract the datas
            // https://blog.theodo.com/2018/01/building-google-analytics-funnel-firebase-react-native/
            this.logEvent(FUNEL_SCREEN_PREFIX + (screenName || '').toLowerCase()),
        ])
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logEvent
     *
     * Log a custom event with optional params.
     * @param {String} eventName Event Name
     * @param {Object} [args] Optional parameters
     * @return Observable
     */
    logEvent = (eventName, args = undefined) => {
        this.logger.debug(`[LOG] EVENT => eventName=${eventName}, param: ${JSON.stringify(args)}`)
        const params = typeof args === 'object' ? this._parseCustomEvent(args) : undefined
        return from(this.analyticsApi.logEvent(eventName?.slice(0, 40), params)) // make sure the event name is not too long for firebase
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logAppOpen
     *
     * App Open event. By logging this event when an App is moved to the foreground, developers can understand
     * how often users leave and return during the course of a Session. Although Sessions are automatically reported,
     * this event can provide further clarification around the continuous engagement of app-users.
     * @return Observable
     */
    logAppOpen = () => {
        this.logger.debug(`[LOG] APP OPEN`)
        return from(this.analyticsApi.logAppOpen())
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logLogin
     *
     * Login event. Apps with a login feature can report this event to signify that a user has logged in.
     * @param {String} loginMethod Login Method
     * @return Observable
     */
    logLogin = (loginMethod = 'auth') => {
        this.logger.info(`[LOG] LOGIN: method: ${loginMethod}`)
        return from(this.analyticsApi.logLogin({ method: loginMethod }))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logSignUp
     *
     * Sign Up event. This event indicates that a user has signed up for an account in your app.
     * The parameter signifies the method by which the user signed up. Use this event to understand
     * the different behaviors between logged in and logged out users.
     * @param {String} signUpMethod Login Method
     * @return Observable
     */
    logSignUp = (signUpMethod = 'auth') => {
        this.logger.info(`[LOG] SIGNUP: method: ${signUpMethod}`)
        return from(this.analyticsApi.logSignUp({ method: signUpMethod }))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logTutorialBegin
     *
     * Tutorial Begin event. This event signifies the start of the on-boarding process in your app.
     * Use this in a funnel with analytics#logTutorialComplete to understand how many users complete
     * this process and move on to the full app experience.
     * @return Observable
     */
    logTutorialBegin = () => {
        this.logger.info(`[LOG] STARTER KIT BEGIN`)
        return from(this.analyticsApi.logTutorialBegin())
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logTutorialComplete
     *
     * Tutorial End event. Use this event to signify the user's completion of your app's on-boarding process.
     * Add this to a funnel with analytics#logTutorialBegin to understand how many users complete this process
     * and move on to the full app experience.
     * @return Observable
     */
    logTutorialComplete = () => {
        this.logger.info(`[LOG] STARTER KIT END`)
        return from(this.analyticsApi.logTutorialComplete())
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logSelectContent
     *
     * Select Content event. This general purpose event signifies that a user has selected some content of a certain type in an app.
     * The content can be any object in your app. This event can help you identify popular content and categories of content in your app.
     * @param {Object} args
     * @param {string} args.contentType Ex: 'VOD', 'REPLAY', 'EPG', 'CHANNEL'
     * @param {string} args.contentName Ex: 'Harry Potter V'
     * @param {string} args.uiLocation PPZ, SPZ, OTHER
     * @return Observable
     */
    logSelectContent = ({ contentId, contentType, contentName, uiLocation } = {}) => {
        const { useCustomEventContentSelection = true } =
            ConfigHelper.getInstance().getConfig('analytics')
        if (useCustomEventContentSelection) {
            this.logger.debug(`[LOG] CONTENT CLICK : ${contentType} = ${contentName}`)
            return this.logEvent(CONTENT_CLICK, {
                contentId,
                contentType,
                contentName,
                uiLocation,
            })
        }
        this.logger.debug(`[LOG] SELECT CONTENT : ${contentType} = ${contentName}`)
        return from(
            this.analyticsApi.logSelectContent({
                content_type: contentType,
                item_id: contentName,
            })
        )
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logSelectItem
     *
     * Select Item event. This event signifies that an item was selected by a user from a list.
     * Use the appropriate parameters to contextualize the event. Use this event to discover the most popular items selected.
     * @param {Object} args
     * @param {String} args.itemName Name of the Item within the list
     * @param {String} args.ListName Name of the list
     * @return Observable
     */
    logSelectItem = ({ itemName, ListName }) => {
        this.logger.debug(`[LOG] SELECT ITEM : itemName=${itemName} :: ListName=${ListName}`)
        return from(
            this.analyticsApi.logSelectItem({
                content_type: itemName,
                // The ID of the list in which the item was presented to the user
                item_list_id: ListName,
                // The name of the list in which the item was presented to the user
                item_list_name: ListName,
                items: [{ item_name: itemName }],
            })
        )
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logSelectPromotion
     *
     * Select promotion event. This event signifies that a user has selected a promotion offer.
     * Use the appropriate parameters to contextualize the event, such as the item(s) for which the promotion applies.
     * @return Observable
     * @param {Object} args
     * @param {String} args.itemName Name of the Item purchased
     */
    logSelectPromotion = ({ itemName }) => {
        this.logger.debug(`[LOG] SELECT PROMOTION : itemName=${itemName}`)
        return from(
            this.analyticsApi.logSelectPromotion({
                promotion_id: itemName,
                promotion_name: itemName,
                items: [{ item_name: itemName }],
            })
        )
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logPurchase
     *
     * E-Commerce Purchase event. This event signifies that an item(s) was purchased by a user.
     * Note: This is different from the in-app purchase event, which is reported automatically for Google Play-based apps
     * @param {Object} args
     * @param {String} args.itemId Id of the Item purchased
     * @param {String} args.itemName Name of the Item purchased
     * @param {String} [args.itemCategory] Optionally category of the iem
     * @param {Number} args.price Shipping cost.
     * @param {String} args.currency Purchase currency in 3 letter [ISO_4217](https://en.wikipedia.org/wiki/ISO_4217#Active_codes) format. E.g. `USD`.
     * @param {string} [args.coupon] Coupon code for a purchasable item. --> Not usable yet
     * @param {string} [args.affiliation] A product affiliation to designate a supplying company or brick and mortar store location
     * Ex: 'BACKEND OPERATOR', 'GOOGLE STORE', 'AMAZON'
     * @param {Number} [args.tax] Tax amount.
     * @param {String} [args.transactionId] A single ID for a e-commerce group transaction.
     * @return Observable
     */
    logPurchase = ({
        itemId,
        itemName,
        itemCategory,
        price,
        currency,
        affiliation = 'BACKEND OPERATOR',
        coupon,
        tax = 0,
        transactionId,
    }) => {
        this.logger.info(`[LOG] PURCHASE : Item: ${itemName} - Price: ${price} ${currency}`)
        return from(
            this.analyticsApi.logPurchase({
                items: [
                    {
                        item_id: itemId,
                        item_name: itemName,
                        item_category: itemCategory,
                        price,
                        quantity: 1,
                    },
                ],
                value: price,
                shipping: price,
                currency,
                affiliation,
                coupon,
                tax,
                transaction_id: transactionId,
            })
        )
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#logSearch
     *
     * Search event. Apps that support search features can use this event to contextualize search operations by supplying the appropriate,
     * corresponding parameters. This event can help you identify the most popular content in your app.
     * @param {Object} args
     * @param {String} args.searchTerm Name of the Item within the list
     * @return Observable
     */
    logSearch = ({ searchTerm }) => {
        this.logger.debug(`[LOG] SEARCH : searchTerm=${searchTerm}`)
        return from(
            this.analyticsApi.logSearch({
                search_term: searchTerm,
            })
        )
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#setUserId
     *
     * Gives a user a unique identification.
     * @param {String} uuid
     * @return Observable
     */
    setUserId = (uuid = undefined) => {
        this.logger.debug(`[SET] USER ID: ${uuid}`)
        return from(this.analyticsApi.setUserId(uuid))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#setUserProperty
     *
     * Sets a key/value pair of data on the current user. Each Firebase project can have up to 25 uniquely named (case-sensitive) user properties.
     * @param {string} key
     * @param {string} value
     * @return Observable
     */
    setUserProperty = (key, value) => {
        this.logger.debug(`[SET] USER PROPERTY : ${key} = ${value}`)
        if (this.userProperties[key] === value) {
            this.logger.debug(`Property ${key} already set...`)
            return of()
        }
        this.userProperties[key] = value
        return from(this.analyticsApi.setUserProperty(key, value))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#setUserProperties
     *
     * Gives a user a unique identification.
     * @param {Object} properties properties: { [key: string]: string | null }
     * @return Observable
     */
    setUserProperties = (properties) => {
        this.logger.debug(`[SET] USER PROPERTIES properties: ${JSON.stringify(properties)}`)
        this.userProperties = {
            ...this.userProperties,
            ...properties,
        }
        return from(this.analyticsApi.setUserProperties(properties))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#setDefaultEventParameters
     *
     * Adds parameters that will be set on every event logged from the SDK, including automatic ones.
     * @param {Object} args
     * @return Observable
     */
    setDefaultEventParameters = (args) => {
        this.logger.debug(`[SET] DEFAULT PARAMETERS`)
        return from(this.analyticsApi.setDefaultEventParameters(args))
    }

    /**
     * Documentation: https://rnfirebase.io/reference/analytics#setSessionTimeoutDuration
     *
     * Sets the duration of inactivity that terminates the current session.
     * @param {Number} milliseconds
     * @return Observable
     */
    setSessionTimeoutDuration = (milliseconds = 30 * 60 * 1000) => {
        this.logger.info(`[SET] SESSION TIMEOUT : ${milliseconds} ms`)
        return from(this.analyticsApi.setSessionTimeoutDuration(milliseconds))
    }

    /**
     * Parse Custum Event to prevent any unpected parameters which can causes a crash
     * @param {Object} params
     * @param {Boolean} isRootLevel=true
     */
    _parseCustomEvent(params, isRootLevel = true) {
        return Object.keys(params).reduce((acc, key) => {
            const value = params[key]
            if (!value && value !== 0) return acc
            if (value === Infinity) return acc

            const valueType = typeof value
            if (valueType === 'string' || valueType === 'number' || valueType === 'boolean') {
                acc[key] = value
            } else if (valueType === 'object' && !isEmpty(value)) {
                if (Array.isArray(value)) {
                    acc[key] = value.join(',')
                } else {
                    acc[key] = isRootLevel
                        ? this._parseCustomEvent(value, false)
                        : JSON.stringify(value)
                }
            }

            return acc
        }, {})
    }
}
