// ------------ REAL APIS ------------
import AuthApi from './AuthApi'
import UserPreferencesApi from './UserPreferencesApi'
import ChannelApi from './ChannelApi'
import FavouritesApi from './FavouritesApi'
import ProgramApi from './ProgramApi'
import PurchaseApi from './PurchaseApi'
import SettingsApi from './SettingsApi'
import CarePortalApi from './CarePortalApi'

// ------------ STUBS ------------
import ChannelApiStub from 'services/stub/ChannelApi'
import ProgramApiStub from 'services/stub/ProgramApi'
import PlayerApiStub from 'services/stub/PlayerApi'
import SettingsApiStub from 'services/stub/SettingsApi'
import FavouritesApiStub from 'services/stub/FavouritesApi'
import ImageApiStub from 'services/stub/ImageApi'
import RecordingsApiStub from 'services/stub/RecordingsApi'
import DeviceApi from 'services/stub/DeviceApi'
import ShoppingCartApi from './ShoppingCartApi'

/**
 * MARKET ONEDocumentation is available here :
 * https://developer.m1amdocs.com/api/
 */
export default class MarketOneApi {
    constructor(config, otherApis) {
        if (!otherApis.userPreferencesApi) {
            otherApis = {
                ...otherApis,
                userPreferencesApi: new UserPreferencesApi(config, otherApis),
                carePortalApi: new CarePortalApi(config, otherApis),
            }
        }
        this.userPreferencesApi = new UserPreferencesApi(config, otherApis)
        this.carePortalApi = new CarePortalApi(config, otherApis)
        this.authApi = new AuthApi(config, { ...otherApis })
        this.purchaseApi = new PurchaseApi(config, { ...otherApis, authApi: this.authApi })
        this.shoppingCartApi = new ShoppingCartApi(config, otherApis)
        // User Preferences API is not used by all Projects (XL Axiata) as we do not want to introduce new APIs during first major delivery validation
        // Make the use it contextual until we harmonize
        if (config.marketone && config.marketone.enableUserPreferencesApi) {
            this.channelApi = new ChannelApi(config, otherApis)
            this.programApi = new ProgramApi(config, otherApis)
            this.favouritesApi = new FavouritesApi(config, otherApis)
            this.settingsApi = new SettingsApi(config, otherApis)
        } else {
            this.channelApi = new ChannelApiStub(config, otherApis)
            this.programApi = new ProgramApiStub(config, otherApis)
            this.favouritesApi = new FavouritesApiStub(config, otherApis)
            this.settingsApi = new SettingsApiStub(config, otherApis)
        }

        // Remaining API stubbed
        this.playerApi = new PlayerApiStub(config, {
            ...otherApis,
            authApi: this.authApi,
            programApi: this.programApi,
        })
        this.imageApi = new ImageApiStub(config)
        this.recordingsApi = new RecordingsApiStub(config, otherApis)
        this.deviceApi = new DeviceApi(config, otherApis)
    }
}
