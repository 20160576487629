import { of, forkJoin, throwError } from 'rxjs'
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators'
import VoltError from 'VoltError'
import * as factories from '../factories'
import Constants from 'api-constants'
import OAuthDeviceFlow from 'framework/oauth2/deviceflow'
import ConfigHelper from 'framework/helpers/config'
import UserPreferencesApi from '../UserPreferencesApi'
import DataHelper from 'framework/helpers/data'
import jwt_decode from 'jwt-decode'
import AuthType from 'framework/helpers/auth'
import { ajax } from 'rxjs/ajax'

export default class AuthApi extends OAuthDeviceFlow {
    // True Means that activation of the subscription should be done using a deeplink (mostly used for STB as there are no browser embedded)
    // False means that activation should be done through email or browser
    constructor(config, { userPreferencesApi }) {
        super(config, 'marketone', {
            enableRefreshToken: !(config.marketone && config.marketone.skipRefreshToken),
        })
        this.config = config
        this.userPreferencesApi = userPreferencesApi
    }

    getProfile({ deviceProperties, screenDimensions }) {
        return this._authenticate().pipe(
            mergeMap(() => this.fetchProfile()),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.LOGIN_REQUIRED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    fetchProfile = () => {
        const accessToken = this.getAccessToken()
        if (!accessToken) {
            const error = 'Cannot get profile due to empty Access Token'
            this.logger.error('AuthApi', error)
            return throwError(
                new VoltError(VoltError.codes.USER_PROFILE_RETRIEVAL_FAILED, {
                    inheritedError: error,
                })
            )
        }

        const { enableUserPreferencesApi } = ConfigHelper.getInstance().getConfig('marketone')
        return forkJoin({
            userAccountsResponse: this.fetch({
                url: `${this.config.urls.apiUrl}/user/accounts`,
                method: 'GET',
                headers: {
                    Authorization: AuthType.BearerToken(accessToken),
                },
                log: 'GET USER ACCOUNT',
            }),
            userInfoResponse: enableUserPreferencesApi
                ? this.fetch({
                      url: `${this.config.urls.apiUrl}/user`,
                      method: 'GET',
                      headers: { Authorization: AuthType.BearerToken(accessToken) },
                      log: 'GET USER INFO',
                  })
                : of({}),
        }).pipe(
            mergeMap(
                ({
                    userAccountsResponse: { response: userAccount = [] } = {},
                    userInfoResponse: { response: userInfo = {} } = {},
                }) => {
                    let profile = factories.profileFactory(userAccount, userInfo, this.config)

                    if (profile && enableUserPreferencesApi) {
                        this.logger.info('[CUSTOM_DATAS] UserPreferences API enabled')
                        this.userPreferencesApi.setUserId(profile.userId)
                        // The Account ID here is needed for MarketONE Hybridization with Vubiquity to be used by Vubiquity Backend
                        // So before removing or changing this line, you should really be precocious and come with a solution avoiding regressions
                        DataHelper.getInstance().storeData([
                            [DataHelper.STORE_KEY.ACCOUNT_ID, profile.customerId],
                        ])

                        return this.userPreferencesApi.retrieveRemainingProfile().pipe(
                            mergeMap((extendedUserProfile) => {
                                profile.pcLevel = extendedUserProfile
                                    ? extendedUserProfile[
                                          UserPreferencesApi.USER_PREFERENCES.PARENTAL_LEVEL
                                      ]
                                    : profile.pcLevel

                                return of(profile)
                            })
                        )
                    }
                    return of(profile)
                }
            ),
            catchError((err) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PROFILE_RETRIEVAL_FAILED, {
                        inheritedError: err,
                    })
                )
            })
        )
    }

    /**
     * Login exteranlized though App Auth.
     * We receive only the oauthToken
     * @param {Object} data
     * @param {String} data.oauthToken Oauth Token
     * @param {String} data.oauthRefreshToken Refresh token
     * @returns {Observable<String>} Constants.authenticationStatus values
     */
    login({ oauthToken, oauthRefreshToken }) {
        if (!oauthToken) {
            return throwError(
                new VoltError(VoltError.codes.LOGIN_FAILED, {
                    extraLog: 'Cannot login. Oauth TOKEN not provided by OIDC + PKCE',
                })
            )
        }
        const refreshTokenNotRequired =
            this.config.marketone && this.config.marketone.skipRefreshToken
        if (!oauthRefreshToken && !refreshTokenNotRequired) {
            return throwError(
                new VoltError(VoltError.codes.LOGIN_FAILED, {
                    extraLog: 'Cannot login. Refresh TOKEN not provided by OIDC + PKCE',
                })
            )
        }
        // Use timestamp rather than Date to avoid UTC conversion error
        let jwtExpirationTimestampSecs
        try {
            const jwtToken = jwt_decode(oauthToken)
            jwtExpirationTimestampSecs = jwtToken.exp
        } catch (error) {
            this.logger.error('[JWT Error] Cannot decode token', error)
            return throwError(
                new VoltError(VoltError.codes.LOGIN_FAILED, {
                    extraLog: 'Cannot login. The Access Token provided is not a JWT Token',
                })
            )
        }

        // If attributes provided, then we implicitly consider the user logged in
        return DataHelper.getInstance()
            .clearAllData()
            .pipe(
                mergeMap(() => {
                    const storagePairs = [
                        [DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN, oauthToken],
                        [
                            DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_EXPIRATION_TIME_MS,
                            jwtExpirationTimestampSecs * 1000,
                        ],
                        [DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_ISSUED_TIME_MS, Date.now()],
                    ]

                    if (!refreshTokenNotRequired) {
                        storagePairs.push([
                            DataHelper.STORE_KEY.PRIMARY_REFRESH_TOKEN,
                            oauthRefreshToken,
                        ])
                    }

                    return DataHelper.getInstance()
                        .storeData(storagePairs)
                        .pipe(
                            switchMap(() => of(Constants.authenticationStatus.SUCCEEDED)),
                            tap(() => {
                                if (
                                    this.config.marketone &&
                                    this.config.marketone.skipRefreshToken
                                ) {
                                    return
                                }
                                this._startAccessTokenRefreshTimer()
                            })
                        )
                })
            )
    }

    /**
     * Please refer to parent method for documentation
     */
    loginWithSecondaryDevice({ deviceId }) {
        let body = {
            'com.uxpsystems.device_id': deviceId,
            client_id: this.oauthConfig.clientId,
            scope: this.oauthConfig.param.scope,
        }
        if (this.oauthConfig.param.debugMode) {
            body = { ...body, 'x-process-debug': this.oauthConfig.param.debugMode }
        }
        return super.loginWithSecondaryDevice({
            deviceId,
            forceHeaders: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            forceBody: body,
        })
    }

    /**
     * Please refer to parent method for documentation
     */
    isLoginWithSecondaryDevice() {
        return super.isLoginWithSecondaryDevice({ isUsingParamPayload: true })
    }

    logout() {
        const accessToken = DataHelper.getInstance().getPrimaryAccessToken()

        if (!accessToken) {
            this.logger.warn('Logout: No access token found')
            return super.logout()
        }

        const clientId = this.config.clientId

        if (!clientId) {
            this.logger.warn('Logout: No client id found')
            return super.logout()
        }

        return this.fetch({
            url: `${this.config.urls.authUrl}/oidc/revoke?client_id=${clientId}&token=${accessToken}`,
            method: 'POST',
            headers: {
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_WWW_FORM_URLENCODED,
            },
            log: 'REVOKE TOKEN',
        }).pipe(
            mergeMap(() => {
                return super.logout()
            }),
            catchError((error) => {
                this.logger.warn('Error during logout... wipe cache and return success', error)
                return super.logout()
            })
        )
    }

    getIsOutOfHome() {
        return of(false)
    }

    /**
     * Get the "suspended" state of current user
     *
     * @returns {Observable<boolean>} An observable which emit a boolean (true = is suspended otherwise false)
     */
    getIsSuspended() {
        return of(false)
    }

    initGuestMode() {
        return of(true)
    }

    isBackendAvailable = () => {
        if (!this.config.urls.authUrl) return of(false)
        return ajax({
            url: this.config.urls.authUrl,
            method: 'GET',
            timeout: this.config.degradedMode.bootTimeout,
        }).pipe(
            mergeMap(({ status }) => {
                return of(status === 200)
            }),
            catchError(() => {
                return of(false)
            })
        )
    }

    onboardAndAuthenticateStartProcessV2 = () => {
        const url = `${this.config.urls.apiUrl}/process/start/authentication.OnboardAndAuthenticate.v2.0?create-password-prompt=false`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[ONBOARD AND AUTHENTICATE PROCESS START]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.ONBOARD_AND_AUTHENTICATE_START_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    getUserInfoUpdate = () => {
        console.log(' getUserInfoUpdate getUserInfoUpdate getUserInfoUpdate ==>')
        const url = `${this.config.urls.apiUrl}/user`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[UPDATE USER INFO AFTER UPDATE EMAIL]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.UPDATE_GET_USER_INFO_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    oidcAuthorize = (payload) => {
        const { pkceChallenge, redirectUrl, state, nonce } = payload
        const client_id = this.config.clientId
        const url = `${this.config.urls.authUrl}/oidc/authorize?code_challenge=${pkceChallenge}&code_challenge_method=S256&response_type=code&client_id=${client_id}&redirect_uri=${redirectUrl}&state=${state}&nonce=${nonce}`
        return this.fetch({
            url,
            method: 'GET',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            log: `[OIDC AUTHORIZED PROCESS START]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.OIDC_AUTHORIZED_PROCESS_START_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    oidcToken = (payload) => {
        const client_id = this.config.clientId
        payload = {
            ...payload,
            client_id,
        }
        const url = `${this.config.urls.authUrl}/oidc/token`
        return this.fetch({
            url,
            method: 'POST',
            headers: {
                'Content-Type': Constants.httpHeader.ContentType.APPLICATION_WWW_FORM_URLENCODED,
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
            },
            body: payload,
            log: `[OIDC AUTHORIZED TOKEN PROCESS START]`,
        }).pipe(
            mergeMap((response) => {
                return of(response)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.OIDC_AUTHORIZED_TOKEN_PROCESS_START_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }
}
