import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import Fetch from '../fetch'
import Constants from 'api-constants'
import UserPreferencesApi from '../UserPreferencesApi'

export default class ProgramApi extends Fetch {
    static TAG = 'ProgramApi'
    /**
     * @return {Observable<ContinueWatchingData>} Continue watching data
     */
    getContinueWatching() {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.BOOKMARKS
            )
        ) {
            return of({ all: [], vod: [], programs: [] })
        }
        this.logger.info(ProgramApi.TAG, `Getting continue watching ...`)

        return this.userPreferencesApi
            .getUserPreferencesList(UserPreferencesApi.USER_PREFERENCES.BOOKMARKS)
            .pipe(
                map((result) => {
                    const bookmarks = result || []
                    this.logger.info(ProgramApi.TAG, `Received ${bookmarks.length} bookmarks...`)

                    return bookmarks.reduce(
                        (acc, bookmark) => {
                            if (bookmark && bookmark.id) {
                                acc.programs.push({ id: bookmark.id, position: bookmark.position })
                                acc.all.push(bookmark.id)
                                // Keep this for the legacy but should not be necessary
                                switch (bookmark.program_type) {
                                    case Constants.programType.vod:
                                    case Constants.programType.movie:
                                    case Constants.programType.season:
                                    case Constants.programType.series:
                                        acc.vod.push(bookmark.id)
                                        break
                                    case Constants.programType.live:
                                        acc.epg.push(bookmark.id)
                                        break
                                    case Constants.programType.channel:
                                        acc.channel.push(bookmark.id)
                                        break
                                    case Constants.programType.dvr:
                                        acc.dvr.push(bookmark.id)
                                        break
                                    case Constants.programType.replay:
                                        acc.replay.push(bookmark.id)
                                        break
                                    default:
                                        acc.unknown.push(bookmark.id)
                                        break
                                }
                            }
                            return acc
                        },
                        {
                            all: [],
                            vod: [],
                            dvr: [],
                            replay: [],
                            epg: [], // Non sense - Should be considered as an error
                            channel: [], // Non sense - Should be considered as an error
                            unknown: [], // Non sense - Should be considered as an error
                            programs: [],
                        }
                    )
                })
            )
    }

    /**
     * Set a bookmark for a specific Title identifier.
     *
     * @param {Object} options
     * @param {String} options.action Bookmark action parameter, must be one of ["LOAD", "FIRST_PLAY", "PLAY", "PAUSE", "HIT", "STOP"]
     * @param {Number} options.bookmark In seconds
     * @param {String} options.programType Program type associated to the bookmark (one of `Constants.programType`)
     * @param {Boolean} options.isTrailer Indicates whether it is a trailer
     * @param {Number} options.viewingDuration The difference between the start time and the end time (in seconds)
     * @param {String} options.titleId
     * @param {Boolean} options.isFinished Indicates whether the program is considered finished
     * @param {Number} options.duration Duration of the program
     *
     * @returns {Observable<Boolean>} Observable which emit a boolean, true = bookmark supported, false = bookmark not supported
     */
    setBookmark({
        action,
        bookmark,
        programType,
        isTrailer,
        viewingDuration,
        titleId,
        isFinished,
        duration: programDuration, // duration of the program, not the viewing session
    }) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.BOOKMARKS
            )
        ) {
            return of(true)
        }

        if (programType !== Constants.programType.vod) {
            this.logger.info('Bookmarks are only available for VOD programs')
            return of(false)
        }

        this.logger.info(
            ProgramApi.TAG,
            `Setting Bookmark [Content:${titleId}][Position:${bookmark}][Type:${programType}]...`
        )

        return this.userPreferencesApi.updateUserPreferencesItemInList(
            UserPreferencesApi.USER_PREFERENCES.BOOKMARKS,
            {
                id: titleId,
                position: bookmark,
                program_type: programType,
                program_duration: programDuration,
                finished: isFinished,
            },
            `id eq "${titleId}"`
        )
    }

    // -------------------------------------------------------------------- //
    // ----[STUBBED FOR MARKET ONE - API NOT MANAGED BY THIS BACKEND]------ //
    // -------------------------------------------------------------------- //
    getProgramPlatformId(streamId, isRecording = false, userAgent) {
        const data = {
            playBackPlatformId: streamId,
            duration: null,
            channel: null,
        }
        return of(data)
    }

    getPrograms(titleIds) {
        return of({})
    }

    getRelatedRequests(program) {
        return of({})
    }

    getLiveSeriesData(seriesIds) {
        return of([])
    }

    getRelated() {
        return of({ programs: [], done: true })
    }

    getTrending() {
        return of({ programs: [], done: true })
    }

    getRecommended() {
        return of({ programs: [], done: true })
    }

    /**
     * Not implemented for marketone
     * @returns {Observable}
     */
    getDetails() {
        return of(false)
    }
    // -------------------------------------------------------------------- //
    // ---------------------------END STUB -------------------------------- //
    // -------------------------------------------------------------------- //
}

/**
 * @typedef {Object} ProgramPlatformData
 * @property {String} playBackPlatformId Platform identifier of content to use for playback
 * @property {Number} duration In seconds !
 * @property {String|null} channel Channel platform identifier (if title is a recording)
 */

/**
 * @typedef {Object} ContinueWatchingData
 * @property {Array<String>} all Platform Title identifiers of ContinueWatching programs (Dvr/Epg and Vod)
 * @property {Array<String>} vod Platform Title identifiers of ContinueWatching Vod
 * @property {Array<String>} replay Platform Title identifiers of ContinueWatching replay
 * @property {Array<String>} dvr Platform Title identifiers of ContinueWatching dvr
 * @property {Array<String>} epg Platform Title identifiers of ContinueWatching epg (NON SENSE - Should be treated as error)
 * @property {Array<String>} channel Platform Title identifiers of ContinueWatching channel (NON SENSE - Should be treated as error)
 * @property {Array<String>} unknown Platform Title identifiers of ContinueWatching unknown (NON SENSE - Should be treated as error)
 * @property {Object.<String, Number>} programs Key/value hash with key = program id and value = bookmark position (number, in seconds)
 */
