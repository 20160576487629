import { of, throwError } from 'rxjs'
import VoltError from 'VoltError'
import { DvrQuota, Profile } from 'models'

export default class RecordingsApi {
    /**
     * @returns {Observable<Array>}
     */
    getEventRecordings() {
        return of([])
    }

    /**
     * @returns {Observable<Array>}
     */
    getSeriesRecordings() {
        return of([])
    }

    /**
     * @returns {Observable<Array>}
     */
    getRecordingsData() {
        return of([])
    }

    /**
     * @returns {Observable<Array>}
     */
    getFavoriteRecordings() {
        return of([])
    }

    /**
     * @returns {Observable}
     */
    addFavoriteRecording() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    deleteFavoriteRecording() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    setEventRecording() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    setSeriesRecording() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    cancelRecording() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    deleteRecording() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    modifyRecordingType() {
        return throwError(new VoltError(VoltError.codes.MISSING_API_METHOD_IMPLEMENTATION))
    }

    /**
     * @returns {Observable}
     */
    getStorage() {
        return of(
            new DvrQuota({
                unit: Profile.DVR_QUOTA_UNIT.HOUR,
                dvrQuota: 0,
                dvrQuotaRemaining: 0,
            })
        )
    }
}
