import { Program } from './Program'
import { IDvrSeries } from '@typings/tile'
import Constants from 'api-constants'

/**
 * A DVR series comprising of multiple {@link Dvr} episodes
 * @property {String|Number} seriesId Platform ID of the series
 * @property {Number} lastRecorded Timestamp of the start time of the latest available episode recording
 * @property {Number} nextScheduledRecording Timestamp of the next scheduled episide recording
 * @property {Array<String|Number>} children IDs of the episodes ({@link Dvr}) belonging to the series
 * @property {Boolean} childrenFetched Flag indicating whether the episodes of the series have been fetched
 * @property {Array<String>} genres List of genres
 */
export class DvrSeries extends Program {
    seriesId: string | number
    lastRecorded: number
    nextScheduledRecording: number
    onlyNewEpisodes: boolean
    originalAssetId: string
    originalAssetTitle: string
    children: (string | number)[]
    childrenFetched: boolean
    genres: string[]
    modelType: string
    status: string

    constructor(props: IDvrSeries) {
        super(props)

        this.seriesId = props.seriesId
        this.lastRecorded = props.lastRecorded
        this.nextScheduledRecording = props.nextScheduledRecording
        this.format = 'landscape'
        this.onlyNewEpisodes = props.onlyNewEpisodes
        this.originalAssetId = props.originalAssetId
        this.originalAssetTitle = props.originalAssetTitle

        this.status = props.status

        const computePropValue = this._makeComputePropValue(props)
        this.children = computePropValue('children', [])
        this.childrenFetched = computePropValue('childrenFetched', false)
        this.genres = computePropValue('genres', [])

        this.modelType = Constants.programType.dvrSeries
    }

    /**
     * @returns {Boolean} always true
     */
    isDvrSeries() {
        return true
    }

    /**
     * Indicates whether the series has atleast one episode scheduled
     * @returns {Boolean}
     */
    isScheduled() {
        return Date.now() < this.nextScheduledRecording
    }

    /**
     * Indicates whether the series has atleast one episode available
     * @returns {Boolean}
     */
    isAvailable() {
        return Date.now() >= this.lastRecorded
    }

    /**
     * Indicates whether the series has atleast one episode ongoing
     * @returns {Boolean}
     */
    isOngoing() {
        return Date.now() >= this.nextScheduledRecording
    }
}
