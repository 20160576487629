export default (props = {}) => <svg width="48" height="33" viewBox="0 0 48 33" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g clip-path="url(#clip0_26458_18677)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.5C0 1.25736 1.00736 0.25 2.25 0.25H45.75C46.9926 0.25 48 1.25736 48 2.5V29.5C48 30.7426 46.9926 31.75 45.75 31.75H2.25C1.00736 31.75 0 30.7426 0 29.5V2.5Z" fill="url(#paint0_linear_26458_18677)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.5C0 1.25736 1.00736 0.25 2.25 0.25H45.75C46.9926 0.25 48 1.25736 48 2.5V29.5C48 30.7426 46.9926 31.75 45.75 31.75H2.25C1.00736 31.75 0 30.7426 0 29.5V2.5Z" fill="#E5E5E5" fill-opacity="0.01"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.58364 17.3371C9.24588 17.6442 8.80696 17.7781 8.11227 17.7781H7.82371V14.1102H8.11227C8.80696 14.1102 9.22863 14.2353 9.58364 14.5589C9.95547 14.8919 10.1791 15.4083 10.1791 15.9395C10.1791 16.472 9.95547 17.0041 9.58364 17.3371ZM8.32827 13.1704H6.75V18.7168H8.31996C9.15478 18.7168 9.75768 18.5188 10.2867 18.0765C10.9156 17.553 11.2874 16.7638 11.2874 15.9476C11.2874 14.3106 10.072 13.1704 8.32827 13.1704Z" fill="#212D34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.7817 18.717H12.857V13.1704H11.7817V18.717Z" fill="#212D34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.4869 15.2991C14.8416 15.0589 14.6523 14.9005 14.6523 14.6007C14.6523 14.2515 14.9898 13.986 15.4532 13.986C15.7752 13.986 16.0402 14.1192 16.3202 14.4351L16.8829 13.6939C16.4205 13.287 15.8674 13.0791 15.2631 13.0791C14.2877 13.0791 13.5438 13.7605 13.5438 14.6682C13.5438 15.4324 13.8901 15.8237 14.9 16.1892C15.321 16.3388 15.5352 16.4382 15.6432 16.5051C15.8581 16.6463 15.9656 16.8462 15.9656 17.0792C15.9656 17.5285 15.6106 17.8615 15.1308 17.8615C14.618 17.8615 14.2048 17.6033 13.9574 17.1216L13.2627 17.7947C13.758 18.5265 14.3531 18.8507 15.1713 18.8507C16.2885 18.8507 17.0724 18.103 17.0724 17.0295C17.0724 16.1481 16.7099 15.7493 15.4869 15.2991Z" fill="#212D34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.4111 15.9474C17.4111 17.5777 18.6834 18.8418 20.3206 18.8418C20.7834 18.8418 21.1799 18.7503 21.6687 18.5186V17.2451C21.2389 17.678 20.8582 17.8526 20.3707 17.8526C19.2878 17.8526 18.5192 17.0626 18.5192 15.9393C18.5192 14.8745 19.312 14.0345 20.3206 14.0345C20.8334 14.0345 21.2217 14.2186 21.6687 14.6585V13.3856C21.1968 13.1448 20.8087 13.0449 20.346 13.0449C18.717 13.0449 17.4111 14.3347 17.4111 15.9474Z" fill="#212D34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.1939 16.896L28.724 13.1704H27.5493L29.8885 18.8593H30.4671L32.8485 13.1704H31.6829L30.1939 16.896Z" fill="#212D34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.334 18.717H36.3832V17.778H34.4084V16.2808H36.3106V15.3413H34.4084V14.1103H36.3832V13.1704H33.334V18.717Z" fill="#212D34"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M38.4836 15.7235H38.1694V14.0437H38.5006C39.1704 14.0437 39.5343 14.3259 39.5343 14.8657C39.5343 15.4233 39.1704 15.7235 38.4836 15.7235ZM40.6409 14.8075C40.6409 13.7691 39.93 13.1699 38.6902 13.1699H37.0957V18.7165H38.1697V16.4884H38.31L39.7982 18.7165H41.1205L39.3851 16.3799C40.195 16.2141 40.6409 15.6567 40.6409 14.8075Z" fill="#212D34"/>
<g filter="url(#filter0_i_26458_18677)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.8843 15.9627C27.8843 17.5924 26.5714 18.9134 24.9518 18.9134C23.3322 18.9134 22.0195 17.5924 22.0195 15.9627C22.0195 14.3331 23.3322 13.0122 24.9518 13.0122C26.5714 13.0122 27.8843 14.3331 27.8843 15.9627Z" fill="url(#paint1_linear_26458_18677)"/>
</g>
</g>
<defs>
<filter id="filter0_i_26458_18677" x="22.0195" y="13.0122" width="5.86475" height="5.90137" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dx="0.75" dy="0.75"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_26458_18677"/>
</filter>
<linearGradient id="paint0_linear_26458_18677" x1="-24" y1="16" x2="4.89829" y2="60.0355" gradientUnits="userSpaceOnUse">
<stop stop-color="#EDF1F3"/>
<stop offset="1" stop-color="#D3DADF"/>
</linearGradient>
<linearGradient id="paint1_linear_26458_18677" x1="28.5441" y1="16.5268" x2="25.0643" y2="12.4113" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA700"/>
<stop offset="1" stop-color="#E75A10"/>
</linearGradient>
<clipPath id="clip0_26458_18677">
<rect width="48" height="32" fill="white" transform="translate(0 0.25)"/>
</clipPath>
</defs>
</svg>
