import {AnalyticsStoreWrapper, getBrowserName} from './utils'
import voltApi from 'client-api'
import {analyticsInstance} from './utils/analytics/analyticsInstance'

let voltApiInstance = null

// THIS is a logger instance for the volt API . make sure to adapt it as needed
const Logger = {
    createChildInstance: a => Logger,
    trace: console.log,
    debug: console.log,
    log: console.log,
    info: console.log,
    warn: console.log,
    error: console.log,
}

const getFirebaseAnalyticsInstance = config => {
    const _analyticsInstance = analyticsInstance(config)
    if (_analyticsInstance?.analytics) {
        return new AnalyticsStoreWrapper(_analyticsInstance?.analytics, true, _analyticsInstance.emit, false)
    }
    return null
}

export const getVoltApiInstance = config => {
    const {VoltApi} = voltApi
    // if (!voltApiInstance) {
    //     voltApiInstance = new VoltApi({
    //         ...config,
    //         platform: getBrowserName(voltApi),
    //         analyticsFirebaseInstance: getFirebaseAnalyticsInstance(config),
    //     })
    // }

    if (!voltApiInstance) {
        voltApiInstance = new VoltApi({
            ...config,
            platform: getBrowserName(voltApi),
            analyticsFirebaseInstance: getFirebaseAnalyticsInstance(config),
            logger: Logger,
        })
    }

    return voltApiInstance as any
}
