import { createSignal, createEffect } from 'solid-js';
import { useSelector } from '../store';

export const useLocale = () => {
  const [locale, setLocale] = createSignal(useSelector(state => state.language.locale)());

  // Update the locale signal when the locale selector changes
  createEffect(() => {
    setLocale(useSelector(state => state.language.locale)())
 })

  return locale;
};
