import { of } from 'rxjs'

/**
 * Deal with Favourites action (add/remove/get)
 */
export default class FavouritesApi {
    /**
     *
     * @param {Object} program
     * @param {String} program.id
     * @param {Boolean} program.isSeries
     * @return {Observable}
     */
    addFavouriteVOD({ id, isSeries }) {
        return of({})
    }

    /**
     *
     * @param {Object} program
     * @param {String} program.id
     * @param {Boolean} program.isSeries
     * @return {Observable}
     */
    removeFavouriteVOD({ id, isSeries }) {
        return of({})
    }

    /**
     * Get All favourites (both Movies/Titles and Series)
     *
     * @return {Observable<Array<String>>} result list of program IDs
     */
    getAllVODs({ limit = 50 } = {}) {
        return of([])
    }

    /**
     * Recursively fetches all favorite recordings
     *
     * @param {Object} params
     * @param {Number} [params.limit=20] The maximum number of results per fetch
     * @returns {Observable<Array<Dvr>>}
     */
    getFavoriteRecordings({ limit = 20 } = {}) {
        return of([])
    }

    /**
     * Adds a recording to favourites
     *
     * @param {Object} params
     * @param {Boolean} params.id Recording ID
     * @returns {Observable}
     */
    addFavoriteRecording({ id }) {
        return of({})
    }

    /**
     * Removes a recording from favourites
     *
     * @param {Object} params
     * @param {Boolean} params.id Recording ID
     * @returns {Observable}
     */
    deleteFavoriteRecording({ id }) {
        return of({})
    }
}
