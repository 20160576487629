export default (props = {}) =>     <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
        <rect
            x="1"
            y="1"
            width="28"
            height="28"
            rx="14"
            stroke="url(#paint0_linear_12175_3052)"
            stroke-width="2"
            stroke-linejoin="bevel"
        />
        <path
            d="M17.2562 13.9226C17.5817 13.5972 17.5817 13.0695 17.2562 12.7441C16.9308 12.4186 16.4031 12.4186 16.0777 12.7441L13.5777 15.2441C13.2523 15.5695 13.2523 16.0972 13.5777 16.4226C13.9031 16.748 14.4308 16.748 14.7562 16.4226L17.2562 13.9226Z"
            fill="url(#paint1_linear_12175_3052)"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.9347 7.25593C19.9584 6.27962 18.3755 6.27962 17.3992 7.25593L8.88307 15.772C8.58265 16.0725 8.36375 16.4445 8.24703 16.853L7.11109 20.8288C6.75187 22.086 7.91425 23.2484 9.1715 22.8892L13.1473 21.7533C13.5558 21.6365 13.9278 21.4176 14.2283 21.1172L22.7444 12.6011C23.7207 11.6248 23.7207 10.0419 22.7444 9.06557L20.9347 7.25593ZM18.5777 8.43444C18.9031 8.109 19.4308 8.109 19.7562 8.43444L21.5658 10.2441C21.8913 10.5695 21.8913 11.0972 21.5658 11.4226L20.8336 12.1548L17.8455 9.16667L18.5777 8.43444ZM16.6669 10.3452L19.6551 13.3333L14.1669 18.8215L11.1788 15.8333L16.6669 10.3452ZM10.0035 17.015L12.9852 19.9968C12.8982 20.0673 12.7977 20.1198 12.6894 20.1507L8.71363 21.2867L9.84957 17.3109C9.8805 17.2026 9.93296 17.102 10.0035 17.015Z"
            fill="url(#paint2_linear_12175_3052)"
        />
        <defs>
            <linearGradient id="paint0_linear_12175_3052" x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0057B8" />
                <stop offset="1" stop-color="#0057B8" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_12175_3052"
                x1="15.2608"
                y1="6.5237"
                x2="15.2608"
                y2="22.9554"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#0057B8" />
                <stop offset="1" stop-color="#0057B8" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_12175_3052"
                x1="15.2608"
                y1="6.5237"
                x2="15.2608"
                y2="22.9554"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#0057B8" />
                <stop offset="1" stop-color="#0057B8" />
            </linearGradient>
        </defs>
    </svg>
