import commonConfig from './common.js'
import prodConfig from './production.js'
import stagingConfig from './staging.js'
import preprod from './preprod.js'
import devConfig from './development.js'
import { changeEnv } from './env.js'
import preprod2 from './preprod2.js'
import preprod3 from './preprod3.js'

const apiConfig = (() => {
    let env = 'development'
    try {
        env = changeEnv()
    } catch (e) {
        throw new Error('No environment specified')
    }

    switch (env) {
        case 'development':
            return devConfig
        case 'staging':
            return stagingConfig
        case 'preprod':
            return preprod
        case 'preprod2':
            return preprod2
        case 'preprod3':
            return preprod3
        case 'production':
            return prodConfig
        default:
            throw new Error('unknown env: ' + env)
    }
})()

const allApiConfig = {
    development: devConfig,
    staging: stagingConfig,
    preprod: preprod,
    preprod2: preprod2,
    preprod3: preprod3,
    production: prodConfig,
}
export default { ...commonConfig, ...apiConfig, allApiConfig }
