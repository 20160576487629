import { of, throwError } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'
import Fetch from '../fetch'
import UserPreferencesApi from '../UserPreferencesApi'
import DataHelper from 'framework/helpers/data'
import VoltError from 'VoltError'
import AuthType from 'framework/helpers/auth'

export default class SettingsApi extends Fetch {
    static TAG = 'SettingsApi'

    /**
     * Updates the user pc level
     *
     * @param {String|Number} level
     * @return {Observable}
     */
    updatePcLevel(level) {
        this.logger.info(SettingsApi.TAG, `Update Parental Control level`)
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.PARENTAL_LEVEL
            )
        ) {
            return of(true)
        }

        return this.userPreferencesApi.updateUserPreference(
            UserPreferencesApi.USER_PREFERENCES.PARENTAL_LEVEL,
            level
        )
    }

    /**
     * Updates the user pin code
     *
     * @param {String} pin type to update, either PURCHASE or PARENTAL (cf PINApi.TYPE)
     * @param {String|Number} pin code
     * @return {Observable}
     */
    updatePin(type, newPin) {
        this.logger.info(SettingsApi.TAG, `Update [${type}] PIN`)

        return this.fetch({
            url: `${this.config.urls.apiUrl}/user`,
            method: 'PUT',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[UPDATE PIN]`,
            body: {
                pin: newPin,
            },
        }).pipe(
            mergeMap((userPreference) => {
                if (userPreference && userPreference.pinSet) {
                    this.logger.info(SettingsApi.TAG, `Pin has been set !`)
                }

                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.UPDATE_PIN_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * Modify user password
     * @param {Object} arg
     * @param {String} arg.password Current Password
     * @param {String} arg.newPassword New Password
     * @return {Observable<Boolean>} true if the password is verified
     */
    updatePassword({ password, newPassword }) {
        this.logger.info(SettingsApi.TAG, `Updating Password...`)
        if (!password || !newPassword) {
            return throwError(
                new VoltError(VoltError.codes.PASSWORD_MODIFICATION_FAILED, {
                    message: 'Cannot modify password because current/new password is empty',
                })
            )
        }

        return this.fetch({
            url: `${this.config.urls.apiUrl}/process/start/userManagement.UpdatePassword.v1.0`,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[UPDATE PASSWORD][STEP_1 RETRIEVE PROCESS ID]`,
        }).pipe(
            mergeMap(({ response: process }) => {
                const processId = process.processId
                if (!processId) {
                    return throwError(
                        new VoltError(VoltError.codes.PASSWORD_MODIFICATION_FAILED, {
                            message:
                                'Cannot modify password because the processId provided by the backend is invalid',
                        })
                    )
                }
                return this.fetch({
                    url: `${this.config.urls.apiUrl}/process/step`,
                    method: 'PUT',
                    headers: {
                        Authorization: AuthType.BearerToken(
                            DataHelper.getInstance().getPrimaryAccessToken()
                        ),
                        'Content-Type': 'application/json',
                    },
                    log: `[UPDATE PASSWORD][STEP_2 UPDATE PASSWORD]`,
                    body: {
                        processId,
                        parameters: {
                            oldPassword: password,
                            newPassword: newPassword,
                        },
                    },
                }).pipe(
                    mergeMap(() => {
                        return of(true)
                    })
                )
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(
                        error.code !== VoltError.codes.AUTH_PASSWORD_COMPLEXITY_NOT_MET.code &&
                        error.code !== VoltError.codes.INVALID_PASSWORD.code
                            ? VoltError.codes.PASSWORD_MODIFICATION_FAILED
                            : error,
                        {
                            inheritedError: error,
                        }
                    )
                )
            })
        )
    }

    /**
     * Checks if a given password is valid (can be used before performing specific action like Reset PIN)
     * @param {Object} arg
     * @param {String} arg.password Password
     * @return {Observable<Boolean>} true if the password is verified
     */
    verifyPassword({ password }) {
        this.logger.info(SettingsApi.TAG, `Verifying Password...`)

        return this.fetch({
            url: `${this.config.urls.apiUrl}/user/verifyPassword`,
            method: 'POST',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[VERIFY PASSWORD]`,
            body: {
                password,
            },
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.INVALID_PASSWORD, {
                        inheritedError: error,
                    })
                )
            })
        )
    }

    /**
     * Checks if a given password is valid (can be used before performing specific action like Reset PIN)
     * @param {Object} arg
     * @param {String} arg.languageCode Should be two digit
     * @return {Observable<Boolean>} true if the password is verified
     */
    updateAccountLocale({ languageCode }) {
        this.logger.info(SettingsApi.TAG, `Updating User locale to ${languageCode}...`)
        if (!languageCode || languageCode.length < 2) {
            return throwError(
                new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                    extraLog: 'Cannot update user language due to irrelevant language code',
                })
            )
        }

        return this.fetch({
            url: `${this.config.urls.apiUrl}/user`,
            method: 'PUT',
            headers: {
                Authorization: AuthType.BearerToken(
                    DataHelper.getInstance().getPrimaryAccessToken()
                ),
                'Content-Type': 'application/json',
            },
            log: `[UPDATE USER LOCALE]`,
            body: {
                attributes: {
                    'com.uxpsystems.mint.user.Language': languageCode.slice(0, 2),
                },
            },
        }).pipe(
            mergeMap(() => {
                return of(true)
            }),
            catchError((error) => {
                return throwError(
                    new VoltError(VoltError.codes.USER_PREFERENCE_UPDATE_FAILED, {
                        inheritedError: error,
                    })
                )
            })
        )
    }
}
