import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import UserPreferencesApi from '../UserPreferencesApi'
import Fetch from '../fetch'

/**
 * Deal with Favourites action (add/remove/get)
 */
export default class FavouritesApi extends Fetch {
    static TAG = 'FavouritesApi'

    /**
     * Get All favourites (both Movies/Titles and Series)
     *
     * @return {Observable<Array<String>>} result list of program IDs
     */
    getAllVODs({ limit = 50 } = {}) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS
            )
        ) {
            return of([])
        }

        this.logger.info(FavouritesApi.TAG, `Getting VOD Favorites...`)

        return this.userPreferencesApi
            .getUserPreferencesList(UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS)
            .pipe(
                map((favorites) => (favorites || []).map((favorite) => favorite.id).filter(Boolean))
            )
    }

    /**
     *
     * @param {Object} program
     * @param {String} program.id
     * @param {Boolean} program.isSeries
     * @return {Observable}
     */
    addFavouriteVOD({ id, isSeries }) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS
            )
        ) {
            return of({})
        }

        this.logger.info(FavouritesApi.TAG, `Adding Favorite VOD ${id}...`)

        return this.userPreferencesApi.updateUserPreferencesItemInList(
            UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS,
            {
                id,
            },
            `id eq "${id}"`
        )
    }

    /**
     *
     * @param {Object} program
     * @param {String} program.id
     * @param {Boolean} program.isSeries
     * @return {Observable}
     */
    removeFavouriteVOD({ id, isSeries }) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS
            )
        ) {
            return of({})
        }

        this.logger.info(FavouritesApi.TAG, `Removing Favorite VOD ${id}...`)

        return this.userPreferencesApi.deleteUserPreferencesItemFromList(
            UserPreferencesApi.USER_PREFERENCES.FAVORITES_VODS,
            `id eq "${id}"`
        )
    }

    /**
     * Recursively fetches all favorite recordings
     *
     * @param {Object} params
     * @param {Number} [params.limit=20] The maximum number of results per fetch
     * @returns {Observable<Array<Dvr>>}
     */
    getFavoriteRecordings({ limit = 20 } = {}) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_RECORDINGS
            )
        ) {
            return of({})
        }
        this.logger.info(FavouritesApi.TAG, `Getting DVR Favorites...`)

        return this.userPreferencesApi
            .getUserPreferencesList(UserPreferencesApi.USER_PREFERENCES.FAVORITES_RECORDINGS)
            .pipe(
                map((favorites) => (favorites || []).map((favorite) => favorite.id).filter(Boolean))
            )
    }

    /**
     * Adds a recording to favourites
     *
     * @param {Object} params
     * @param {Boolean} params.id Recording ID
     * @returns {Observable}
     */
    addFavoriteRecording({ id }) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_RECORDINGS
            )
        ) {
            return of({})
        }

        this.logger.info(FavouritesApi.TAG, `Adding DVR Favorites ${id}...`)

        return this.userPreferencesApi.updateUserPreferencesItemInList(
            UserPreferencesApi.USER_PREFERENCES.FAVORITES_RECORDINGS,
            {
                id,
            },
            `id eq "${id}"`
        )
    }

    /**
     * Removes a recording from favourites
     *
     * @param {Object} params
     * @param {Boolean} params.id Recording ID
     * @returns {Observable}
     */
    deleteFavoriteRecording({ id }) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_RECORDINGS
            )
        ) {
            return of({})
        }

        this.logger.info(FavouritesApi.TAG, `Removing DVR Favorites ${id}...`)

        return this.userPreferencesApi.deleteUserPreferencesItemFromList(
            UserPreferencesApi.USER_PREFERENCES.FAVORITES_RECORDINGS,
            `id eq "${id}"`
        )
    }
}
