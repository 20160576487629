export default (props = {}) => <svg width="115" height="30" viewBox="0 0 115 30" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<path d="M15.0052 29.9985C11.5408 29.9985 8.34772 28.8153 5.80376 26.8461C5.78012 26.8267 5.79179 26.803 5.82332 26.8186C7.47095 27.7382 10.3886 28.7683 15.0053 28.7683C20.3178 28.7683 23.6913 27.0427 24.8749 26.2522C24.9065 26.2336 24.9222 26.2522 24.8903 26.2805C22.2525 28.591 18.7963 29.9985 15.0052 29.9985Z" fill="black"/>
<path d="M26.2008 24.6137C23.774 26.6062 19.7163 27.6128 16.0237 27.6128C9.00883 27.6128 3.33037 25.4395 1.45862 21.4611C1.44658 21.4302 1.46636 21.4187 1.48613 21.4456C3.49538 24.3579 8.62744 25.8832 13.3971 25.8832C20.145 25.8832 26.6801 23.8117 28.9369 20.4906C28.9609 20.4548 28.9805 20.4703 28.969 20.5021C28.7092 21.1546 28.3902 21.8184 28.064 22.3772C27.4975 23.3482 26.8649 24.0708 26.2008 24.6137Z" fill="black"/>
<path d="M0.0313808 14.9952C0.68826 17.4361 4.30945 21.0129 11.9893 21.0129C20.0662 21.0129 29.4129 17.1142 29.9596 14.1819C29.9676 14.1428 29.9908 14.1461 29.9908 14.1862C30.0027 14.4331 30.0106 14.6846 30.0106 14.9994C30.0106 16.3005 29.8213 17.8493 29.4561 18.7063C28.9453 19.9047 26.444 23.5488 15.5043 23.5488C7.47489 23.5488 2.83857 20.8327 0.978934 18.8943C0.570108 18.4657 0.291257 17.928 0.223752 17.5854C0.0822468 16.8815 0 15.8211 0 14.9993C0 14.9676 0.0232801 14.9598 0.0313808 14.9952Z" fill="black"/>
<path d="M0.373723 11.6739C0.518731 10.9114 1.00652 9.43799 1.44658 8.56466C1.45855 8.54072 1.4819 8.54525 1.47446 8.57254C1.40031 8.87132 1.34091 9.15841 1.34091 9.44544C1.34091 12.4327 6.29476 14.7986 12.2327 14.7986C17.7146 14.7986 28.5794 12.2876 28.4496 8.39155C28.4455 8.36411 28.4658 8.36068 28.4774 8.38389C28.8267 9.09572 29.2395 10.1095 29.4599 10.9788C29.6571 11.7567 29.6489 12.3341 29.4954 12.8809C28.6615 15.8513 23.5145 17.4791 16.8888 17.4791C6.02824 17.4791 1.37229 14.3543 0.45181 12.4014C0.337234 12.1575 0.310159 12.0006 0.373723 11.6739Z" fill="black"/>
<path d="M25.0091 4.12735C25.0091 3.9854 24.9776 3.87995 24.9146 3.78114C24.8983 3.75764 24.9146 3.74195 24.9379 3.76121C25.4214 4.20208 25.8782 4.64995 26.3611 5.19999C27.105 6.04551 27.3958 6.48163 27.3958 7.22871C27.3958 9.31911 24.1439 11.6978 16.8574 11.6978C10.019 11.6978 2.70539 9.583 2.70539 6.66627C2.70539 6.46602 2.89068 6.13177 3.18916 5.75448C3.81386 4.95668 4.52598 4.21762 5.30101 3.56082C5.3278 3.53768 5.3475 3.54965 5.32429 3.58074C4.89934 4.10356 4.70638 4.61835 4.70638 5.12146C4.70638 6.52892 5.82332 8.74207 11.7892 8.74207C19.9085 8.74207 25.0091 5.79388 25.0091 4.12735Z" fill="black"/>
<path d="M15.0053 0.00019455C17.8206 0.00019455 20.432 0.767053 22.6808 2.11497C23.2862 2.4765 23.5854 2.71602 23.5854 3.05836C23.5854 4.49261 19.559 5.98261 14.3679 5.98261C9.12224 5.98261 6.16552 4.62229 6.16552 3.35713C6.16552 2.92795 6.54654 2.59407 7.22348 2.17744C9.48034 0.793836 12.1704 0.00019455 15.0053 0.00019455Z" fill="black"/>
<path d="M45.9934 12.9102H43.6603C43.6177 12.6083 43.5307 12.3402 43.3993 12.1058C43.2679 11.8679 43.0992 11.6655 42.8932 11.4986C42.6873 11.3317 42.4494 11.2038 42.1795 11.1151C41.9131 11.0263 41.6237 10.9819 41.3112 10.9819C40.7466 10.9819 40.2547 11.1222 39.8357 11.4027C39.4167 11.6797 39.0918 12.0845 38.8609 12.6172C38.6301 13.1463 38.5147 13.7891 38.5147 14.5455C38.5147 15.3232 38.6301 15.9766 38.8609 16.5057C39.0953 17.0348 39.422 17.4343 39.841 17.7042C40.2601 17.9741 40.7448 18.109 41.2952 18.109C41.6042 18.109 41.89 18.0682 42.1528 17.9865C42.4192 17.9048 42.6553 17.7859 42.8613 17.6296C43.0672 17.4698 43.2377 17.2763 43.3726 17.049C43.5111 16.8217 43.607 16.5625 43.6603 16.2713L45.9934 16.282C45.933 16.7827 45.7821 17.2656 45.5406 17.7308C45.3027 18.1925 44.9813 18.6062 44.5765 18.9719C44.1752 19.3342 43.6958 19.6218 43.1383 19.8349C42.5843 20.0444 41.9575 20.1491 41.2579 20.1491C40.2849 20.1491 39.4149 19.929 38.6479 19.4886C37.8844 19.0483 37.2807 18.4109 36.8368 17.5763C36.3964 16.7418 36.1763 15.7315 36.1763 14.5455C36.1763 13.3558 36.4 12.3438 36.8474 11.5092C37.2949 10.6747 37.9021 10.0391 38.6692 9.60227C39.4362 9.16193 40.2991 8.94176 41.2579 8.94176C41.89 8.94176 42.476 9.03054 43.0158 9.2081C43.5591 9.38565 44.0403 9.64489 44.4593 9.9858C44.8783 10.3232 45.2192 10.7369 45.482 11.2269C45.7484 11.717 45.9188 12.2781 45.9934 12.9102Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M72.9505 20H70.4789L74.2449 9.09091H77.2172L80.9778 20H78.5063L77.6986 17.5124H73.7598L72.9505 20ZM75.7737 11.5838L77.114 15.712H74.3455L75.6884 11.5838H75.7737Z" fill="black"/>
<path d="M114.007 13.5227V11.8182H112.468V9.85795H110.199V11.8182H109.08V13.5227H110.199V17.7841C110.195 18.3203 110.307 18.7642 110.534 19.1158C110.762 19.4673 111.079 19.7266 111.488 19.8935C111.9 20.0604 112.379 20.1332 112.926 20.1119C113.221 20.1012 113.471 20.071 113.677 20.0213C113.883 19.9751 114.043 19.9343 114.157 19.8988L113.8 18.2102L113.717 18.2261L113.66 18.2372L113.555 18.2582C113.448 18.2759 113.342 18.2848 113.235 18.2848C113.082 18.2848 112.947 18.2617 112.83 18.2156C112.717 18.1694 112.628 18.0895 112.564 17.9759C112.5 17.8587 112.468 17.6953 112.468 17.4858V13.5227H114.007Z" fill="black"/>
<path d="M102.594 20V15.2699C102.597 14.9183 102.663 14.6183 102.791 14.3697C102.922 14.1175 103.103 13.9258 103.334 13.7944C103.568 13.663 103.838 13.5973 104.144 13.5973C104.598 13.5973 104.955 13.7393 105.214 14.0234C105.474 14.304 105.601 14.6946 105.598 15.1953V20H107.867V14.7905C107.867 14.1548 107.75 13.608 107.515 13.1499C107.281 12.6882 106.953 12.3331 106.53 12.0845C106.107 11.8359 105.612 11.7116 105.044 11.7116C104.437 11.7116 103.92 11.8519 103.494 12.1325C103.068 12.4094 102.764 12.7859 102.583 13.2617H102.487V11.8182H100.324V20H102.594Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M95.0883 20.1598C94.2467 20.1598 93.5222 19.9893 92.915 19.6484C92.3113 19.304 91.8461 18.8175 91.5194 18.1889C91.1927 17.5568 91.0293 16.8093 91.0293 15.9464C91.0293 15.1048 91.1927 14.3661 91.5194 13.7305C91.8461 13.0948 92.306 12.5994 92.899 12.2443C93.4956 11.8892 94.1952 11.7116 94.9977 11.7116C95.5375 11.7116 96.04 11.7987 96.5052 11.9727C96.9739 12.1431 97.3823 12.4006 97.7303 12.745C98.0819 13.0895 98.3553 13.5227 98.5507 14.0447C98.746 14.5632 98.8436 15.1705 98.8436 15.8665V16.4897H93.2719V16.495C93.2719 16.8999 93.3465 17.2496 93.4956 17.5444C93.6483 17.8391 93.8632 18.0664 94.1401 18.2262C94.4171 18.386 94.7456 18.4659 95.1256 18.4659C95.3777 18.4659 95.6085 18.4304 95.8181 18.3594C96.0276 18.2884 96.2069 18.1818 96.356 18.0398C96.5052 17.8977 96.6188 17.7237 96.697 17.5178L98.7957 17.6562C98.6891 18.1605 98.4707 18.6009 98.1405 18.9773C97.8138 19.3501 97.3912 19.6413 96.8727 19.8509C96.3578 20.0568 95.763 20.1598 95.0883 20.1598ZM96.7076 15.0835H93.274C93.2877 14.7897 93.3633 14.5215 93.5009 14.2791C93.6501 14.0128 93.856 13.8015 94.1188 13.6452C94.3852 13.4854 94.6906 13.4055 95.035 13.4055C95.3653 13.4055 95.6547 13.4783 95.9033 13.6239C96.1554 13.766 96.3525 13.9631 96.4945 14.2152C96.6366 14.4673 96.7076 14.7567 96.7076 15.0835Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M83.6625 22.935C84.1881 23.1374 84.8184 23.2386 85.5535 23.2386C86.3347 23.2386 87.0254 23.1197 87.6256 22.8817C88.2293 22.6474 88.7016 22.2958 89.0425 21.8271C89.3834 21.3583 89.5538 20.7759 89.5538 20.0799V11.8182H87.3006V13.1925H87.2154C87.1124 12.9723 86.9651 12.7486 86.7733 12.5213C86.5851 12.2905 86.3365 12.0987 86.0276 11.946C85.7186 11.7898 85.3351 11.7116 84.877 11.7116C84.2768 11.7116 83.7246 11.8661 83.2204 12.1751C82.7197 12.484 82.3184 12.9492 82.0166 13.5707C81.7147 14.1921 81.5638 14.9663 81.5638 15.8931C81.5638 16.7987 81.7112 17.5479 82.0059 18.141C82.3042 18.7305 82.7037 19.169 83.2044 19.4567C83.7087 19.7408 84.268 19.8828 84.8823 19.8828C85.3156 19.8828 85.6884 19.8189 86.0009 19.6911C86.3134 19.5632 86.5691 19.3981 86.768 19.1957C86.9668 18.9933 87.116 18.7784 87.2154 18.5511H87.3113V20.0479C87.3113 20.609 87.1497 21.0085 86.8266 21.2464C86.507 21.4879 86.1004 21.6087 85.6068 21.6087C85.2765 21.6087 84.996 21.5643 84.7651 21.4755C84.5379 21.3903 84.355 21.2766 84.2165 21.1346C84.078 20.9925 83.9768 20.8398 83.9129 20.6765L81.8141 20.9588C81.9065 21.3885 82.1071 21.7756 82.4161 22.12C82.725 22.4645 83.1405 22.7362 83.6625 22.935ZM86.5283 17.9066C86.2761 18.0877 85.9672 18.1783 85.6014 18.1783C85.2286 18.1783 84.9143 18.0859 84.6586 17.9013C84.4065 17.7131 84.2129 17.4485 84.078 17.1076C83.9466 16.7631 83.8809 16.3548 83.8809 15.8825C83.8809 15.4173 83.9466 15.0071 84.078 14.652C84.2094 14.2969 84.4029 14.0199 84.6586 13.821C84.9143 13.6186 85.2286 13.5174 85.6014 13.5174C85.9672 13.5174 86.2779 13.6151 86.5336 13.8104C86.7893 14.0057 86.9846 14.2809 87.1195 14.636C87.2545 14.9876 87.3219 15.4031 87.3219 15.8825C87.3219 16.3619 87.2527 16.7738 87.1142 17.1183C86.9793 17.4592 86.784 17.7219 86.5283 17.9066Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M63.6181 19.6484C64.2254 19.9893 64.9498 20.1598 65.7914 20.1598C66.4661 20.1598 67.0609 20.0568 67.5759 19.8509C68.0943 19.6413 68.5169 19.3501 68.8436 18.9773C69.1739 18.6009 69.3923 18.1605 69.4988 17.6562L67.4001 17.5178C67.322 17.7237 67.2083 17.8977 67.0592 18.0398C66.91 18.1818 66.7307 18.2884 66.5212 18.3594C66.3117 18.4304 66.0808 18.4659 65.8287 18.4659C65.4487 18.4659 65.1202 18.386 64.8433 18.2262C64.5663 18.0664 64.3514 17.8391 64.1987 17.5444C64.0496 17.2496 63.975 16.8999 63.975 16.495V16.4897H69.5467V15.8665C69.5467 15.1705 69.4491 14.5632 69.2538 14.0447C69.0585 13.5227 68.785 13.0895 68.4335 12.745C68.0854 12.4006 67.6771 12.1431 67.2083 11.9727C66.7431 11.7987 66.2406 11.7116 65.7009 11.7116C64.8983 11.7116 64.1987 11.8892 63.6021 12.2443C63.0091 12.5994 62.5492 13.0948 62.2225 13.7305C61.8958 14.3661 61.7325 15.1048 61.7325 15.9464C61.7325 16.8093 61.8958 17.5568 62.2225 18.1889C62.5492 18.8175 63.0144 19.304 63.6181 19.6484ZM63.9771 15.0835C63.9908 14.7897 64.0665 14.5215 64.2041 14.2791C64.3532 14.0128 64.5592 13.8015 64.822 13.6452C65.0883 13.4854 65.3937 13.4055 65.7382 13.4055C66.0684 13.4055 66.3578 13.4783 66.6064 13.6239C66.8585 13.766 67.0556 13.9631 67.1977 14.2152C67.3397 14.4673 67.4107 14.7567 67.4107 15.0835H63.9771Z" fill="black"/>
<path d="M56.3059 20V11.8182H58.5058V13.2457H58.591C58.7402 12.7379 58.9905 12.3544 59.3421 12.0952C59.6937 11.8324 60.0985 11.701 60.5566 11.701C60.6702 11.701 60.7927 11.7081 60.9241 11.7223C61.0555 11.7365 61.1709 11.756 61.2704 11.7809V13.7944C61.1638 13.7624 61.0165 13.734 60.8283 13.7092C60.64 13.6843 60.4678 13.6719 60.3116 13.6719C59.9778 13.6719 59.6795 13.7447 59.4167 13.8903C59.1574 14.0323 58.9515 14.2312 58.7988 14.4869C58.6496 14.7425 58.5751 15.0373 58.5751 15.3711V20H56.3059Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.5422 19.8828C48.9506 20.0639 49.4158 20.1545 49.9378 20.1545C50.3426 20.1545 50.6995 20.1012 51.0085 19.9947C51.3174 19.8846 51.582 19.7337 51.8022 19.5419C52.0223 19.3466 52.1981 19.1211 52.3295 18.8654H52.3934V20H54.5454V14.4815C54.5454 13.995 54.4513 13.5778 54.2631 13.2298C54.0749 12.8782 53.8174 12.5906 53.4907 12.3668C53.1676 12.1431 52.7965 11.978 52.3774 11.8714C51.962 11.7649 51.5269 11.7116 51.0724 11.7116C50.419 11.7116 49.8437 11.8111 49.3465 12.0099C48.8529 12.2053 48.4499 12.4858 48.1374 12.8516C47.8249 13.2138 47.6154 13.6435 47.5088 14.1406L49.6076 14.3111C49.6857 14.0412 49.8455 13.8175 50.087 13.6399C50.3284 13.4588 50.6534 13.3683 51.0617 13.3683C51.4488 13.3683 51.7471 13.4606 51.9566 13.6452C52.1697 13.8299 52.2762 14.0909 52.2762 14.4283V14.4602C52.2762 14.6342 52.2105 14.7656 52.0791 14.8544C51.9478 14.9432 51.7365 15.0107 51.4453 15.0568C51.1541 15.0994 50.7706 15.1456 50.2947 15.1953C49.8899 15.2344 49.5028 15.3018 49.1335 15.3977C48.7677 15.4936 48.441 15.6357 48.1534 15.8239C47.8657 16.0121 47.6385 16.2607 47.4715 16.5696C47.3046 16.8786 47.2212 17.2603 47.2212 17.7148C47.2212 18.2546 47.3384 18.7056 47.5728 19.0678C47.8107 19.4265 48.1338 19.6982 48.5422 19.8828ZM51.4719 18.3913C51.2162 18.5227 50.9215 18.5884 50.5877 18.5884C50.2397 18.5884 49.952 18.5085 49.7247 18.3487C49.501 18.1854 49.3892 17.9492 49.3892 17.6403C49.3892 17.4272 49.4442 17.2496 49.5543 17.1076C49.6644 16.962 49.8188 16.8466 50.0177 16.7614C50.2166 16.6761 50.4492 16.614 50.7155 16.5749C50.8398 16.5572 50.9783 16.5376 51.131 16.5163C51.2837 16.4915 51.4364 16.4648 51.5891 16.4364C51.7418 16.4045 51.8785 16.3707 51.9992 16.3352C52.1235 16.2962 52.2212 16.2536 52.2922 16.2074V17.0756C52.2922 17.3633 52.2194 17.6207 52.0738 17.848C51.9282 18.0753 51.7276 18.2564 51.4719 18.3913Z" fill="black"/>
</svg>
