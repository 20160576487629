import { Tile, Product, TVODProduct, Subscription } from 'models'
import { offerFactory } from './offer'
import { IMAGE_TYPE, getImage } from './helpers'
import Constants from 'api-constants'
import config from 'services/proxy/config'
const { productStatus } = config

export const productFactory = (data = {}, clientConfig = {}, productType) => {
    const { status = [] } = data
    const { proxyVersion = 1, proxy } = clientConfig
    const { enableFetchProductTitles = true } = proxy || {}
    // Proxy provide several prices but Product model contains only one.
    // Get only the first price in the array
    const defaultPrice =
        Array.isArray(status) && status.length > 0 ? parseFloat(status[0].amount) : undefined
    const currency = Array.isArray(status) && status.length > 0 && status[0].currency

    if (proxyVersion >= 3) {
        data.thumbnail = getImage(data.pictures, IMAGE_TYPE.THUMBNAIL)
        data.backdrop = getImage(data.pictures, IMAGE_TYPE.BACKDROP)
        //New Maculosa providing combination of billingPlan & promotions & product as  @property{offers}
        data.catalogOffers = data.offers
        //This below we are kepping as it is to support backward compatibility
        data.offers = data.billings
        data.genre = data.genres && data.genres.join(',')
    } else {
        data.thumbnail =
            data.pictures &&
            Array.isArray(data.pictures.thumbnails) &&
            data.pictures.thumbnails.length > 0
                ? data.pictures.thumbnails[0]
                : undefined
        data.backdrop =
            data.pictures &&
            Array.isArray(data.pictures.backdrops) &&
            data.pictures.backdrops.length > 0
                ? data.pictures.backdrops[0]
                : undefined
    }

    let validityStart
    if (data.start_validity) {
        validityStart =
            typeof data.start_validity === 'string'
                ? Date.parse(data.start_validity)
                : data.start_validity * 1000
    }
    let validityEnd
    if (data.end_validity) {
        validityEnd =
            typeof data.end_validity === 'string'
                ? Date.parse(data.end_validity)
                : data.end_validity * 1000
    }
    let isAvailable = true
    if (data.product_status) {
        isAvailable = data.product_status === productStatus.activated
    }

    let commonProps = {
        id: data.platform_id,
        title: data.title || data.short_title,
        description: data.description || data.short_description,
        thumbnail: data.thumbnail,
        backdrop: data.backdrop,
        category: data.genre && Array.isArray(data.genre) ? data.genre.join(', ') : data.genre,
        currency: currency,
        validityStart,
        validityEnd,
        isAvailable,
        displayable: data.displayable,
        offers: (data.offers || []).map((offer) =>
            offerFactory(
                offer,
                defaultPrice,
                currency,
                data?.more_info_url,
                data?.service_access_url
            )
        ),
        source: Product.SOURCE.IFS_PROXY,
        format: Tile.FORMAT.LANDSCAPE,
        children: data?.children,
        promotions: data?.promotions,
        trialGroup: data.trial_group || '',
        productGroups: data?.product_groups,
        catalogOffers: data?.catalogOffers,
    }

    // Important : Proxy contains only Products/Packs description and does not handle entitlement metadatas,
    // this information should be parsed using external backend
    if (
        data.product_type === Constants.productTypes.TRANSACTIONAL &&
        productType === Constants.productTypes.TRANSACTIONAL
    ) {
        // This is a simplification from the current implement of the VOLT API which needs to be done now but reworked later !
        const programId = data.titles.length > 0 ? data.titles[0] : undefined
        return new TVODProduct({
            programId,
            ...commonProps,
            // TODO Those fields are not handled by the Proxy yet
            // programId, contents, name, providerId
        })
    }
    return new Subscription({
        ...commonProps,
        titles: enableFetchProductTitles
            ? (data.titles || []).reduce((acc, title) => {
                  if (title) {
                      acc[title] = { id: title, contents: { [title]: { id: title } }, isHD: false } // TODO Handle HD props later through the proxy
                  }
                  return acc
              }, {})
            : {},
        channels: data.channels,
        serviceType: data.service_type,
        // Unfortunately for the moment we have to keep the legacy 'android_uri' from the proxy acting' as command. Will be cleaned later
        launchCommand: data.android_launch_command || data.android_uri,
        launchUri: data.android_launch_uri,
        activationUri: data.android_activation_uri,
        webActivationUri: data.web_activation_uri,
    })
}
