import enMessages from "./en.json";
import arMessages from "./fr.json";
import jaMessage from './ja.json';
import esMessages from './es.json'

export const defaultLocale = "en-US";

  export const locales = {
    "en-US": {
     messages: enMessages,
    },
    "es-US": {
      messages: esMessages,
    },
    "fr-CA": {
     messages: arMessages,
    },
    "ja-JP": {
      messages: jaMessage,
     },
};