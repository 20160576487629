import { from, of } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'

export default class PinApi {
    static TYPE = {
        MASTER: 'PURCHASE',
        ADULT: 'PARENTAL',
    }

    static DEFAULT_PIN = '0000'

    constructor(config) {
        this.config = config
    }

    getPinTypes = () => {
        return PinApi.TYPE
    }

    /**
     * Checks if a given PIN code of a given type is valid.
     *
     * Stub behavior: always consider pin as valid.
     *
     * @param {String} pin The PIN code that needs validating
     * @param {String} type The type of PIN code (ex: purchase, parental control)
     * @return {Observable<Boolean>} The validity of the given PIN code
     */
    validatePin(pin, type) {
        const defaultPinCode = this.config.defaultPinCode || PinApi.DEFAULT_PIN
        return from(this.config.persistentStorage.getItem(type)).pipe(
            mergeMap((currentPin) => {
                return of(currentPin === null ? pin === defaultPinCode : currentPin === pin)
            }),
            catchError(() => {
                return of(false)
            })
        )
    }

    /**
     * Calls proper reset function according to a PIN type
     * @param {PinType} type - user's PIN type
     * @returns {Observable<Boolean>} returns true if resetting was successful
     */
    resetPin(type) {
        return from(this.config.persistentStorage.removeItem(type)).pipe(
            mergeMap(() => of(true)),
            catchError(() => of(false))
        )
    }

    /**
     * Checks if user has a PIN with a given type
     * @param {PinType} type - user's PIN type
     * @returns {Observable<Boolean>|Observable<VoltError>} returns true if resetting was successful
     */
    isPinExist(type) {
        return of(true)
    }
}
