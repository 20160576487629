import { Abstract } from './Abstract'
import Constants from 'api-constants'
import { PaymentMethod } from './PaymentMethod'
import { Entitlement, IProfile } from '@typings/platform'
import { HashMap } from '@typings/generic'

export const PROP_VOD_POP = 'VODPopulation'
export const PROP_EMI_POP = 'Population'

/**
 * Represent a Profile
 * @property {String} status Profile Status (Not provided by all backend) {@Link Profile.STATUS}
 * @property {String} userId Seachange: Looks like a guuid : aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee
 *                           Vubiquity: is the user Id
 *                           MarketOne: Mapping with accountId
 * @property {String} customerId Partner: Also known as the "Traxis IngestId", looks like a number (digits only), but could be a real string
 *                               Kaltura: Also known as the externalId
 *                               Vubiquity:  Also known as externalId, it attached to user profile and is mandatory for SVOD transactions
 *                               MarketOne: Mapping with accountId
 * @property {String} profileId Traxis profileId of the user/customer (ex: aaaaaaaa-bbbb-cccc-dddd-eeeeeeeeeeee~~23MasterProfile)
 *                              Traxis allow multiple profiles per User, but volt currently support only one (the first one)
 *                              Vubiquity: Also known as identityId, it is attached to profile user
 *                              MarketOne: No explicit value for this Backend. Use default value
 * @property {String} username The profile's username
 * @property {String} firstName The profile's user's first name
 * @property {String} lastName The profile's user's last name
 * @property {String} email The email address attached to the profile
 * @property {String} mobile The mobile number attached to the profile
 * @property {String} defaultLanguage Default User Language (Not provided by all backend)
 * @property {Number} pcLevel Parental control level set. For a positive pcLevel, the profile is restricted to content with a pcLevel below this value.
 *                            Parental control is disabled if the profile's pcLevel is negative.
 * @property {Number} dvrQuota recording quota allowed, represent an amount of hours
 * @property {Number} dvrQuotaRemaining recording quota used, represent an amount of hours
 * @property {String} dvrQuotaUnit Unit of quota. It can be hours or megabytes
 * @property {String} profileType Type of Profile (Super Profile, Common Profile)
 * @property {Boolean} [selectedProfile=false] Indicates if there are multi profile if the current profile is the one currently selected by the end user. Please note that it is not necesseraly equals to default profile which can remains unchanged whatever the user selection
 * @property {Boolean} [isDefaultProfile=true] Indicates if it is a default profile (make sense when the backend supports multiple profiles)
 * @property {Boolean} [isKidProfile=true] Indicates if it is profile for kids with some security rules
 * @property {Constants.businessType} [businessType=B2C] Type of business (B2C, B2B2C, B2B)
 * @property {Object} [market] Market where the user is affiliated. Can be used to filter content like channels for Kaltura Backend (not always available depends on the backend)
 * @property {Object} [subMarket] Sub Market where the user is affiliated. Can be used to filter content like channels for Kaltura Backend (not always available depends on the backend)
 * @property {Object} [zipCode] User zip code (not always available depends on the backend)
 * @property {Object} [adZone] User ad zone (not always available depends on the backend)
 * @property {Object} [namedProperties] custom properties named, stored as key = propName, value = propValue
 * @property {String[]} [affiliateId] Vubiquity only: array of affiliate identifiers. To gather content by cluster
 * @property {Array<PaymentMethod>} paymentMethods Vubiquity payment methods attached to profile user mandatory for TVOD transactions
 * @property {Array<Entitlement>} entitlements User Profile Entitlements for TVOD, SVOD or Packs/Products.
 * (Some backend provide this data from Profile endpoints). Please be aware that in VOLT product entitlements API are not managed through account
 * TODO: Need to harmonize entitlements API
 * @property {String} userAccountType Account Type
 */

export class Profile extends Abstract {
    username: string
    firstName: string
    lastName: string
    userId: string
    customerId: string | number
    profileId: string | number
    email: string
    address?: any
    mobile?: string
    defaultLanguage: string
    pcLevel: number
    dvrEnabled: boolean
    ratingID?: string | number
    dvrQuota: number
    dvrQuotaRemaining: number
    dvrQuotaProtected: number
    dvrQuotaUnit?: string
    paymentMethods: PaymentMethod[]
    entitlements: Entitlement[]
    tvProfile: string
    affiliateId?: string
    market?: string
    subMarket?: string
    adZone?: string
    zipCode?: string
    namedProperties: HashMap<string, string>
    status: string
    profileType: string
    userAccountType: string
    businessType: string
    selectedProfile: boolean
    isDefaultProfile: boolean
    isKidProfile: boolean
    displayName?: string
    avatarLogo: string
    channelsFilter: string
    metadata?: any

    constructor(props: IProfile) {
        super()

        this.username = props.username
        this.firstName = props.firstName
        this.lastName = props.lastName
        this.userId = props.userId
        this.customerId = props.customerId
        this.profileId = props.profileId
        this.email = props.email
        this.address = props.address
        this.mobile = props.mobile
        this.defaultLanguage = props.defaultLanguage
        this.displayName = props.displayName
        this.pcLevel = props.pcLevel
        this.dvrEnabled = props.dvrEnabled
        this.ratingID = props.ratingID
        this.dvrQuota = props.dvrQuota
        this.dvrQuotaRemaining = props.dvrQuotaRemaining
        this.dvrQuotaProtected = props.dvrQuotaProtected
        this.dvrQuotaUnit = props.dvrQuotaUnit || Profile.DVR_QUOTA_UNIT.HOUR
        this.paymentMethods = props.paymentMethods
        this.entitlements = props.entitlements
        this.tvProfile = props.tvProfile // (TODO: Rename eligility when implementation with Wow will be unified but keep it as follow for now)
        this.affiliateId = props.affiliateId
        this.market = props.market
        this.subMarket = props.subMarket
        this.adZone = props.adZone
        this.zipCode = props.zipCode
        this.metadata = props.metadata

        const computePropValue = this._makeComputePropValue(props)

        this.namedProperties = computePropValue('namedProperties', {})
        this.status = computePropValue('status', Profile.STATUS.ACTIVATED)
        this.profileType = computePropValue('profileType', Profile.PROFILE_TYPE.SUPER_PROFILE)
        this.userAccountType = computePropValue('userAccountType', Profile.USER_ACCOUNT_TYPE.MAIN)
        this.businessType = computePropValue('businessType', Constants.businessType.B2C)
        this.selectedProfile = computePropValue('selectedProfile', false)
        this.isDefaultProfile = computePropValue('isDefaultProfile', true)
        this.isKidProfile = computePropValue('isKidProfile', false)
        this.channelsFilter = computePropValue('channelsFilter', '')
        this.avatarLogo = computePropValue('avatarLogo', '')
    }

    static STATUS = {
        ACTIVATED: 'ACTIVATED',
        ACTIVATING: 'ACTIVATING',
        DEACTIVATED: 'DEACTIVATED',
        PLAYBACK_RESTRICTED: 'PLAYBACK_RESTRICTED',
        PENDING: 'PENDING',
        SUSPENDED: 'SUSPENDED',
        UNKNOWN: 'UNKNOWN',
    }

    static PROFILE_TYPE = {
        SUPER_PROFILE: 'SUPER_PROFILE',
        COMMON_PROFILE: 'COMMON_PROFILE',
    }

    static USER_ACCOUNT_TYPE = {
        MAIN: 'MAIN',
        LITE: 'LITE',
    }

    static DVR_QUOTA_UNIT = {
        HOUR: 'hour',
        MB: 'mb',
    }

    /** Vod population */
    getVodPopulation() {
        return this.namedProperties[PROP_VOD_POP]
    }

    /** Emi population */
    getEmiPopulation() {
        return this.namedProperties[PROP_EMI_POP]
    }

    isSuspended() {
        return this.status === Profile.STATUS.SUSPENDED
    }
}
