/**
 * @class Market One Entitlements for subscriptions
 * @property All these field are not well documented by M1.
 *           https://developer.m1amdocs.com/api/
 * This an nested class with represents the backend response.
 * Should NOT be used OUTSIDE M1 APIS !!!
 */
export class M1UserSubscription {
    constructor(props) {
        this.type = props.type
        this.status = props.status
        this.transactionId = props.transactionId // Used for cancellation
        this.transactionAlternativeId = props.internalProviderSubscriptionId // Used for cancellation
        // All these metadatas are inherited from paymentProviderSubscriptions
        this.productId = props.productId
        this.providerSubscriptionId = props.providerSubscriptionId
        this.internalProviderSubscriptionId = props.internalProviderSubscriptionId
        this.subscriptionStartDate = props.subscriptionStartDate
        this.subscriptionEndDate = props.subscriptionEndDate
        this.billingState = props.billingState
        this.subscriptionStatus = props.subscriptionStatus
        this.nextSubscriptionBillingDate = props.nextSubscriptionBillingDate
        this.nextSubscriptionPrice = props.nextSubscriptionPrice
        this.nextSubscriptionCurrency = props.nextSubscriptionCurrency
        this.serviceType = props.serviceType
        this.impactedSubscriptionItems = props.subscriptionItems
    }

    static STATUS = {
        activating: 'activating',
        activated: 'activated',
        provisional: 'provisional', // This status is used after having purchased using Google/Apple IAP from this because, pending 'activated' status which can take some time
        suspended: 'suspended',
        deactivated: 'deactivated',
        pending: 'pending',
        unknown: 'unknown',
    }

    isEntitled() {
        return (
            this.status === M1UserSubscription.STATUS.activating ||
            this.status === M1UserSubscription.STATUS.activated ||
            this.status === M1UserSubscription.STATUS.pending ||
            this.status === M1UserSubscription.STATUS.provisional ||
            this.status === M1UserSubscription.STATUS.suspended
        )
    }

    /**
     * To be used for Debug and log
     */
    toString() {
        return `status: ${this.status} || ProductId: ${this.productId} || TransactionId: ${this.transactionId} || TransactionAlternativeId: ${this.transactionAlternativeId} || billingState: ${this.billingState} || subscriptionStatus: ${this.subscriptionStatus} || subscriptionStartDate: ${this.subscriptionStartDate} || subscriptionEndDate: ${this.subscriptionEndDate}`
    }
}
