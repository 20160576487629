import {lazy} from 'solid-js'

export * from './config/useConfig'
export * from './toast/useToast'
export * from './icon/useIcon'
export * from './theme/useTheme'
export * from './theme/emiTheme'

export const AppBootstrap = lazy(() => import('./AppBootstrap'))
export const AppProviders = lazy(() => import('./AppProviders'))
export const Toast = lazy(() => import('./toast/Toast'))
export const Spinner = lazy(() => import('./spinner/Spinner'))
export const LoadingScreen = lazy(() => import('./loadingScreen/LoadingScreen'))
export const ThemeProvider = lazy(() => import('./theme/ThemeProvider'))
