import { Abstract } from './Abstract'
import { Tile } from './Tile'
import { Channel } from './Channel'
import { Program } from './Program'
import { ICategory } from '@typings/emi'
import { HashMap } from '@typings/generic'
import { IntlKey } from '@typings/node'

export class Category extends Abstract {
    id: string | number
    name: string
    names: string[]
    requestId: string | number
    intlKey: IntlKey
    title: string
    children: (string | number)[]
    size: number
    type: string
    freshness: string | number
    emptyText: string | number
    ratioOfScreenHeight: number
    height: number
    icon: string
    format: string
    pending: boolean
    retrieved: boolean
    temporary: boolean
    isEntitled: boolean
    totalItemsSize: number
    zoneType?: string
    backgroundColor?: string
    textColor?: string
    thumbnail?: string
    logoURL?: string
    labelsDisplay?: string
    backdropLandscape?: string
    backdropPortrait?: string
    zoneTilesOrientation?: string
    constructor(props: ICategory) {
        super()
        this.id = props.id
        this.name = props.name
        this.names = props.names
        this.zoneType = props?.zoneType
        this.backgroundColor = props?.backgroundColor
        this.textColor = props?.textColor
        this.thumbnail = props?.thumbnail
        this.logoURL = props?.logoURL
        this.labelsDisplay = props?.labelsDisplay
        this.backdropLandscape = props?.backdropLandscape
        this.backdropPortrait = props?.backdropPortrait
        this.zoneTilesOrientation = props?.zoneTilesOrientation
        /** ID of the related EMI request */
        this.requestId = props.requestId
        this.intlKey = props.intlKey
        this.title = props.title
        this.children = props.children
        this.size = props.size
        this.type = props.type
        this.freshness = props.freshness
        this.emptyText = props.emptyText
        this.ratioOfScreenHeight = props.ratioOfScreenHeight
        this.height = props.height
        /** collection icon */
        this.icon = props.icon

        const computePropValue = this._makeComputePropValue(props)

        this.format = computePropValue('format', Tile.FORMAT.PORTRAIT)
        this.pending = computePropValue('pending', false)
        this.retrieved = computePropValue('retrieved', false)
        this.temporary = computePropValue('temporary', false)
        /** Relevant when category is related to a product (ex: Subscription which contains Channel as children) */
        this.isEntitled = computePropValue('isEntitled', false)
        this.totalItemsSize = computePropValue('totalItemsSize', 0)
    }

    static FORMAT = {
        MIXED: 'mixed',
    }

    getPropsToMerge() {
        return ['children', ...super.getPropsToMerge()]
    }

    hasChildren() {
        return !!this.children && this.children.length > 0
    }

    hasMoreChildren() {
        return this.children.length < this.size
    }

    createPopulatedCopy(
        dataStore: (child: string | number) => null | (Program | Channel),
        newProps: HashMap<string, any>,
        propModifier: (value: Channel | Program) => Channel | Program
    ): Category {
        return this.update(
            {
                ...newProps,
                children: dataStore
                    ? this.children.reduce<(Channel | Program)[]>((acc, id) => {
                          const c = dataStore(id)
                          if (c) {
                              const _mods = propModifier && propModifier(c)

                              _mods && typeof c.update === 'function'
                                  ? acc.push(c.update(_mods))
                                  : acc.push(c)
                          }
                          return acc
                      }, [])
                    : [],
            },
            // Here we want to replace known children (identifiers) by their real value (instanceOf)
            // So we want to disable the merging process
            { bypassMerge: ['children'] }
        )
    }

    isPageRetrieved(page: number, limit: number) {
        return this.retrieved || (page + 1) * limit <= this.children.length
    }

    nextPage(limit: number) {
        return Math.floor(this.children.length / limit)
    }
}
