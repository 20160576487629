import './styles.css'
import {lazy} from 'solid-js'

export const Input = lazy(() => import('./input/Input'))
export const Button = lazy(() => import('./button/Button'))
export const Tile = lazy(() => import('./tile/Tile'))
export const SwimlaneLib = lazy(() => import('./swimlaneLib/SwimlaneLib'))
export const Swimlane = lazy(() => import('./swimlane/Swimlane'))
export const MenuLib = lazy(() => import('./menuLib/MenuLib'))
export const Menu = lazy(() => import('./menu/Menu'))
export const Tag = lazy(() => import('./tag/Tag'))
export const Slide = lazy(() => import('./slide/Slide'))
export const ElementWrapper = lazy(() => import('./element-wrapper/ElementWrapper'))
export const Dropdown = lazy(() => import('./dropdown/Dropdown'))
export const GenericUniverse = lazy(() => import('./genericUniverse/GenericUniverse'))
export const Layout = lazy(() => import('./layout/Layout'))
export const LazyImg = lazy(() => import('./lazyImg/LazyImg'))
export const ContentWall = lazy(() => import('./tilesList/ContentWall'))
export const DialogTile = lazy(() => import('./tilesList/DialogTile/DialogTile'))
export const SubscriptionTile = lazy(() => import('./tilesList/SubscriptionTile/SubscriptionTile'))
export const ConfirmCancelTile = lazy(() => import('./tilesList/ConfirmCancelTile/ConfirmCancelTile'))
export const ConfirmPauseTile = lazy(() => import('./tilesList/ConfirmPauseTile/ConfirmPauseTile'))
export const OfferBannerTile = lazy(() => import('./tilesList/SingleContentBlock/SingleContentBlock'))
export const MultipleContentBlocksList = lazy(() => import('./tilesList/MultipleContentBlocks/MultipleContentBlocks'))
export const WorksTileList = lazy(() => import('./tilesList/WorksTileList/WorksTileList'))
export const PackagePromotionList = lazy(() => import('./packagePromotionList/PackagePromotionList'))
export const MySubscriptionList = lazy(() => import('./mySubscriptionList/MySubscriptionList'))
export const ActivationRequiredList = lazy(() => import('./activationRequiredList/ActivationRequiredList'))
export const MyPerksHeading = lazy(() => import('./myPerksHeading/MyPerksHeading'))
export const CartDetails = lazy(() => import('./cartDetails/CartDetails'))
export const Checkout = lazy(() => import('./checkout/Checkout'))
export const SubMenu = lazy(() => import('./subMenu/subMenu'))
export const SearchPanel = lazy(() => import('./searchPanel/SearchPanel'))
export const Profile = lazy(() => import('./profile/Profile'))
export const Faq = lazy(() => import('./faq/Faq'))
export const CookieConsentManager = lazy(() => import('./cookieConsentManager/CookieConsentManager'))
export const MySubscriptionListForMobile = lazy(
    () => import('./mySubscriptionListForMobile/MySubscriptionListForMobile')
)
