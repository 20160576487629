type ClientConfig = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string | number]: any
}

/**
 * Configuration helper to access everywhere the configuration
 */
export default class ConfigHelper {
    // eslint-disable-next-line no-use-before-define
    private static _instance: ConfigHelper
    private static _config: ClientConfig

    // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
    private constructor() {}

    static getInstance() {
        if (!this._instance) {
            this._instance = new this()
        }
        return this._instance
    }

    setConfig = (config: ClientConfig) => (ConfigHelper._config = config)

    /**
     * Get configuration reference
     */
    getConfig = (rootKey?: keyof ClientConfig): ClientConfig | ClientConfig[keyof ClientConfig] => {
        if (rootKey) {
            return (ConfigHelper._config && ConfigHelper._config[rootKey]) || {}
        }
        return ConfigHelper._config || {}
    }
}
