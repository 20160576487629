import { of, throwError } from 'rxjs'
import { switchMap, catchError } from 'rxjs/operators'
import VoltError from 'VoltError'
import Fetch from '../fetch'
import { url } from 'services/proxy'
import { vodFactory } from 'services/proxy/factories/vod'
import { liveFactory } from 'services/proxy/factories/live'
import { channelFactory } from 'services/proxy/factories/channel'

const CONTENTS = 'contents'
const PAGE = 'page'
const PAGES = 'pages'

export default class SearchApi extends Fetch {
    /**
     * Helper used to parsed proxy response according to it's version
     * @param {*} response received from proxy
     * @returns content and pages
     *
     * Note for SearchAPI V3 -> For now, contents results has been changed to 'resources' yet.
     * Using CONTENTS constant until the change is made
     */
    getResult = (response) => {
        const resultField = CONTENTS // Temp & specific to SearchAPI: read note just above...
        const pageFiled = this.getVersion() >= 3 ? PAGE : PAGES
        return {
            contents: (response && response[resultField]) || [],
            pages: (response && response[pageFiled]) || {
                current: 0,
                total: 0,
                total_items_count: 0,
            },
        }
    }

    /**
     * Build date which format can differs from proxy v1 and v3
     * @param {Date} date Date
     */
    _buildDate(date) {
        if (!date) return undefined
        return this.getVersion() >= 3 ? parseInt(date.getTime() / 1000) : date.toISOString()
    }

    /**
     * Searches all contents that match the given query string and the metatypes, and returns a page of results.
     * Search is limited to title, casting and directors
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    _searchTerms({
        gt_schedule_begin,
        gt_schedule_end,
        limit = 20,
        lte_schedule_begin,
        lte_schedule_end,
        metatypes,
        offset = 0,
        in_schedule_channel,
        term,
        search_all_locales = true,
    }) {
        if (!term || !term.length) {
            return throwError(
                new VoltError(VoltError.codes.INVALID_VOLT_API_ARGUMENT, {
                    extraLog: 'search term cannot be empty',
                })
            )
        }

        const page = Math.floor(offset / limit) + 1
        const factory =
            metatypes === 'Vod'
                ? vodFactory
                : metatypes === 'Channel'
                ? channelFactory
                : liveFactory

        const defaultParams = {
            context: 'title',
            gt_schedule_begin,
            gt_schedule_end,
            limit,
            lte_schedule_begin,
            lte_schedule_end,
            page,
            ...(this.getVersion() >= 3 ? { search_all_locales } : {}),
            in_schedule_channel,
            text: term.trim(),
            not_rating: this.config.adultRating,
            metatypes,
            episode_expired_out: 'y', // retrieve only series with active episodes
        }

        return this.fetch({
            endpoint: url.search,
            params: defaultParams,
        }).pipe(
            switchMap((response) => {
                const {
                    contents,
                    pages: { current, total },
                } = this.getResult(response)

                const results = contents.map((content) => factory(content, this.config))
                const nextOffset = offset + limit
                const done = current === total
                return of({ results, done, nextOffset })
            }),
            catchError((err) => {
                // TEMP: Unblock 204 potential empty result from proxy while waiting for 204 error handling merge
                // Future fix will be to add { contents: [], pages: { current: 0, total: 0 } } in the case: 204.NO_CONTENT error inside parseMaculosaResponse
                if (
                    err.code === VoltError.codes.HTTP_404.code ||
                    err.code === VoltError.codes.HTTP_204.code
                ) {
                    return of({
                        results: [],
                        done: true,
                        nextOffset: 0,
                    })
                }
                return throwError(err)
            })
        )
    }

    /**
     * Searches all VOD contents that match the given query string and returns a page of results.
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    vodSearch({ term, offset, limit }) {
        const metatypes = 'Vod'

        return this._searchTerms({ limit, metatypes, offset, term })
    }

    /**
     * Searches all EPG contents that match the given query string and returns a page of results.
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    allEpgSearch({ term, offset, limit, channels }) {
        const metatypes = 'Schedule'

        return this._searchTerms({ limit, metatypes, offset, term, in_schedule_channel: channels })
    }

    /**
     * Searches channels contents that match the given query string and returns a page of results.
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    channelSearch({ term, offset, limit }) {
        const metatypes = 'Channel'

        return this._searchTerms({ limit, metatypes, offset, term })
    }

    /**
     * Searches all future EPG contents that match the given query string and returns a page of results.
     * The fields that are searched for in order of priority are: title, subtitle
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    futureEpgSearch({ term, offset, limit, channels }) {
        const metatypes = 'Schedule'
        const dateScheduleBegin = new Date()
        let dateScheduleEnd
        if (
            this.config.epg &&
            this.config.epg.numOfDaysFuture &&
            typeof this.config.epg.numOfDaysFuture === 'number'
        ) {
            dateScheduleEnd = new Date()
            dateScheduleEnd.setDate(dateScheduleEnd.getDate() + this.config.epg.numOfDaysFuture)
        }

        return this._searchTerms({
            lte_schedule_end: this._buildDate(dateScheduleEnd),
            gt_schedule_begin: this._buildDate(dateScheduleBegin),
            limit,
            metatypes,
            offset,
            term,
            in_schedule_channel: channels,
        })
    }

    /**
     * Searches all past EPG contents that match the given query string and returns a page of results.
     * The fields that are searched for in order of priority are: title, subtitle
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    pastEpgSearch({ term, offset, limit, channels }) {
        const metatypes = 'Schedule'
        const dateScheduleEnd = new Date()
        let dateScheduleBegin
        if (
            this.config.epg &&
            this.config.epg.numOfDaysPast &&
            typeof this.config.epg.numOfDaysPast === 'number'
        ) {
            dateScheduleBegin = new Date()
            dateScheduleBegin.setDate(dateScheduleBegin.getDate() - this.config.epg.numOfDaysPast)
        }

        return this._searchTerms({
            limit,
            lte_schedule_end: this._buildDate(dateScheduleEnd),
            gt_schedule_begin: this._buildDate(dateScheduleBegin),
            metatypes,
            offset,
            term,
            in_schedule_channel: channels,
        })
    }

    /**
     * Searches all currently broadcasting EPG contents that match the given query string and returns a page of results.
     * The fields that are searched for in order of priority are: title, subtitle
     *
     * @param {ProxySearchArgs} args
     * @returns {Observable<SearchResult>}
     */
    liveEpgSearch({ term, offset, limit, channels }) {
        const metatypes = 'Schedule'
        const now = new Date()
        const dateScheduleBegin = now
        const dateScheduleEnd = now

        return this._searchTerms({
            limit,
            lte_schedule_begin: this._buildDate(dateScheduleBegin),
            gt_schedule_end: this._buildDate(dateScheduleEnd),
            metatypes,
            offset,
            in_schedule_channel: channels,
            term,
        })
    }
}

/**
 * @typedef ProxySearchArgs
 * @property {String} term The search term
 * @property {Number} [offset=0] The pagination offset (with respect to the first result) of the query
 * @property {Number} [limit=20] The maximum number of results returned
 * @property {String} [metatypes] one of 'Vod', 'Scheduled' or 'Channel
 * @property {String} [lte_schedule_begin] maculosa generic filter for epg programs : start time lower than or equal to provided date (must be ISO format : YYYY-MM-DDTHH:mm:ss.sssZ)
 * @property {String} [gt_schedule_begin] maculosa generic filter for epg programs : start time greater than provided date (must be ISO format : YYYY-MM-DDTHH:mm:ss.sssZ)
 * @property {String} [lte_schedule_end] maculosa generic filter for epg programs : end time lower than or equal to provided date (must be ISO format : YYYY-MM-DDTHH:mm:ss.sssZ)
 * @property {String} [gt_schedule_end] maculosa generic filter for epg programs: end time greater thant provided date (must be ISO format : YYYY-MM-DDTHH:mm:ss.sssZ)
 */
