/**
 * @class Market One Partner Subscriptions
 * @property Documentation available here https://developer.m1amdocs.com/api/
 * This an nested class with represents the backend response.
 * Should NOT be used OUTSIDE M1 APIS !!!
 * @property {String} organizationId : The ID of the aggregator organization in MarketONE for which this record was created.
 * @property {String} accountId : The ID of the MarketONE account associated with this partner service record. This ID is not returned when the API is called with a partner API key. This will be returned withwhen the API is called with an aggregator API key.
 * @property {String} subscriptionId : The id of the SLM subscription associated with this partner service.
 * @property {String} partnerSubscriptionId : MarketONE partner service provisioning record identifier.
 * @property {String} registrationString : Information that was sent to the partner as part of the "AddSubscription" callback; e.g. registration code
 * @property {String} partnerUrl : The optional response value that identifies the Url where user will be directed to for entering the registration code and completing the registration
 * @property {String} partnerCode : The partner code as set by the partner admin in MarketONE.
 * @property {String} productCode : The product code defined by the partner identified by partner code.
 * @property {String} serviceStatus : Status of the partner service MarketONE information. UNPROVISIONED indicates that the record was created by the callback response was not received. INACTIVE indicates the provisioning
 * callback was sucesfully received by the partner service, but it has not been claimed by any service user yet. ACTIVE indicates that the service entitlement has been sucesfully provisioned, and a partner service user has claimed it.
 * CANCELLATION-IN-PROGRESS indicates that the a de-provisioning request is pending. CANCELLED indicates that the de-provisioning request confirmation has been received.
 * @property {String} billingStatus : The status of the OTT billing partner subscription. PENDING-VALIDATION - the BILLING partner subscription creation was requested by the OTT partner and it requires validation,
 * MarketONE triggered the OTP notification, aggregator sends the OTP to the consumer. The customer aggregator account details have not yet been validated as being correct. E.g. a MSISDN was provided, but we have not yet verified it belongs to the user.
 * PENDING-CONFIRMATION - MarketONE successfully validated the BILLING partner subscription. All the BILLING partner subscription details are validated at this point, MarketONE awaiting confirmation from the customer via the OTT partner that they wish this partner subscription to be active for billing.
 * ACTIVE - the BILLING partner subscription is available for billing CANCELLED - the BILLING partner subscription was canceled and can no longer be used for charges, but may be used for refunds if it had previously been active.
 * @property {String} startServiceDate : The timestamp (ISO 8601 format) when the partner service provisioning information record was created.
 * @property {String} endServiceDate : The timestamp (ISO 8601 format) when the partner service provisioning information record was updated.
 * @property {String} createdDate : The timestamp (ISO 8601 format) when the partner service provisioning information record was created.
 * @property {String} updatedDate : The timestamp (ISO 8601 format) when the partner service provisioning information record was updated.
 * @property {String} partnerTokenizedUrl
 * @property {String} redemptionCodeExpirationTime

 * 
 */
export class M1PartnerSubscription {
    constructor(props) {
        this.organizationId = props.organizationId
        this.accountId = props.accountId
        this.subscriptionId = props.subscriptionId
        this.partnerSubscriptionId = props.partnerSubscriptionId
        this.registrationString = props.registrationString
        this.partnerUrl = props.partnerUrl
        this.partnerCode = props.partnerCode
        this.productCode = props.productCode
        this.createdDate = props.createdDate
        this.updatedDate = props.updatedDate
        this.serviceStatus = props.serviceStatus
        this.billingStatus = props.billingStatus
        this.startServiceDate = props.startServiceDate
        this.endServiceDate = props.endServiceDate
        this.partnerTokenizedUrl = props.partnerTokenizedUrl
        this.redemptionCodeExpirationTime = props.redemptionCodeExpirationTime
        this.slmProductCode = props.slmProductCode
    }

    static SERVICE_STATUS = {
        ACTIVE: 'ACTIVE',
        CANCELLED: 'CANCELLED',
        CANCELLATION_IN_PROGRESS: 'CANCELLATION-IN-PROGRESS',
        INACTIVE: 'INACTIVE',
        UNPROVISIONED: 'UNPROVISIONED',
    }

    /**
     * Check if the Partner Subscription is linked the same User Subscription (Strange Backend)
     * @param {M1UserSubscription} M1UserSubscription
     */
    isLinkedWithUserSubscription(M1UserSubscription) {
        return (
            M1UserSubscription &&
            M1UserSubscription.internalProviderSubscriptionId === this.subscriptionId &&
            M1UserSubscription?.productId === this.slmProductCode
        )
    }

    /**
     * To be used for Debug and log
     */
    toString() {
        return `billingStatus: ${this.billingStatus} || serviceStatus: ${this.serviceStatus} || subscriptionId: ${this.subscriptionId} || partnerSubscriptionId: ${this.partnerSubscriptionId} || startServiceDate: ${this.startServiceDate} || startServiceDate: ${this.startServiceDate}`
    }
}
