/* eslint-disable no-use-before-define */
import { from } from 'rxjs'

import EncryptionHelper from 'framework/helpers/encryption'
import { HashMap } from '@typings/generic'
import DefaultStorage from 'framework/helpers/DefaultStorage'

/**
 * This class is a helper which offers multiples functionalities.
 * This Helper provides a data access to :
 *      - Store/Read/Clear data from locale memory storage
 *      - Aggregates Data needed in runtime by offering accessors, rather than using AuthApi
 *        to access data like Access Token etc.. as it could create a circular dependencies
 *        and make difficult the sharing of backend components between clients
 *
 * To be used this helper, please proceed as follow :
 * 1. Access this helper by importing this dependencies
 *      > import DataHelper from 'framework/helpers/data'
 *
 * 2. Then use the singleton to manipulate this class
 *      > DataHelper.getInstance().getData(DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN)
 *      > DataHelper.getInstance().getPrimaryAccessToken()
 *
 * 3. There are a set of predefined keys which allows to access to predefined data to make it generic (@link DataHelper.STORE_KEY)
 * Example : > DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN
 *           > DataHelper.STORE_KEY.BACKEND_USER_DEVICE_ID
 *
 * 4. You also have the capability to overloaded the keys above (@link DataHelper.STORE_KEY) using the overloaded method at initialization stage
 * Example: DataHelper.getInstance().overloadStoreKeys({
 *              PRIMARY_ACCESS_TOKEN: 'oauthAccessToken',
 *              IMAGE_API_ACCESS_TOKEN: 'oauthAccessToken',
 *           })
 * By doing this, you will be able to use the same value to Primary Access Token and Image Access Token
 *
 * 5. Base on point 4. to access to the image API, please use the following KEY (do not use the overloaded key) as follow :
 *          > DataHelper.getInstance().getData(DataHelper.STORE_KEY.IMAGE_API_ACCESS_TOKEN)
 * and not :
 *          > DataHelper.getInstance().getData('oauthAccessToken')
 * As this class wraps the management on nested overloaded keys
 *
 * 6. To synchronize Data stored in local storage at boot, you should call the following method earlier.
 * This call is used to be called during the authenticate() method binded to fetchProfile() but feel free to call
 * it earlier if needed
 *      > DataHelper.getInstance().readAllData()
 */

export default class DataHelper {
    /**
     * Singleton Instance
     */
    // eslint-disable-next-line no-use-before-define
    private static _instance: DataHelper

    /**
     * Key used to store and manage data
     */
    static STORE_KEY = {
        // ---- Backend URL retrieved dynamically (Example via a load balancer) ----
        BACKEND_API_URL: 'BACKEND_API_URL',
        DRM_LICENSE_SERVER_URL: 'DRM_LICENSE_SERVER_URL',
        FAIRPLAY_CERTIFICATE_SERVER_URL: 'FAIRPLAY_CERTIFICATE_SERVER_URL',
        // ---- Primary Authentication ----
        PRIMARY_ACCESS_TOKEN: 'PRIMARY_ACCESS_TOKEN',
        PRIMARY_REFRESH_TOKEN: 'PRIMARY_REFRESH_TOKEN',
        PRIMARY_ID_TOKEN: 'PRIMARY_ID_TOKEN',
        PRIMARY_ACCESS_TOKEN_EXPIRATION_TIME_MS: 'PRIMARY_ACCESS_TOKEN_EXPIRATION_TIME_MS',
        PRIMARY_ACCESS_TOKEN_ISSUED_TIME_MS: 'PRIMARY_ACCESS_TOKEN_ISSUED_TIME_MS',
        PRIMARY_CLIENT_ID: 'PRIMARY_CLIENT_ID',
        // ---- Secondary Authentication ----
        SECONDARY_ACCESS_TOKEN: 'SECONDARY_ACCESS_TOKEN',
        SECONDARY_REFRESH_TOKEN: 'SECONDARY_REFRESH_TOKEN',
        SECONDARY_ACCESS_TOKEN_EXPIRATION_TIME_MS: 'SECONDARY_ACCESS_TOKEN_EXPIRATION_TIME_MS',
        SECONDARY_ACCESS_TOKEN_ISSUED_TIME_MS: 'SECONDARY_ACCESS_TOKEN_ISSUED_TIME_MS',
        SECONDARY_CLIENT_ID: 'SECONDARY_CLIENT_ID',
        // ---- Session Token (The difference with other token is we don't know the expiration of the token, backend does)----
        SESSION_TOKEN: 'SESSION_TOKEN',
        SESSION_COOKIE: 'SESSION_COOKIE',
        // ---- External Drm Access Token ----
        EXTERNAL_DRM_ACCESS_TOKEN: 'EXTERNAL_DRM_ACCESS_TOKEN',
        EXTERNAL_DRM_ACCESS_TOKEN_EXPIRATION_TIME_MS:
            'EXTERNAL_DRM_ACCESS_TOKEN_EXPIRATION_TIME_MS',
        EXTERNAL_DRM_ACCESS_TOKEN_ISSUED_TIME_MS: 'EXTERNAL_DRM_ACCESS_TOKEN_ISSUED_TIME_MS',
        // ---- Gateway API token ----
        GATEWAY_API_TOKEN: 'GATEWAY_API_TOKEN',
        // ---- Image Access Token ----
        IMAGE_API_ACCESS_TOKEN: 'IMAGE_API_ACCESS_TOKEN',
        // ---- Backend Device ID ----
        BACKEND_USER_DEVICE_ID: 'BACKEND_USER_DEVICE_ID',
        BACKEND_USER_DEVICE_NAME: 'BACKEND_USER_DEVICE_NAME',
        DRM_USER_DEVICE_ID: 'DRM_USER_DEVICE_ID',
        // ---- Account infos ----
        SUBSCRIBER_TYPE: 'SUBSCRIBER_TYPE',
        SUBSCRIBER_ID: 'SUBSCRIBER_ID',
        SUBSCRIBER_ID2: 'SUBSCRIBER_ID2', // Give a different name to deceive the ennemy
        BUSINESS_TYPE: 'BUSINESS_TYPE',
        CHANNEL_NAMESPACE: 'CHANNEL_NAMESPACE',
        USER_GROUP: 'USER_GROUP',
        CURRENT_PROFILE_ID: 'CURRENT_PROFILE_ID',
        ACCOUNT_ID: 'ACCOUNT_ID',
        ACCOUNT_VID: 'ACCOUNT_VID',
        ACCOUNT_USER_NAME: 'ACCOUNT_USER_NAME',
        DEFAULT_PAYMENT_METHOD: 'DEFAULT_PAYMENT_METHOD',
        USER_PC_LEVEL: 'USER_PC_LEVEL',
        PROFILE_TYPE: 'PROFILE_TYPE',
        LANGUAGE_LOGIN: 'LANGUAGE_LOGIN',
        USER_PHONE_NUMBER: 'USER_PHONE_NUMBER',
        // ---- Account infos for Starhub ----
        ESSOID: 'ESSOID',
        HUBID: 'HUBID',
        // ---- Device infos ----
        CASN: 'CASN', // The CASN is an alternative serial number available on some chip dealing with the CAS (DRM)
        SERIAL_NUMBER: 'SERIAL_NUMBER',
        MAC_ADDRESS: 'MAC_ADDRESS',
        DISPLAY_GAME_SCORES: 'DISPLAY_GAME_SCORES',
        FAVORITE_CATALOGS: 'FAVORITE_CATALOGS',
        CACHED_IDS_LISTS: 'CACHED_IDS_LISTS',
        STB_MIGRATION_LOGIN: 'STB_MIGRATION_LOGIN', // TO BE REMOVED AFTER MIGRATION of STARHUB INNOPIRA BOARD
        // ---- Player analytics API ----
        PLAYER_ANALYTICS_KEYS: 'PLAYER_ANALYTICS_KEYS',
        // ---- Proxy API ----
        PROXY_REVISION: 'PROXY_REVISION',
    }
    static overloadingMap: HashMap<string, string>
    static storage: DefaultStorage

    // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-empty-function
    private constructor() {
        if (!DataHelper._instance) {
            DataHelper.storage = new DefaultStorage()
            // Use default store keys
            this.overloadStoreKeys()
            DataHelper._instance = this
        }
        return DataHelper._instance
    }

    /**
     * Use to retrieve the instance of this class
     * @returns instance
     */
    static getInstance() {
        if (!this._instance) {
            this._instance = new DataHelper()
        }
        return this._instance
    }

    static [key: string]:
        | DataHelper
        | HashMap<string, string>
        | DefaultStorage
        | (() => DataHelper)
        | undefined
        | string

    //-----------------------------------------------------
    //-----                    INIT                   -----
    //-----------------------------------------------------
    /**
     * Overload locale store keys to be able to change them and mutualize them
     */
    overloadStoreKeys(storeKeys?: HashMap<string, string>) {
        DataHelper.overloadingMap = Object.keys(DataHelper.STORE_KEY).reduce<
            HashMap<string, string>
        >((acc, storeKey) => {
            if (storeKeys && storeKeys[storeKey]) {
                acc[storeKey] = storeKeys[storeKey]
            } else {
                acc[storeKey] = storeKey
            }

            return acc
        }, {})
    }

    //-----------------------------------------------------
    //-----                   TOOLBOX                 -----
    //-----------------------------------------------------
    /**
     *
     * @param {String} storeKey Store key (@link DataHelper.STORE_KEY)
     */
    getData(storeKey: string): string | undefined {
        if (this._supportStoreKey(storeKey)) {
            return DataHelper[this._getOverloadedKey(storeKey)] as string
        }
        return undefined
    }

    /**
     * Synchronize static values of the instance
     * @param {String} overloadedStoreKey Store key
     * @param {String} storeValue Value
     */
    _setData = (overloadedStoreKey: string, storeValue?: string) => {
        DataHelper[overloadedStoreKey] = storeValue
    }

    /**
     * Returns if store key is supported or not
     * @param {String} storeKey Store key (@link DataHelper.STORE_KEY)
     * @returns true/false
     */
    _supportStoreKey = (storeKey: string) => {
        return Object.keys(DataHelper.STORE_KEY).includes(storeKey)
    }

    //-----------------------------------------------------
    //-----                  ACCESSOR                 -----
    //-----------------------------------------------------
    getPrimaryAccessToken() {
        return this.getData(DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN)
    }

    getPrimaryRefreshToken() {
        return this.getData(DataHelper.STORE_KEY.PRIMARY_REFRESH_TOKEN)
    }

    getSecondaryAccessToken() {
        return this.getData(DataHelper.STORE_KEY.SECONDARY_ACCESS_TOKEN)
    }

    getSecondaryRefreshToken() {
        return this.getData(DataHelper.STORE_KEY.SECONDARY_REFRESH_TOKEN)
    }

    getAccountId() {
        return this.getData(DataHelper.STORE_KEY.ACCOUNT_ID)
    }

    //-----------------------------------------------------
    //-----            STORAGE MANAGEMENT             -----
    //-----------------------------------------------------
    /**
     * Stores a list of values in the storage
     * @param {Array<String[]>} keyValuePairs Array of key/value pairs to store (@link DataHelper.STORE_KEY)
     * @returns {Observable}
     */
    storeData = (keyValuePairs: [string, string][]) => {
        const myKeyValuePairs = (keyValuePairs || []).reduce<string[][]>((acc, [key, value]) => {
            if (this._supportStoreKey(key)) {
                const internalKey = this._getOverloadedKey(key)
                this._setData(internalKey, value)

                // Save encrypted value in memory, but internally keep it decrypted
                const encryptedValue: string = this._encrypt(value && value.toString())
                if (value) {
                    acc.push([internalKey, encryptedValue])
                }
            }
            return acc
        }, [])

        return from(DataHelper.storage.multiSet(myKeyValuePairs))
    }

    /**
     * Synchronize all the related key/values including from the storage (to be used at boot)
     * @param {Array<String[]>} storeKeys Array of keys to delete (@link DataHelper.STORE_KEY)
     * @returns {Observable}
     */
    readData = (storeKeys: string[]) => {
        const overloadedStoreKeys = this._getOverloadedKeys(storeKeys)
        return from(
            DataHelper.storage.multiGet(overloadedStoreKeys).then((memory) => {
                const result = (memory || []).reduce(
                    (acc: Array<string[]>, [key, value]: (string | null)[], index: number) => {
                        // Save encrypted value in memory, but internally keep it decrypted
                        const decryptedValue = this._decrypt(value!)
                        this._setData(key!, decryptedValue)
                        acc.push([storeKeys[index], decryptedValue])
                        return acc
                    },
                    []
                )
                return result
            })
        )
    }

    /**
     * Synchronize all the related key/values including from the storage (to be used at boot)
     * @returns {Observable}
     */
    readAllData = () => {
        const storeKeys = Object.keys(DataHelper.STORE_KEY)
        return this.readData(storeKeys)
    }

    /**
     * Deletes a given list of values
     * @param {Array<String[]>} storeKeys Array of keys to delete (@link DataHelper.STORE_KEY)
     * @returns {Observable}
     */
    clearData = (storeKeys: string[]) => {
        const overloadedStoreKeys = this._getOverloadedKeys(storeKeys)
        return from(
            DataHelper.storage.multiRemove(overloadedStoreKeys).then(() => {
                overloadedStoreKeys.forEach((key) => {
                    this._setData(key, undefined)
                })
            })
        )
    }

    /**
     * Deletes all the related key/values including in storage
     * @returns {Observable}
     */
    clearAllData = () => {
        const storeKeys = Object.keys(DataHelper.STORE_KEY)
        return this.clearData(storeKeys)
    }

    //-----------------------------------------------------
    //-----             NESTED TOOLBOX                -----
    //-----------------------------------------------------
    /**
     * Returns (internal) overloaded Store Keys
     * @param {String} storeKey (@link DataHelper.STORE_KEY)
     * @returns {String} Overloaded (internal) Storage Key
     */
    _getOverloadedKey(storeKey: string) {
        return DataHelper.overloadingMap[storeKey]
    }

    /**
     * Returns (internal) overloaded Store Keys
     * @param {Array<String>} storeKeys (@link DataHelper.STORE_KEY)
     * @returns {String} Overloaded (internal) Storage Key
     */
    _getOverloadedKeys(storeKeys: string[]) {
        return (storeKeys || []).reduce<string[]>((acc, storeKey) => {
            acc.push(DataHelper.overloadingMap[storeKey])
            return acc
        }, [])
    }

    /*
     * Override the storage Helper
     * Unfortunately the DefaultStorage class is available only for browser, not react native application
     * Use temporary this method to override the helper provided by react native apps (stb, mobile) at init
     * and based on asyncStorage
     * @todo rework
     * @param {Object} storageHelper storageHelper
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setStorageHelper(storageHelper: any) {
        DataHelper.storage = storageHelper
    }

    /**
     * Encrypts sensitiveValue
     * @param {String} sensitiveValue
     * @returns {String} encrypted value
     */
    _encrypt(sensitiveValue: string) {
        return EncryptionHelper.getInstance().encrypt(sensitiveValue)
    }

    /**
     * Decrypts sensitiveValue
     * @param {String} value
     * @returns {String} decrypted value
     */
    _decrypt(value: string): string {
        const { data /*, decrypted*/ } = EncryptionHelper.getInstance().decrypt(value) || {}
        return data || null // Returns null rather undefined to keep the compat with local storage behaviour
    }
}
