import { from, of } from 'rxjs'
import VoltError from 'VoltError'
import { throwError } from 'rxjs'

export default class SettingsApi {
    constructor(config) {
        this.config = config
    }

    /**
     * Updates the Parental Control level of the user
     *
     * @param {String|Number} level
     * @param {String} Profile ID
     * @return {Observable}
     */
    updatePcLevel(level, profileId) {
        return of({})
    }

    /**
     * Updates the user pin code
     *
     * @param {String} pin type to update, either PURCHASE or PARENTAL (cf PINApi.TYPE)
     * @param {String|Number} pin code
     * @return {Observable}
     */
    updatePin(type, newPin) {
        return from(this.config.persistentStorage.setItem(type, newPin))
    }

    /**
     * Modify user password
     * @param {Object} arg
     * @param {String} arg.password Current Password
     * @param {String} arg.newPassword New Password
     * @return {Observable<Boolean>} true if the password is verified
     */
    updatePassword({ password, newPassword }) {
        return of(true)
    }

    /**
     * Checks if a given password is valid (can be used before performing specific action like Reset PIN)
     * @param {Object} arg
     * @param {String} arg.password Password
     * @return {Observable<Boolean>} true if the password is verified
     */
    verifyPassword({ password }) {
        return of(true)
    }

    /**
     * Checks if a given password is valid (can be used before performing specific action like Reset PIN)
     * @param {Object} arg
     * @param {String} arg.languageCode Should be two digit
     * @return {Observable<Boolean>} true if the password is verified
     */
    updateAccountLocale({ languageCode }) {
        return of(true)
    }

    /**
     * Update business Mode
     * @param {Constants.businessType.B2B|Constants.businessType.B2C} businessMode Business Mode
     */
    updateBusinessMode(businessMode) {
        return throwError(
            new VoltError(VoltError.codes.SERVICE_NOT_ALLOWED, {
                extraLog: 'Feature not supported',
            })
        )
    }
}
