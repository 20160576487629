export default {
    STUB: 'stub',
    EVERGENT: 'evergent',
    SEACHANGE: 'seachange',
    KALTURA: 'kaltura',
    VUBIQUITY: 'vubiquity',
    MARKETONE: 'marketone',
    VINDICIA: 'vindicia',
    HUAWEI: 'huawei',
    // Starhub is a virtual Backend as it does not really exist. It reflect the hybridization between Nagra and Ufinity Backends
    STARHUB: 'starhub',
    ATT: 'att',
    DCM: 'dcm',
    H3G: 'h3g',
    MARKETPLACE: 'marketplace',
}
