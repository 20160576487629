import { throwError, of, Observable } from 'rxjs'
import { catchError, mergeMap, tap, map } from 'rxjs/operators'
import VoltError from 'VoltError'
import OauthFetch from './fetch'
import { serialize } from 'helpers/index.js'
import { OAuthDeviceFlowStatus } from 'models'
import DataHelper from 'framework/helpers/data'
import TokenHelper from 'framework/helpers/token'
import { HashMap, IHeaders } from '@typings/generic'
import DefaultStorage from 'framework/helpers/DefaultStorage'

/**
 * Toolbox class to handle the Device Authorization Grant
 * https://auth0.com/docs/flows/device-authorization-flow
 */
export default class OAuthDeviceFlow extends OauthFetch {
    static TAG = 'OAuthApi'
    oauthConfig: { url: any; clientId: any; clientSecret: any; param: any }
    extendedFetch: {
        refreshToken?: () => Observable<{
            response: {
                access_token?: string
                refresh_token?: string
                expires_in?: number
                id_token?: string
            }
        }>
    }
    _tokenHelper: TokenHelper
    oauthStatus: OAuthDeviceFlowStatus
    oauthDeviceId: string
    deviceData?: any

    constructor(config: any, name: string, { enableRefreshToken = true } = {}, extendedFetch = {}) {
        super(config, name)
        this.oauthConfig = {
            url: config.urls.authUrl,
            clientId: config.clientId,
            clientSecret: config.clientSecret,
            param: config.OAuthParam || {
                scope: 'openid profile email offline_access',
                endpoint: {
                    deviceCode: '/oidc/devicecode',
                    token: '/oidc/token',
                    refreshToken: '/oidc/token',
                },
                debugMode: false,
            },
        }

        this.extendedFetch = extendedFetch

        this._tokenHelper = new TokenHelper(
            this._refreshAccessToken.bind(this),
            DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN,
            this.logger,
            enableRefreshToken
        )

        this.oauthStatus = new OAuthDeviceFlowStatus({
            status: OAuthDeviceFlowStatus.OAUTH_STATUS.NOT_STARTED,
        })
        // Cached by this module to be reused if needed
        this.oauthDeviceId = ''
    }

    /**
     * This function follows the nomenclature from VOLT API.
     * This function does not do the authentication but only check if storage contains
     * Token cached, then refresh the access token if the expirations occurred
     * Throw en error if there is not authentication token
     * @param {Boolean} [args.needToRefreshToken] Need to refresh the access Token
     * @returns {Observable<Boolean>}
     */
    _authenticate(needToRefreshToken = false) {
        return DataHelper.getInstance()
            .readAllData()
            .pipe(
                mergeMap(() => {
                    // If we do not have an access token in storage, redo the authentication process
                    if (!this._tokenHelper.hasToken()) {
                        return throwError(
                            new VoltError(VoltError.codes.LOGIN_REQUIRED, {
                                extraLog: 'No Access token found',
                            })
                        )
                    }

                    // If the access token has expired, just refresh it without redoing the login process
                    if (!this._tokenHelper.isTokenValid()) {
                        needToRefreshToken = true
                        this.logger.info(
                            OAuthDeviceFlow.TAG,
                            'Init : Access Token is no more valid, try to refresh the access token'
                        )
                    }

                    if (this._needToRefreshAccessTokenOnBoot()) {
                        needToRefreshToken = true
                        this.logger.info(
                            OAuthDeviceFlow.TAG,
                            'Init : Access Token not refresh since duration configured, refresh it now'
                        )
                    }

                    if (needToRefreshToken) {
                        return this._refreshAccessToken().pipe(
                            mergeMap(() => {
                                this.logger.info(
                                    OAuthDeviceFlow.TAG,
                                    `Token successfully refreshed`
                                )
                                // (Optional) Update status to keep the consistency but not really necessary from upper layer standpoint
                                this.oauthStatus = this.oauthStatus.update({
                                    status: OAuthDeviceFlowStatus.OAUTH_STATUS
                                        .LOGIN_SECONDARY_DEVICE,
                                }) as OAuthDeviceFlowStatus
                                return of(true)
                            }),
                            catchError((error) => {
                                if (VoltError.codes.INVALID_AUTH_TOKEN.code === error.code) {
                                    return throwError(
                                        new VoltError(VoltError.codes.INVALID_AUTH_TOKEN, {
                                            extraLog:
                                                'Invalid Token got from the backend. Force to re-login',
                                            backend: this.backendName,
                                        })
                                    )
                                } else {
                                    // Do not invite the user to login again for irrelevant issue. Could create more issue than the optimization desired
                                    this.logger.error(
                                        OAuthDeviceFlow.TAG,
                                        `Init : An error occurred during the refresh token ${error}. But do not invite the user to login again`
                                    )
                                    // (Optional) Update status to keep the consistency but not really necessary from upper layer standpoint
                                    this.oauthStatus = this.oauthStatus.update({
                                        status: OAuthDeviceFlowStatus.OAUTH_STATUS
                                            .LOGIN_SECONDARY_DEVICE,
                                    }) as OAuthDeviceFlowStatus
                                    return of(true)
                                }
                            })
                        )
                    }

                    this.logger.info(
                        OAuthDeviceFlow.TAG,
                        'Init : Access Token is STILL valid but plan to refresh the access token'
                    )
                    this._startAccessTokenRefreshTimer()

                    // Here, for sure we consider that storage contains valid access token
                    this.oauthStatus = this.oauthStatus.update({
                        status: OAuthDeviceFlowStatus.OAUTH_STATUS.LOGIN_SECONDARY_DEVICE,
                    }) as OAuthDeviceFlowStatus

                    return of(true)
                })
            )
    }

    /**
     * This method follows the standard https://auth0.com/docs/flows/device-authorization-flow and
     * perform the request to INITIALIZE the authentication with secondary device providing user code
     * @param {Object} args
     * @param {String} args.deviceId Unique device ID for the device (differs according to platform)
     * @param {Object} [args.forceHeaders] Offers the possibility to force the header of the requests as it may differs according to servers
     * @param {Object} [args.forceBody] Offers the possibility to force the forceBody of the requests as it may differs according to servers
     * @param {Object} [data.deviceData] optional device data (brand, model, serial number, casn, etc...)
     * @returns {OAuthDeviceFlowStatus} Oauth status updated
     */
    loginWithSecondaryDevice({
        deviceId,
        forceHeaders,
        forceBody,
        deviceData,
    }: {
        deviceId: string
        forceHeaders?: IHeaders
        forceBody?: object
        deviceData?: any
    }) {
        const url = `${this.oauthConfig.url}${this.oauthConfig.param.endpoint.deviceCode}`
        const body = forceBody
            ? forceBody
            : {
                  client_id: this.oauthConfig.clientId,
                  client_secret: this.oauthConfig.clientSecret,
                  device_id: deviceId,
                  scope: this.oauthConfig.param.scope,
              }
        const headers: IHeaders = forceHeaders
            ? forceHeaders
            : {
                  'Content-Type': 'application/x-www-form-urlencoded',
              }

        this.oauthDeviceId = deviceId
        this.deviceData = deviceData

        return this.fetch<{
            device_code: string
            user_code: string
            verification_uri: string
            verification_uri_complete: string
            expires_in: number
            interval: number
            ulm_details: {
                notification_dest: string
                'x-process-debug-token-series': string
                'x-process-debug-token-url': string
                notification_type: string
            }
        }>({
            url,
            body,
            headers,
            method: 'POST',
            log: 'REQUESTING DEVICE CODE',
        }).pipe(
            mergeMap(({ response }) => {
                const {
                    device_code,
                    user_code,
                    verification_uri,
                    verification_uri_complete,
                    expires_in,
                    interval,
                    ulm_details, // Not part of the standard héhé
                } = response || {}
                let pollingIntervalSecs = interval
                if (this.oauthConfig.param.forcePollingIntervalSecs) {
                    pollingIntervalSecs = this.oauthConfig.param.forcePollingIntervalSecs
                    this.logger.info(
                        OAuthDeviceFlow.TAG,
                        `Backend Polling interval ${interval} overriden by the configuration ${pollingIntervalSecs}`
                    )
                }
                this.oauthStatus = this.oauthStatus.update({
                    status: OAuthDeviceFlowStatus.OAUTH_STATUS.PENDING_LOGIN_SECONDARY_DEVICE,
                    deviceCode: device_code,
                    userCode: user_code,
                    verificationUri: verification_uri,
                    verificationUriComplete: verification_uri_complete,
                    expiresInSecs: expires_in,
                    pollingIntervalSecs,
                    email: ulm_details && ulm_details.notification_dest,
                    displayType:
                        ulm_details && ulm_details.notification_type === 'EMAIL'
                            ? OAuthDeviceFlowStatus.DISPLAY_TYPE.EMAIL
                            : OAuthDeviceFlowStatus.DISPLAY_TYPE.USER_CODE,
                    isDebugMode: this.oauthConfig.param.debugMode,
                    debugProperties: this.oauthConfig.param.debugMode
                        ? {
                              // Those field are specific for MarketOne, will be removed while front page for OAuth delivered
                              debugTokenSeries:
                                  ulm_details && ulm_details['x-process-debug-token-series'],
                              debugTokenUrl:
                                  ulm_details && ulm_details['x-process-debug-token-url'],
                          }
                        : {},
                }) as OAuthDeviceFlowStatus
                return of(this.oauthStatus) // Should we need to return of copy
            }),
            catchError((error) => {
                this.oauthStatus = this.oauthStatus.update({
                    status:
                        error.code === VoltError.codes.AUTH_UNAUTHORIZED_USER.code
                            ? OAuthDeviceFlowStatus.OAUTH_STATUS.ERROR_DEVICE_NOT_REGISTERED
                            : OAuthDeviceFlowStatus.OAUTH_STATUS.ERROR,
                    error,
                }) as OAuthDeviceFlowStatus
                return of(this.oauthStatus)
            })
        )
    }

    pullingAuthenticationStatus({
        forceHeaders,
        forcePayload,
        isUsingParamPayload,
    }: {
        forceHeaders: IHeaders
        forcePayload: object
        isUsingParamPayload: boolean
    }) {
        const payload = forcePayload
            ? forcePayload
            : {
                  grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
                  client_id: this.oauthConfig.clientId,
                  client_secret: this.oauthConfig.clientSecret,
                  device_code: this.oauthStatus.deviceCode,
              }

        // Some backend accepts parameters, others a body it depends
        const url = `${this.oauthConfig.url}${this.oauthConfig.param.endpoint.token}${
            isUsingParamPayload
                ? serialize({
                      ...payload,
                  })
                : ''
        }`

        return this.fetch<{
            access_token?: string
            refresh_token?: string
            expires_in?: number
            id_token?: string
        }>({
            url,
            headers: forceHeaders,
            body: isUsingParamPayload ? undefined : payload,
            method: 'POST',
            log: 'POLLING LOGIN STATUS',
        })
    }

    /**
     * This method follows the standard https://auth0.com/docs/flows/device-authorization-flow and
     * Check through a polling if the user is authenticated or not
     * @param {Object} args
     * @param {Object} [args.forceHeaders] Offers the possibility to force the HEADERS of the requests as it may differs according to servers
     * @param {Object} [args.forcePayload] Offers the possibility to force the PAYLOAD of the requests as it may differs according to servers
     * @param {Object} [args.isUsingParamPayload=false] If true send the data through request parameters, otherwise through the body
     */
    isLoginWithSecondaryDevice({
        forceHeaders,
        forcePayload,
        isUsingParamPayload = false,
    }: {
        forceHeaders: IHeaders
        forcePayload: object
        isUsingParamPayload: boolean
    }) {
        if (!this.oauthStatus || !this.oauthStatus.deviceCode) {
            const errorMsg =
                'DeviceCode is not available, cannot proceed for server polling to check authentication status!!!'

            this.logger.error(OAuthDeviceFlow.TAG, errorMsg)
            return throwError(
                new VoltError(VoltError.codes.AUTH_ERROR, {
                    extraLog: errorMsg,
                    backend: this.backendName,
                })
            )
        }
        if (
            this.oauthStatus.status === OAuthDeviceFlowStatus.OAUTH_STATUS.LOGIN_SECONDARY_DEVICE &&
            this._tokenHelper.isTokenValid()
        ) {
            // Once the server has issued an access token, any other call to this API will throw an error
            // This is why here, we consider this extra call which should not occurs as succeeded as Access Token is valid
            this.logger.warn(
                OAuthDeviceFlow.TAG,
                'Extra call to this method although the device is authenticated. You should not call twice this method when access token has been already retrieved. Security to avoid false negative: Consider the device is authenticated'
            )
            return of(this.oauthStatus)
        }
        if (
            this.oauthStatus.status !==
            OAuthDeviceFlowStatus.OAUTH_STATUS.PENDING_LOGIN_SECONDARY_DEVICE
        ) {
            // Not considered yet as a blocking error
            this.logger.warn(
                OAuthDeviceFlow.TAG,
                'You are trying to check if login with secondary device but OAuth sequence has not been correctly initialized'
            )
        }

        return this.pullingAuthenticationStatus({
            forceHeaders,
            forcePayload,
            isUsingParamPayload,
        }).pipe(
            mergeMap(({ response }) => {
                return this._parseAccessToken(response, true).pipe(
                    map(() => this.oauthStatus),
                    tap(() => this._startAccessTokenRefreshTimer())
                )
            }),
            catchError((error) => {
                if (
                    VoltError.codes.AUTH_OAUTH_WAITING_LOGIN_WITH_SECONDARY_DEVICE.code ===
                    error.code
                ) {
                    // Do Nothing, we still should been in that state
                    this.oauthStatus = this.oauthStatus.update({
                        status: OAuthDeviceFlowStatus.OAUTH_STATUS.PENDING_LOGIN_SECONDARY_DEVICE,
                    }) as OAuthDeviceFlowStatus
                    this.logger.info(
                        OAuthDeviceFlow.TAG,
                        'Authentication still pending with secondary device'
                    )
                    return of(this.oauthStatus)
                }
                if (VoltError.codes.AUTH_OAUTH_SLOW_DOWN_POLLING.code === error.code) {
                    // Do Nothing, we just should increase the interval of polling from the server
                    this.oauthStatus.setSlowDown()
                    this.logger.info(
                        OAuthDeviceFlow.TAG,
                        'Slow down the polling. Increase by 5 secs according to the RFC :)'
                    )
                    return of(this.oauthStatus)
                }
                if (VoltError.codes.AUTH_OAUTH_EXPIRED_TOKEN.code === error.code) {
                    // Do Nothing, we just should increase the interval of polling from the server
                    this.oauthStatus = this.oauthStatus.update({
                        status: OAuthDeviceFlowStatus.OAUTH_STATUS.TIMEOUT_LOGIN_SECONDARY_DEVICE,
                    }) as OAuthDeviceFlowStatus
                    this.logger.info(
                        OAuthDeviceFlow.TAG,
                        'Token has expired during the POLLING process, the user should redo the OAUTH process'
                    )
                    return of(this.oauthStatus)
                }

                this.logger.error(
                    OAuthDeviceFlow.TAG,
                    'Error occurred during the POLLING, stop the POLLING the user should REDO the authentication from the beginning'
                )

                // This API does not throw an error, but return a status with an error included in the object
                this.oauthStatus = this.oauthStatus.update({
                    status: OAuthDeviceFlowStatus.OAUTH_STATUS.ERROR,
                    error,
                }) as OAuthDeviceFlowStatus
                return of(this.oauthStatus)
            })
        )
    }

    pullingRefreshAccessToken() {
        const oauthRefreshToken = DataHelper.getInstance().getPrimaryRefreshToken()
        if (!oauthRefreshToken) {
            const errorMsg = 'Refresh Token is not available, cannot proceed for token refresh !!!'

            this.logger.error(OAuthDeviceFlow.TAG, errorMsg)
            return throwError(
                new VoltError(VoltError.codes.INVALID_AUTH_TOKEN, {
                    extraLog: errorMsg,
                    backend: this.backendName,
                })
            )
        }

        this.logger.info(OAuthDeviceFlow.TAG, 'Refreshing access Token')

        let url = `${this.oauthConfig.url}`

        if (this.oauthConfig.param.endpoint?.fullRefreshTokenUrl) {
            url = `${this.oauthConfig.param.endpoint.fullRefreshTokenUrl}`
        } else {
            url += this.oauthConfig.param.endpoint.refreshToken
                ? this.oauthConfig.param.endpoint.refreshToken
                : this.oauthConfig.param.endpoint.token
        }

        let body: {
            grant_type: string
            client_id: any
            refresh_token:
                | string
                | DataHelper
                | HashMap<string, string>
                | DefaultStorage
                | (() => DataHelper)
            client_secret?: string
        } = {
            grant_type: 'refresh_token',
            client_id: this.oauthConfig.clientId,
            refresh_token: oauthRefreshToken,
        }
        if (this.oauthConfig.clientSecret) {
            body = {
                ...body,
                client_secret: this.oauthConfig.clientSecret,
            }
        }

        return this.fetch<{
            access_token?: string
            refresh_token?: string
            expires_in?: number
            id_token?: string
        }>({
            url,
            body,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // TODOMRI Handle it outside
            },
            method: 'POST',
            log: 'REFRESH ACCESS TOKEN',
        })
    }

    /**
     * Refresh the access Token
     */
    _refreshAccessToken() {
        return this.pullingRefreshAccessToken().pipe(
            mergeMap(({ response }) => {
                return this._parseAccessToken(response).pipe(
                    tap(() => this._startAccessTokenRefreshTimer())
                )
            }),
            catchError((err = {}) => {
                let error
                switch (err.code) {
                    case VoltError.codes.AUTH_INVALID_CONFIG.code:
                    case VoltError.codes.INVALID_AUTH_TOKEN.code:
                        error = VoltError.codes.INVALID_AUTH_TOKEN
                        break
                    case VoltError.codes.UNKNOWN_API_ERROR.code:
                        error = VoltError.codes.AUTH_ERROR
                        break
                    default:
                        error = err
                        break
                }

                return throwError(
                    new VoltError(error, {
                        inheritedError: err,
                        backend: this.backendName,
                    })
                )
            })
        )
    }

    logout() {
        this._tokenHelper.stopTokenRefreshTimer()
        return DataHelper.getInstance().clearData(this._getStoredKeys())
    }

    getAccessToken = () => {
        return DataHelper.getInstance().getPrimaryAccessToken() as string
    }

    getRefreshToken = () => {
        return DataHelper.getInstance().getPrimaryRefreshToken() as string
    }

    _parseAccessToken = (
        response: {
            access_token?: string
            refresh_token?: string
            expires_in?: number
            id_token?: string
        },
        cleanAllStorageData = false
    ) => {
        const { access_token, refresh_token, expires_in, id_token /*, token_type, scope*/ } =
            response

        if (!access_token || !refresh_token || !expires_in) {
            const errorMsg =
                'Access Token, Refresh Token or expiration is missing from the backend, cannot validate Authentication !!!'

            this.logger.error(OAuthDeviceFlow.TAG, errorMsg)
            return throwError(
                new VoltError(VoltError.codes.INVALID_AUTH_TOKEN, {
                    extraLog: errorMsg,
                    backend: this.backendName,
                })
            )
        }

        this.oauthStatus = this.oauthStatus.update({
            status: OAuthDeviceFlowStatus.OAUTH_STATUS.LOGIN_SECONDARY_DEVICE,
        }) as OAuthDeviceFlowStatus

        return (
            (cleanAllStorageData
                ? DataHelper.getInstance().clearData(this._getStoredKeys())
                : of(true)) as Observable<void | boolean>
        ).pipe(
            mergeMap(() =>
                DataHelper.getInstance().storeData([
                    [DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN, access_token],
                    [
                        DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_EXPIRATION_TIME_MS,
                        (expires_in * 1000 + Date.now()).toString(),
                    ],
                    [
                        DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_ISSUED_TIME_MS,
                        Date.now().toString(),
                    ],
                    [DataHelper.STORE_KEY.PRIMARY_REFRESH_TOKEN, refresh_token],
                    [DataHelper.STORE_KEY.PRIMARY_ID_TOKEN, id_token!],
                ])
            )
        )
    }

    /**
     * Checks the need of refresh the token at the boot
     * @returns {boolean}
     */
    _needToRefreshAccessTokenOnBoot = () => {
        if (
            this.oauthConfig &&
            this.oauthConfig.param &&
            this.oauthConfig.param.refreshTokenOnBootTimeoutMs
        ) {
            const oauthAccessTokenIssuedTimeMsecs = DataHelper.getInstance().getData(
                DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_ISSUED_TIME_MS
            ) as string

            return (
                this._tokenHelper.hasToken() &&
                ((!!oauthAccessTokenIssuedTimeMsecs &&
                    parseInt(oauthAccessTokenIssuedTimeMsecs) +
                        this.oauthConfig.param.refreshTokenOnBootTimeoutMs <
                        Date.now()) ||
                    // For boards on the field which does not integrate the feature, for a refresh
                    !oauthAccessTokenIssuedTimeMsecs)
            )
        }
        return false
    }

    _getStoredKeys() {
        return [
            DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN,
            DataHelper.STORE_KEY.PRIMARY_REFRESH_TOKEN,
            DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_ISSUED_TIME_MS,
            DataHelper.STORE_KEY.PRIMARY_ACCESS_TOKEN_EXPIRATION_TIME_MS,
            DataHelper.STORE_KEY.PRIMARY_ID_TOKEN,
        ]
    }

    /**
     * Start timer for the refresh of the Access Token asynchronously
     * @param {Number} [iNextRefreshTokenTimeMsecs] Value can be specified otherwise computed from cache
     */
    _startAccessTokenRefreshTimer(iNextRefreshTimeMsecs?: number) {
        this._tokenHelper.startTokenRefreshTimer(iNextRefreshTimeMsecs)
    }
}
