import config from './production.js'

const conf = {
    ...config,
    carePortal: {
        urls: {
            adminAuthUrl: 'https://attus02.ulm.stg.m1amdocs.io',
        },
        agentClientId: 'HaloCTestRP',
        ssoClientId: 'haloeFederationClientID',
        ssoClientSecret: 'secret0.5477682322858153',
        OAuthParam: {
            scope: 'openid profile',
            extras: {},
        },
    },
    urls: {
        ...config.urls,
        apiUrl: 'https://attustest03-ulm-pdt.m1amdocs.io/platform/rest/v85',
        authUrl: 'https://attustest03-ulm-pdt.m1amdocs.io',
        userPreferencesUrl: 'https://attustest03-ulm-pdt.m1amdocs.io/customData',
        storefrontUrl: 'https://attustest03-ulm-pdt.m1amdocs.io/storefront',
        emi: {
            baseUrl: 'https://waf-attus-emi-wa-prebk003.ifs.vubiquity.com/curation-api',
            homePath: '3.0/{population}/1',
            defaultPopulation: 'pdt',
        },
        proxyUrl: 'https://waf-attus-metadata-api-prebk003.ifs.vubiquity.com/maculosa/v3.1',
        ifsApiUrl: ' https://plm-prodtest.m1amdocs.io/el/pdt3',
        iframePaymentUrl: 'https://test6.att.com/acctmgmt/paymentmgmt/paymentmethods',
    },
    clientId: '689670c03c664e04974e98f765cd32d3',
    encryption: {
        enabled: false,
    },
    env: 'preprod3',
    OAuthParam: {
        ...config.OAuthParam,
        extras: {
            ...config.OAuthParam.extras,
        },
    },
}

export default conf
