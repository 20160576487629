import { AUTH_TYPE } from 'enums'

export default class AuthType {
    /**
     * Key used to store and manage data
     */
    static TYPE = AUTH_TYPE

    static BearerToken(token: string) {
        if (!token) return ''
        return `Bearer ${token}`
    }

    static BasicAuth(username: string, password: string) {
        return `Basic ${btoa(`${username}:${password}`)}`
    }

    static NoAuth() {
        return ''
    }

    /**
     * Use to retrieve the instance of this class
     * @returns instance
     */
    static get(
        {
            token,
            username,
            password,
            apiKey,
        }: { token: string; username: string; password: string; apiKey: string },
        authType = AUTH_TYPE.BEARER
    ) {
        switch (authType) {
            case AUTH_TYPE.NO_AUTH:
                return AuthType.NoAuth()
            case AUTH_TYPE.BEARER:
                return AuthType.BearerToken(token)
            case AUTH_TYPE.BASIC_AUTH:
                return AuthType.BasicAuth(username, password)
            case AUTH_TYPE.API_KEY:
                return apiKey || token
            default:
                return token
        }
    }
}
