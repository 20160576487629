const Platforms = require('config/platforms').default
const Models = require('models')
const MarketOneApi = require('services/marketone').default

const Constants = require('api-constants').default
const VoltError = require('VoltError').default
const AbstractApi = require('../Api').default

// ----- REAL APIs -----
const ProxySearchApi = require('services/proxy/SearchApi').default
// ----- STUBs -----
const ConfigurationApi = require('services/marketone/ConfigurationApi').default
const PinApi = require('services/stub/PinApi').default
class VoltApi extends AbstractApi {
    constructor(config) {
        super(config)
        this.searchApi = new ProxySearchApi(this.config, {
            commonApi: this.commonApi,
        })
        this.pinApi = new PinApi(this.config)

        this.platformApi = new MarketOneApi(this.config, {
            metaApi: this.metaApi,
            searchApi: this.searchApi,
            userPreferencesApi: this.userPreferencesApi,
            carePortalApi: this.carePortalApi,
            commonApi: this.commonApi,
            pinApi: this.pinApi,
            shoppingCartApi: this.shoppingCartApi,
        })
    }
}

module.exports = {
    PLATFORM_NAME: Platforms.ATT,
    VoltApi,
    ConfigurationApi,
    Models,
    Constants,
    VoltError,
    PIN_TYPE: PinApi.TYPE,
}
