import {For, JSXElement, Match, Show, Switch} from 'solid-js'
import {useIcon} from '../icon/useIcon'

export enum ToastType {
    PLAIN = 'toast-plain',
    ERROR = 'toast-error',
    WARNING = 'toast-warning',
    SUCCESS = 'toast-success',
    INFO = 'toast-info',
}

export enum ToastPosition {
    TOP_LEFT = 'top-left',
    TOP_RIGHT = 'top-right',
    BOTTOM_LEFT = 'bottom-left',
    BOTTOM_RIGHT = 'bottom-right',
    TOP_CENTER = 'top-center',
}

export interface ToastProps {
    title: string
    description: string
    subDescription?: string
    type: ToastType
    actions?: JSXElement[]
    index?: number
    onClose?: () => void
    className?: string
    id?: number
    life?: number
    position?: ToastPosition
    sticky?: boolean
    link?: string
}

const Toast = (props: ToastProps) => {
    const icons = useIcon()

    const calculateSpace = (index: number) => {
        return `${index * 80 + 15}px`
    }

    const CloseIcon = icons.get('Close')
    const SuccessIcon = icons.get('Success')
    const WarningIcon = icons.get('Warning')
    const InfoIcon = icons.get('Information')
    const ErrorIcon = icons.get('Error')

    const getStyle = (): string => {
        switch (props.position) {
            case ToastPosition.TOP_CENTER:
            case ToastPosition.TOP_RIGHT:
            case ToastPosition.TOP_LEFT: {
                return 'top'
            }
            case ToastPosition.BOTTOM_RIGHT:
            case ToastPosition.BOTTOM_LEFT: {
                return 'bottom'
            }
            default:
                return 'bottom'
        }
    }

    console.log("Prop", props)

    return (
        <div
            class={`toast-wrapper ${props.className} ${props.type} ${props.position || ToastPosition.BOTTOM_LEFT}`}
            style={{[getStyle()]: calculateSpace(props.index || 0)}}
        >
            <div class="toast-icon-details-wrapper">
                <div class="toast-icon-wrapper">
                    <Switch fallback={<SuccessIcon class="toast-icon" />}>
                        <Match when={props.type === ToastType.PLAIN && !/failed/i.test(props.description)}>
                            <InfoIcon class="toast-icon" />
                        </Match>
                        <Match when={props.type === ToastType.SUCCESS}>
                            <SuccessIcon class="toast-icon" />
                        </Match>
                        <Match when={props.type === ToastType.INFO && !/failed/i.test(props.description)}>
                            <InfoIcon class="toast-icon" />
                        </Match>
                        <Match when={props.type === ToastType.WARNING}>
                            <WarningIcon class="toast-icon" />
                        </Match>
                        <Match when={props.type === ToastType.ERROR || /failed/i.test(props.description)}>
                            <ErrorIcon class="toast-icon" />
                        </Match>
                    </Switch>
                </div>
                <div class="toast-details-wrapper">
                    <div class="toast-title">{props.title}</div>
                    <div class="toast-description">{props.description}</div>
                    <div class="toast-description">{props.subDescription ?? ''}</div>
                    <div class="toast-description">
                        <a target="blank" href={props?.link ?? ''}>
                            {props?.link ?? ''}
                        </a>
                    </div>
                </div>
            </div>

            <div class="toast-actions">
                <For each={props.actions}>
                    {action => {
                        return action
                    }}
                </For>
                <Show when={!props.actions}>
                    <div class="toast-close-icon-wrapper" id="btnCloseToast" onClick={() => props.onClose?.()}>
                        <CloseIcon class="webstv-icon" />
                    </div>
                </Show>
            </div>
        </div>
    )
}

export default Toast
