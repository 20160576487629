import Constants from 'api-constants'
import { forkJoin, throwError, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { map, timeoutWith, catchError } from 'rxjs/operators'
import { get } from 'lodash'
import { NavigationNode } from 'models'
import VoltError from 'VoltError'
import nodeIds from './config/nodeIds'

import VodModule from './vod'
import HomeModule from './home'
import UniverseModule from './universe'
import ThemeModule from './theme'
import AppdockModule from './appdock'
import VirtualChannelModule from './virtualChannel'
import ParameterModule from './parameter'
const { emiModules } = Constants

export default class EmiApi {
    config
    constructor(config, metaApi) {
        // Contains all modules for which config should be dynamicaly retrieved
        // (even from EMI or another datasource depending of emiModules enabled)
        this.config = config
        if (
            config?.emiModules &&
            Object.values(config.emiModules).length &&
            !Object.values(config.emiModules)[0].name
        ) {
            //deprecated way to handle universes, please use enhanced emiModules
            // TODO:UNIVERSE_REFACTOR: remove these lines
            this.modules = [
                new VodModule(config, metaApi),
                new HomeModule(config),
                config.emiModules.theme && new ThemeModule(config),
                // later: new OfferModule(config) etc...
            ].filter(Boolean)
        } else {
            this.buildModules({ config })
        }
    }
    /**
     * Fetch EMI Configuration (Vod, Home modules or specific one) and return it as an object of
     * Navigation Map and Requests Map.
     *
     * @param {Object} [args={}]
     * @param {String} [args.population] Targeted population, if missing `urls.emi.defaultPopulation` config is used
     * @param {String} [args.module] Module name we want the config, if missing all modules are retrieved
     * @return {EmiParsedConfig}
     */
    getConfig = ({ population, module: moduleName } = {}) => {
        if (!this.modules.length) {
            return of({ modules: {} })
        }
        // Assign default population if not provided
        population = population || get(this.config, 'urls.emi.defaultPopulation')

        // Filter module that are not targeted
        const filteredModules = this.modules.filter(
            (module) => !moduleName || moduleName === module.emiModule
        )

        if (!filteredModules.length) {
            return throwError(
                new VoltError(VoltError.codes.RUNTIME_ERROR, {
                    extraLog: `No module to fetch, provided module (${moduleName}) is probably unknown`,
                })
            )
        }

        return forkJoin(
            // Assuming that each module exposes a `getConfig()` method
            ...filteredModules.map((module) => module.getConfig(population))
        ).pipe(
            map((modulesConfig) => {
                // Index configuration by related module name
                const configByModule = modulesConfig.reduce((acc, moduleConfig, moduleIndex) => {
                    const module = filteredModules[moduleIndex]

                    acc[module.emiModule] = moduleConfig
                    return acc
                }, {})

                // Add a `root` config which is a simple NavigationNode (the root one)
                const root = new NavigationNode({
                    id: nodeIds.root,
                })

                return { modules: configByModule, root }
            })
        )
    }

    /**
     * @param {String} population
     * @returns {String} EMI base url from config and population
     */
    _getEmiUrl(population) {
        const { baseUrl, homePath } = this.config.urls.emi

        return [baseUrl, homePath.replace('{population}', population), 'module'].join('/')
    }

    /**
     * Fetch EMI dynamic modules and return them as an array of EmiModule.
     *
     * @return {Observable<EmiModule[]>}
     */
    getDynamicModules() {
        const population = get(this.config, 'urls.emi.defaultPopulation')
        return ajax({
            url: `${this._getEmiUrl(population)}/module.json`,
            async: true,
            crossDomain: true,
            method: 'GET',
            responseType: 'json',
        }).pipe(
            timeoutWith(
                this.config.timeout.emi || Constants.fallbackTimeout.emi,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            map((result) => {
                // add emiModules to config, this will introduce the dynamic modules also
                this.config['emiModules'] = result.response

                this.buildModules({ emiModules: result.response, config: this.config })
            }),
            catchError((err) => throwError('module')) // TODO: Shall we upgrade this error? (10/2021)
        )
    }

    buildModules({ modules, config }) {
        const _emiModules = modules ?? config?.emiModules ?? {}

        this.modules = Object.values(_emiModules || {})
            .map((element) => {
                if (element.isEMIUniverse) {
                    return new UniverseModule(
                        config,
                        element.name,
                        element.defaultConfig || {},
                        element.anchorNode,
                        element.slug,
                        element.position,
                        element.promptToLogin,
                        element.visibility,
                        element.isHomePage
                    )
                } else if (element.name === emiModules.THEME) {
                    return new ThemeModule(config, element.name, element.defaultConfig || {})
                } else if (element.name === emiModules.APPDOCK) {
                    return new AppdockModule(config, element.name, element.defaultConfig || {})
                } else if (element.name === emiModules.VIRTUALCHANNEL) {
                    return new VirtualChannelModule(
                        config,
                        element.name,
                        element.defaultConfig || {}
                    )
                } else if (element.name === emiModules.PARAMETER) {
                    return new ParameterModule(config, element.name, element.defaultConfig || {})
                }
                return null
            })
            .filter(Boolean)
    }
}
