import { throwError, of, from } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { map, catchError, timeoutWith, mergeMap, tap } from 'rxjs/operators'

import VoltError from 'VoltError'
import { NavigationNode } from 'models'

import { parseEmiConfig } from './factories'
import nodeIds from './config/nodeIds'
import config from './config'
import Constants from 'api-constants'

import MockLogger from 'MockLogger'

export default class Fetch {
    /**
     * @param {*} config Configuration file set for
     * @param {string} emiModule name of this current Emi module
     * @param {*} defaultConfig default config to be used, if there is no config defined in EMI repo for this module
     * @param {*} anchorNode name of the node where this EMI config should be plugged in.
     *                       If not set by default rootNode is used. If empty this mean this is a tree config
     * @param {*} slug name of the URIto be configured in router.
     * @param {*} position position order of the page in the menu.
     * @param {*} promptToLogin if true, the user will be prompted to log-in before accessing the page.
     * @param {*} visibility if false, the page will not be displayed in the menu.
     * @param {*} isHomePage if true, the page will be the home page.
     */
    constructor(
        config,
        emiModule,
        defaultConfig = {},
        anchorNode,
        slug = '',
        position = 0,
        promptToLogin = false,
        visibility = true,
        isHomePage = false
    ) {
        this.config = config
        this.emiModule = emiModule
        this.defaultConfig = defaultConfig
        this.anchorNode = anchorNode
        this.slug = slug
        this.position = position
        this.promptToLogin = promptToLogin
        this.visibility = visibility
        this.isHomePage = isHomePage

        this.logger = (config.logger || MockLogger).createChildInstance('emi')
    }

    /**
     * @throws Error when JSON parsing failed or reponse does not contains moduleData.CoachConfigs
     * @param {String} body response body
     * @returns {Object} Emi full configuration
     */
    static parseEmiResponse = (body) => {
        let response = body.response.replace(/^[^{]*/g, '')
        response = response.replace(/[^}]*$/g, '')
        response = JSON.parse(response)

        return response
    }

    /**
     * @param {String} population
     * @returns {String} EMI base url from config and population
     */
    _getEmiUrl(population) {
        const { baseUrl, homePath } = this.config.urls.emi

        return [baseUrl, homePath.replace('{population}', population), this.emiModule].join('/')
    }

    /**
     * @param {Number} revision
     * @param {String} population
     * @returns {AjaxObservable}
     */
    fetch(revision, population) {
        const { parseEmiResponse } = Fetch
        const url = `${this._getEmiUrl(population)}/${revision}.json`
        const method = 'GET'

        this.logger.debug(`[EMI][${population}][${revision}] HttpRequest ${url}`, { method })
        return ajax({
            url,
            async: true,
            crossDomain: true,
            method,
            responseType: 'text',
        }).pipe(
            timeoutWith(
                this.config.timeout.emi || Constants.fallbackTimeout.emi,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            map((response) => {
                this.logger.trace(
                    `[EMI][${population}][${revision}] HttpRequest response`,
                    response && response.response
                )
                return parseEmiResponse(response)
            }),
            catchError((error) => {
                this.logger.error(`[EMI][${population}][${revision}] HttpRequest Error`, {
                    url,
                    method,
                    error,
                })
                return throwError(
                    error instanceof VoltError
                        ? error
                        : new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                              inheritedError: error,
                          })
                )
            })
        )
    }

    /**
     * @param {String} population
     * @returns {AjaxObservable}
     */
    getRevision(population) {
        return ajax({
            url: `${this._getEmiUrl(population)}/revision.json`,
            async: true,
            crossDomain: true,
            method: 'GET',
            responseType: 'json',
        }).pipe(
            timeoutWith(
                this.config.timeout.emi || Constants.fallbackTimeout.emi,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            map((result) => result.response.revision),
            catchError((err) => throwError('revision')) // TODO: Shall we upgrade this error? (10/2021)
        )
    }

    /**
     * Curried function: Return a function which handle config parsing
     *
     * @param {Number} [ageLimit]
     * @returns {Function} Config parsing function which wait of a `config` parameter ({@link EmiFullConfig})
     */
    _makeConfigParser = (ageLimit) => (config) => {
        // Automatically inject a Root navigation node for this emiModule
        // By default his parent is the main "root" node (parent of all universes) - but if it has been overriding anchorNode should be used
        const rootNodesIds = this.config.emiModulesToUniverseRoot || nodeIds
        const hasAnchorNode = this.anchorNode !== undefined
        // if anchor node has been override with '', let's use undefined to be consistent with root node and tree root
        const anchorNode = this.anchorNode !== '' ? this.anchorNode : undefined
        config.moduleData.moduleRoot = new NavigationNode({
            id: rootNodesIds[this.emiModule] ?? 'root-' + this.emiModule, //ex: nodesIds.home = 'root-coach'
            labels: { default: '' },
            intlKey: { tag: rootNodesIds[this.emiModule] },
            parent: hasAnchorNode ? anchorNode : rootNodesIds.root ?? 'root',
        })
        return parseEmiConfig(config, ageLimit, this.config)
    }

    /**
     * @param {String} population Targeted population
     * @returns {AjaxObservable}
     */
    getEmiConfig(population) {
        let isNewRevision = true
        return this.getRevision(population).pipe(
            mergeMap((revision) => {
                return from(
                    this.config.persistentStorage.getItem(`${this.emiModule}_${population}`)
                ).pipe(
                    mergeMap((x) => {
                        if (x && x !== null) {
                            const parsedConfig = JSON.parse(x)
                            isNewRevision = !(
                                parsedConfig != null &&
                                parsedConfig.revision &&
                                parsedConfig.revision.toString() === revision.toString()
                            )
                            if (!isNewRevision) {
                                this.logger.info(
                                    'emi config revision',
                                    {
                                        revision,
                                        module: this.emiModule,
                                    },
                                    'already known'
                                )
                                return of(parsedConfig)
                            }
                        }
                        this.logger.info('fetching emi config revision', {
                            revision,
                            module: this.emiModule,
                        })
                        return this.fetch(revision, population)
                    })
                )
            }),
            mergeMap((rawConfig) => {
                this.logger.info('retrieved emi raw config')
                this.logger.debug('raw config', rawConfig)
                // Persist the raw configuration in storage
                return of(rawConfig).pipe(
                    map(this._makeConfigParser()),
                    // Add a flag in the result to indicate it is a new revision downloaded
                    map((cfg) => ({
                        ...cfg,
                        isNewRevision,
                        slug: this.slug,
                        position: this.position,
                        promptToLogin: this.promptToLogin,
                        visibility: this.visibility,
                        isHomePage: this.isHomePage,
                    })),
                    tap(
                        () =>
                            from(
                                this.config.persistentStorage.setItem(
                                    `${this.emiModule}_${population}`,
                                    JSON.stringify(rawConfig)
                                )
                            ).pipe(
                                catchError(() => {
                                    this.logger.error('failed to add config in local storage')
                                    return of([])
                                })
                            ) // let it fail silently: the config is still valid
                    )
                )
            }),
            catchError((error) => {
                // Unable to retrieve EMI config, fallback to eventual stored raw config
                this.logger.error(
                    'Unable to retrieve EMI config, fallback to eventual stored raw config',
                    { error, module: this.emiModule }
                )
                return from(
                    this.config.persistentStorage
                        .getItem(`${this.emiModule}_${population}`)
                        .then((x) => JSON.parse(x))
                ).pipe(
                    map(this._makeConfigParser(config.programmingAgeLimit)),
                    catchError(() => {
                        this.logger.error('no config in local storage, returning default config', {
                            module: this.emiModule,
                        })
                        if (
                            this.defaultConfig.navMap || //old way to define defaultConfig
                            Object.keys(this.defaultConfig).length === 0 //defaultConfig has not been defined
                        ) {
                            // Fails to fallback to stored config, use the default one and emulate a
                            // startTime to "now"
                            return of({
                                programmings: { [Date.now()]: this.defaultConfig },
                                revision: 0,
                                polling: this.config.defaultEmiPollingConfig,
                                slug: this.slug,
                                position: this.position,
                                promptToLogin: this.promptToLogin,
                                visibility: this.visibility,
                                isHomePage: this.isHomePage,
                            })
                        } else {
                            return of(this.defaultConfig).pipe(map(this._makeConfigParser()))
                        }
                    })
                )
            })
        )
    }
}
