import { IPaymentMethod } from '@typings/tile'
import { PAYMENT_METHOD_TYPE } from 'enums'
import { Abstract } from './Abstract'

/**
 * @class PaymentMethod
 * @property {Boolean} active indicates if payment method is active
 * @property {String} paymentType refers to payment method type {@Link PaymentMethod.PAYMENT_METHOD_TYPE}
 * @property {String} id refers to payment method ID
 * @property {String} externalId refers to external ID
 * @property {String} name Payment Method Name
 * @property {Boolean} defaultPaymentMethod indicates if payment method is set as default
 * @property {String} currency refers to currency used by the payment method
 * @property {String} paymentPlatform Payment Platform
 * @property {String} internalProviderAccountId
 */

export class PaymentMethod extends Abstract {
    id: string
    active: boolean
    name: string
    externalId: string
    defaultPaymentMethod: boolean
    currency: string
    paymentPlatform: string
    paymentType: `${PAYMENT_METHOD_TYPE}`
    cardNumber: string
    cardExpirationMonth: string
    cardExpirationYear: string
    internalProviderAccountId: string

    constructor(props: IPaymentMethod) {
        super()

        const computePropValue = this._makeComputePropValue(props)

        this.id = props.id
        this.active = computePropValue('active', true) // If not provided, we should consider it as active
        this.name = props.name
        this.externalId = props.externalId
        this.defaultPaymentMethod = props.defaultPaymentMethod
        this.currency = props.currency
        this.paymentPlatform = props.paymentPlatform

        this.paymentType = computePropValue(
            'paymentType',
            PaymentMethod.PAYMENT_METHOD_TYPE.PAY_ON_BILL
        )

        /**
         * Credit card related information
         */
        this.cardNumber = props.cardNumber
        this.cardExpirationMonth = props.cardExpirationMonth
        this.cardExpirationYear = props.cardExpirationYear
        this.internalProviderAccountId = props.internalProviderAccountId
    }

    static PAYMENT_METHOD_TYPE = {
        PAY_ON_BILL: 'PAY_ON_BILL',
        CARRIER_BILLING: 'CARRIER_BILLING',
        CREDIT_CARD: 'CREDIT_CARD',
        APPSTORE_IAP: 'APPSTORE_IAP',
        CREDIT_CARD_USING_SECONDARY_DEVICE: 'CREDIT_CARD_USING_SECONDARY_DEVICE',
        PAYPAL: 'PAYPAL',
        UNKNOWN: 'UNKNOWN',
    }
}
