import VoltError from 'VoltError'
import { AjaxRequest, ajax } from 'rxjs/ajax'
import { map, catchError, timeoutWith } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'
import { has } from 'lodash'
import MockLogger from 'MockLogger'
import Constants from 'api-constants'
import {
    Logger,
    Config,
    IVoltError,
    ErrorMappingTable,
    IHeaders,
    FetchArgs,
    FetchResponse,
    ErrorResponse,
} from '@typings/generic'

/**
 * Generic Fetching API class.
 */
export default class GenericFetch {
    /**
     * Default HTTP Settings
     */
    public static readonly defaultHeaders = {
        'Content-Type': Constants.httpHeader.ContentType.APPLICATION_JSON,
    }

    static readonly defaultMethod = 'GET'

    /**
     * Type of response return by fetch method
     */
    public static readonly responseType = {
        json: 'json', // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON
        text: 'text', // The response is always decoded using UTF-8.
        arrayBuffer: 'arrayBuffer', // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/ArrayBuffer
        blob: 'blob', // https://developer.mozilla.org/en-US/docs/Web/API/Blob
        formData: 'formData', // https://developer.mozilla.org/en-US/docs/Web/API/FormData
    }
    config: Config
    logger: Logger
    backendName: string
    errorMappingTable: ErrorMappingTable

    constructor(
        config: Config,
        backendName = 'genericfetch',
        errorMappingTable: ErrorMappingTable = {}
    ) {
        this.config = config
        this.backendName = backendName
        this.logger = (config.logger || MockLogger).createChildInstance(
            (backendName && backendName.toLowerCase()) || 'genericfetch'
        )
        this.errorMappingTable = this.registerErrors(errorMappingTable)
    }

    /**
     * register errors to be associated
     * @param {Object} errorMappingTable Object of key pair values.
     * Example:
     * {
     *      unauthorized: VoltError.codes.HTTP_401_UNAUTHORIZED,
     *      'missing parameters': VoltError.codes.INVALID_API_ARGUMENT,
     *      'error contacting esps': VoltError.codes.EXTERNAL_BSS_CANNOT_BE_REACHED,
     *      'locked hub id account': VoltError.codes.USER_ACCOUNT_LOCKED,
     * }
     */
    registerErrors(errorMappingTable: ErrorMappingTable = {}): ErrorMappingTable {
        // Lower case all keys to ease the matching
        return Object.keys(errorMappingTable).reduce((acc: ErrorMappingTable, value) => {
            const func = errorMappingTable[value]
            if (typeof func === 'function') {
                acc[value.toLowerCase()] = func
            } else {
                throw new Error(`${func} should be a function`)
            }
            return acc
        }, this.errorMappingTable || {})
    }

    /**
     * Fetching Method call with ajax API. Output of the function is then automatically parsed
     *
     * @param {String} url
     * @param {Object} body : HTTP URL
     * @param {Object} headers : HTTP Header
     * @param {String} method : GET, PUT, POST, DELETE, HEAD, OPTIONS
     * @param {Number} timeout
     * @param {String} [log=''] Extra message for Debug
     * @param {Boolean} [retrieveResponseHeaders=false] False by default to same CPU as not always needed
     * @param {Boolean} [withCredentials=undefined] Whether or not a CORS request was sent with credentials. If false, will also ignore cookies in the CORS response.
     * @param {VoltError} [defaultError=undefined] Default error to return when UNKNOWN_API_ERROR is thrown
     * @returns { response, totalCount }
     */
    fetch<T>({
        url,
        body,
        headers = GenericFetch.defaultHeaders,
        method = GenericFetch.defaultMethod,
        responseType = GenericFetch.responseType.json,
        timeout,
        log = '',
        retrieveResponseHeaders = false,
        withCredentials,
        defaultError,
        crossDomain,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FetchArgs): Observable<FetchResponse<T>> {
        this.logger.debug(`[${log}] HttpRequest [REQUEST]: ${url}`, { method, headers, body })

        let props: AjaxRequest = {
            url: this._parseUrl(url),
            body,
            headers,
            method,
            responseType,
        }
        if (withCredentials) {
            props = {
                ...props,
                withCredentials: true,
            }
        }
        if (crossDomain) {
            props = {
                ...props,
                crossDomain: true,
            }
        }

        const filteredUrl = this._filterUrlParams(url)
        return ajax(props).pipe(
            timeoutWith(
                timeout || this.config?.defaultTimeoutBackend || Constants.fallbackTimeout.default,
                throwError(
                    new VoltError(VoltError.codes.REQUEST_TIMEOUT, {
                        extraLog: `platform error: Timeout detected for [${log}] url: ${filteredUrl}`,
                        backend: this.backendName,
                        httpUrl: filteredUrl,
                        backendErrorMessage: `platform error: Timeout detected for [${log}] url: ${filteredUrl}`,
                    })
                )
            ),
            map(({ response, xhr }) => {
                this.logger.trace(`[${log}] HttpRequest [RESPONSE]`, response)
                let result
                try {
                    if (responseType === GenericFetch.responseType.text) {
                        // Heuristic nor always true because sometime could be XML as text. Better to keep it here for now
                        result = JSON.parse(response)
                    } else {
                        result = response
                    }
                } catch {
                    result = response
                }

                return {
                    response: result,
                    headers: retrieveResponseHeaders ? this._parseAjaxResponseHeaders(xhr) : {},
                    method,
                    url,
                    log,
                    xhr,
                }
            }),
            catchError((errorObject) => {
                if (errorObject instanceof VoltError) return throwError(errorObject)

                const httpError = errorObject.status
                const error = this._parseError(errorObject.response || { error: errorObject.code })
                if (!!defaultError && error.code === VoltError.codes.UNKNOWN_API_ERROR.code) {
                    error.code = defaultError.code
                }
                this.logger.error(`[${log}] HttpRequest [ERROR ${httpError}]:`, {
                    url: this._filterUrlParams(url),
                    method,
                    error,
                    errorResponse: errorObject.response,
                })
                if (httpError) error.setHttpErrorStatus(httpError)
                if (filteredUrl) error.setHttpUrl(filteredUrl)
                errorObject.xhr &&
                    error.setHttpResponseHeaders(this._parseAjaxResponseHeaders(errorObject.xhr))
                return throwError(error)
            })
        )
    }
    /**
     * Parsing Error
     * @param {Object} response
     * @returns {VoltError}
     */
    _parseError(response: ErrorResponse): VoltError {
        const { error, description = '', errorCode = '' } = this.extractError(response) || {}

        if (!error)
            return new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                extraLog: `platform error: null`,
                backend: this.backendName,
            })

        const _error = typeof error === 'string' ? error.toLowerCase() : error.toString()
        const errorMapping = has(this.errorMappingTable, _error)
            ? this.errorMappingTable[_error](errorCode, description)
            : VoltError.codes.UNKNOWN_API_ERROR
        const voltErroCode = Array.isArray(errorMapping) ? errorMapping?.[0] : errorMapping
        const errorVariant = Array.isArray(errorMapping) ? errorMapping?.[1] : undefined

        const platformErrorMessage = `Backend error: [${error}] ${description} | ${errorCode}`
        return new VoltError(voltErroCode, {
            extraLog: platformErrorMessage,
            errorVariant,
            backendErrorCode: error || errorCode,
            backendErrorMessage: platformErrorMessage,
            backend: this.backendName,
        })
    }

    /**
     * Parses several form of error messaging.
     * This method needs to be redefined by the children class
     * @param {Object} response
     * @returns {Object} '{ error: 'CODE', description: 'DESCRIPTION' }'
     */
    extractError(response: ErrorResponse): IVoltError {
        if (response.error) {
            return {
                error: response.error.toString(),
                errorCode: response.errorCode,
                description: response.error_description,
            }
        }

        if (response.responseType === GenericFetch.responseType.text) {
            return {
                error: response.response,
                errorCode: response.status,
                description: response.response,
            }
        }

        return { error: 'unknown', description: 'unknown', errorCode: 'unknown' }
    }

    _parseUrl(url?: string) {
        // IMPORTANT : Backend fall in error if we provide a double slash
        return url && url.replace(/([^:]\/)\/+/g, '$1')
    }

    _filterUrlParams(url?: string) {
        // IMPORTANT : To filter for GDPR
        return url && url.split('?')[0]
    }

    /**
     * Parse raw response headers text
     * @param {XMLHttpRequest} xhr XmlHttpRequest
     * @returns {IHeaders}
     */
    _parseAjaxResponseHeaders(xhr: XMLHttpRequest): IHeaders {
        const headers: IHeaders = {}
        try {
            xhr &&
                xhr.getAllResponseHeaders &&
                xhr
                    .getAllResponseHeaders()
                    .trim()
                    .split(/[\r\n]+/)
                    .map((value) => value.split(/: /))
                    .forEach((keyValue) => {
                        headers[keyValue[0].trim()] = keyValue[1].trim()
                    })
        } catch (e) {
            return {}
        }
        return headers
    }

    /**
     * Parse raw response headers text
     * @param {Object} data data
     * @returns {IHeaders}
     */
    _parseFetchResponseHeaders(data: { headers: string[] }): IHeaders {
        const headers: IHeaders = {}
        try {
            if (data && data.headers) {
                data.headers.forEach((value, key) => {
                    headers[key] = value
                })
            }
        } catch (e) {
            return {}
        }
        return headers
    }
}
