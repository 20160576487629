import { of } from 'rxjs'
import Constants from 'api-constants'

/**
 * Deals with Playback requirements (session creation, tracking etc)
 */
export default class PlayerApi {
    /**
     * Gets the manifest url for playing a Dvr stream
     *
     * @param {Object} args
     * @param {String|Number} args.recordingId Dvr ID
     * @returns {Observable<SessionData>}
     */
    getDvrStreamUrl({ recordingId: id }) {
        return of({})
    }

    /**
     * Get the url a associated Live manifest
     *
     * @throws {Error} if not mediaFile matches
     *
     * @param {Object} args
     * @param {String} args.platformChannelId
     * @param {String} args.playbackUrl
     * @param {String} args.drmProvider
     * @return {Observable<SessionData>}
     */
    getLiveStreamUrl({ platformChannelId, playbackUrl, drmProvider } = {}) {
        return of({})
    }

    /**
     * Get the url associated Vod manifest
     * @param {Object} args
     * @param {String} args.playbackContentId can be the id of the trailer or that of the main playback
     * @param {String} args.titleId Id of the title/offer - only used for vubiquity
     * @param {Object} args.screenDimensions Screen width and height
     * @param {Boolean} args.isTrailer
     * @param {Array} args.subscriptionIds subscription ids from Program
     * @param {Object} args.streams
     * @param {String} [args.gpsPosition] For mobile devices, latitude and longitude concatenated (e.g.: '51.507351;-0.127758')
     * @param {Number} [args.initialOffset] Value of the bookmark when the playback is launched
     * @return {Observable<SessionData>}
     */
    getVodStreamUrl({
        playbackContentId,
        titleId,
        screenDimensions,
        isTrailer,
        subscriptionIds,
        streams,
        gpsPosition,
        initialOffset,
    }) {
        return of({})
    }

    /**
     * Get manifest url of a RollingBuffer (catchup/replay)
     *
     * @throws {Error} if not mediaFile matches
     *
     * @param {Object} options
     * @param {Object} options.channelId Platform Channel identifier
     * @param {Object} options.programId Platform Program identifier
     */
    getRollingBufferStreamUrl({ channelId, programId }) {
        return of({})
    }

    /**
     * Get manifest url for a StartOver
     *
     * @param {Object} options
     * @param {Object} options.channelId Platform Channel identifier
     * @param {Object} options.programId Platform Program identifier
     */
    getStartOverStreamUrl({ channelId, programId }) {
        return of({})
    }

    notifyPauseSessionEvent({ sessionId, timeOffset }) {
        return of(null)
    }

    /**
     * @param {Object} options
     * @param {String} options.sessionId
     * @param {Number} options.timeOffset
     * @param {Number} options.previousTimeOffset
     * @return {Observable} Emit and complete once the seek session request ends
     */
    notifySeekSessionEvent({ sessionId, timeOffset, previousTimeOffset }) {
        return of(null)
    }

    /**
     * @param {Object} options
     * @param {String} options.sessionId
     * @param {Number} options.timeOffset
     * @param {String} options.reason A constant from Constants.session.endReasons.*
     * @return {Observable} Emit and complete once the end session request ends
     */
    notifyEndSessionEvent({
        sessionId,
        timeOffset,
        reason = Constants.session.endReasons.unknown,
    }) {
        return of(null)
    }

    notifyStartSessionEvent({ sessionId, timeOffset }) {
        return of(null)
    }

    keepAlive({ sessionId, timeOffset }) {
        return of(null)
    }
}
