import { of } from 'rxjs'
import { map } from 'rxjs/operators'
import UserPreferencesApi from '../UserPreferencesApi'
import Fetch from '../fetch'

export default class ChannelApi extends Fetch {
    static TAG = 'ChannelApi'

    /**
     * Get all channels data from Platform (auto-paginated)
     *
     * @param {Object} [opts]
     * @param {Number} [opts.pageSize=100] nb of items to fetch by page
     * @param {string} [opts.market] market of the user, specific to WOW
     * @param {string} [opts.subMarket] subMarket of the user, specific to WOW
     * @return {Observable<Array<ApiChannelData>>}
     */
    getChannelsData({ pageSize = 100, market, subMarket } = {}) {
        return of([])
    }

    /**
     * Get channels data by merging Proxy & Platform data that could be retrieved.
     *
     * Note: Are kept only channels which are known from the Platform, otherwise they are filtered out
     *
     * Return an Ajax Observable which emit identical returned structure from {@link ProxyApi#getChannels}
     *
     * @param {Object} [opts]
     * @param {Number} [opts.pageSize] pageSize used when fetch channels data from Kaltura platform
     * @param {string} [opts.market] market of the user, specific to WOW
     * @param {string} [opts.subMarket] subMarket of the user, specific to WOW
     * @return {Observable<ChannelsData>}
     */
    getChannels(opts) {
        // Use a fake configuration parameter to disable this feature for multiple reason
        //      1. We do not need it for XL Axiata and it could impact the performances
        //      2. There are still work to do at store level to manage properly free channels got from the proxy
        if (this.config.getChannelListFromProxy) {
            return this.metaApi.getChannels().pipe(
                map((proxyResult) => {
                    if (proxyResult && !!Object.keys(proxyResult).length) {
                        return Object.values(proxyResult).reduce((acc, channel) => {
                            acc[channel.id] = channel.update({
                                canWatch: true,
                                rollingBufferRetrieved: true,
                                rollingBufferPending: false,
                            })
                            return acc
                        }, proxyResult)
                    }

                    return {}
                })
            )
        }
        return of({})
    }

    /**
     * Get favorites channels of the current user
     *
     * @return {Observable<Array<String>>} Observable which emit an Array of Platform Channel identifiers
     */
    getFavoritesChannel() {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_CHANNELS
            )
        ) {
            return of([])
        }
        this.logger.info(ChannelApi.TAG, `Getting Favorites Channels...`)

        return this.userPreferencesApi
            .getUserPreferencesList(UserPreferencesApi.USER_PREFERENCES.FAVORITES_CHANNELS)
            .pipe(
                map((favorites) => (favorites || []).map((favorite) => favorite.id).filter(Boolean))
            )
    }

    /**
     * Set a channel as favorite for the current user
     *
     * @param {String|Number} platformChannelId
     * @return {Observable}
     */
    setFavoriteChannel(platformChannelId) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_CHANNELS
            )
        ) {
            return of({})
        }
        this.logger.info(ChannelApi.TAG, `Setting Favorites Channel ${platformChannelId}...`)

        return this.userPreferencesApi.updateUserPreferencesItemInList(
            UserPreferencesApi.USER_PREFERENCES.FAVORITES_CHANNELS,
            {
                id: platformChannelId,
            },
            `id eq "${platformChannelId}"`
        )
    }

    /**
     * Remove a channel as favorite for the current user
     *
     * @param {String|Number} channelId
     * @return {Observable}
     */
    removeFavoriteChannel(channelId) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.FAVORITES_CHANNELS
            )
        ) {
            return of({})
        }
        this.logger.info(ChannelApi.TAG, `Removing Favorites Channel ${channelId}...`)

        return this.userPreferencesApi.deleteUserPreferencesItemFromList(
            UserPreferencesApi.USER_PREFERENCES.FAVORITES_CHANNELS,
            `id eq "${channelId}"`
        )
    }

    /**
     * @param {String} profileId
     * @param {Date|String|Number} startTime lower date time boundary
     * @param {Date|String|Number} endTime upper date time boundary
     * @return {Observable<ApiRemindersList>}
     */
    getReminderList(profileId, startTime, endTime) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.REMINDERS
            )
        ) {
            return of({ reminders: [] })
        }

        this.logger.info(ChannelApi.TAG, `Getting all Reminders from ${startTime} to ${endTime}...`)

        let filter = ''
        if (startTime) {
            filter.concat(`start_time ge "${startTime}"`)
        }
        if (startTime && endTime) {
            filter.concat(' and ')
        }
        if (endTime) {
            filter.concat(`end_time le "${endTime}"`)
        }
        return this.userPreferencesApi
            .getUserPreferencesList(
                UserPreferencesApi.USER_PREFERENCES.REMINDERS,
                filter ? filter : undefined
            )
            .pipe(
                map((reminders) => (reminders || []).map((reminder) => reminder.id).filter(Boolean))
            )
    }

    /**
     * @param {String} profileId
     * @param {String} eventId
     * @param {String} [channelId] Channel ID
     * @param {Date|String|Number} startTime upper date time boundary
     * @param {Date|String|Number} endTime upper date time boundary
     * @return {Observable}
     */
    addReminder(profileId, eventId, channelId, startTime, endTime) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.REMINDERS
            )
        ) {
            return of(true)
        }

        this.logger.info(
            ChannelApi.TAG,
            `Adding Reminder ${eventId} (channel:${channelId} : startTime:${startTime} : endTime:${endTime}) ...`
        )

        return this.userPreferencesApi.updateUserPreferencesItemInList(
            UserPreferencesApi.USER_PREFERENCES.REMINDERS,
            {
                id: eventId,
                channel_id: channelId,
                start_time: startTime,
                end_time: endTime,
            },
            `id eq "${eventId}"`
        )
    }

    /**
     * @param {String} profileId
     * @param {String} eventId
     * @return {Observable}
     */
    removeReminder(profileId, eventId) {
        if (
            !this.userPreferencesApi.supportUserPreference(
                UserPreferencesApi.USER_PREFERENCES.REMINDERS
            )
        ) {
            return of(true)
        }

        this.logger.info(ChannelApi.TAG, `Removing Reminder ${eventId}...`)

        return this.userPreferencesApi.deleteUserPreferencesItemFromList(
            UserPreferencesApi.USER_PREFERENCES.REMINDERS,
            `id eq "${eventId}"`
        )
    }

    // -------------------------------------------------------------------- //
    // ----[STUBBED FOR MARKET ONE - API NOT MANAGED BY THIS BACKEND]------ //
    // -------------------------------------------------------------------- //
    getRollingBuffer() {
        return of({})
    }

    getOnTvNow() {
        return of({})
    }
    // -------------------------------------------------------------------- //
    // ---------------------------END STUB -------------------------------- //
    // -------------------------------------------------------------------- //

    /**
     * Gets restricted channel IDs
     *
     * @returns {Observable<Array<String>>} returns an array of restricted channel IDs
     */
    getChannelsRestriction() {
        return of([])
    }

    /**
     * Sets channel as restricted
     *
     * @param {Array<String>} channelIds - channel IDs
     * @param {Boolean} isLock - define if channel should be restricted or not
     * @returns {Boolean} returns true if setting is successful
     */
    setChannelRestriction(channelIds, isLock) {
        return of(true)
    }
}
