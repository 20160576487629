import { IBackendDevice } from '@typings/platform'
import { Abstract } from './Abstract'

/**
 * Backend device
 * @property {String} name Name of the device
 * @property {String} id Unique ID for the device
 * @property {String} publicId Public Device ID (Can be used for specific operations)
 * @property {String} _etag etag of the device
 * @property {String} deviceUUID
 * @property {String} active status of the device
 * @property {Number} lastLoginTime Last Login time (Timestamp in ms)
 * @property {BackendDevice.TYPE} deviceType type of the device
 * @property {Boolean} deletable The device can be deleted
 * @property {Boolean} displayable The device can be displayed within the device list
 * @property {Boolean} isCurrentDevice The device is the one currently used
 */
export class BackendDevice extends Abstract {
    id: string
    etag: string | undefined
    name: string
    deviceUUID: string
    publicId: string
    active: boolean
    deviceType: string
    lastLoginTime: number
    deviceApplicationId: string
    deletable: boolean
    displayable: boolean
    isCurrentDevice: boolean

    constructor(props: IBackendDevice) {
        super()
        this.id = props.id
        this.etag = props?._etag ? props._etag.replace('\\', '') : undefined // To be able use etag as a back-end parameter, we have to format it
        this.name = props.name
        this.deviceUUID = props.deviceUUID
        this.publicId = props.publicId
        this.active = props.active
        this.deviceType = props.deviceType
        this.lastLoginTime = props.lastLoginTime
        this.deviceApplicationId = props.DeviceApplicationId

        const computePropValue = this._makeComputePropValue(props)
        /** @type {Boolean} */
        this.deletable = computePropValue('deletable', true)
        /** @type {Boolean} */
        this.displayable = computePropValue('displayable', true)
        this.isCurrentDevice = computePropValue('isCurrentDevice', false)
    }

    static TYPE = {
        STB_MANAGED: 'STB_MANAGED',
        OPEN_DEVICE: 'OPEN_DEVICE',
    }
}

/**
 * @typedef {Object} BackendDeviceProps
 *
 */
