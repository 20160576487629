export function setCookie(cname: string, cvalue: Object, exdays: number) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + d.toUTCString()
    document.cookie = cname + '=' + JSON.stringify(cvalue) + ';' + expires + ';path=/'
}

export function getCookie<T>(cname: string, parseJSON: boolean): string | T | undefined {
    let name = cname + '='
    let ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
            const _c = c.substring(name.length, c.length)
            return parseJSON ? JSON.parse(_c) : _c
        }
    }
    return undefined
}
