/**
 * Quick helper
 */
export class M1ApiVersion {
    constructor(config = {}) {
        this.apiVersion = M1ApiVersion.convertApiVersion(
            config.marketone && config.marketone.apiVersion
        )
    }

    static API_VERSION = {
        MARKETONE_V1: 'V1',
        MARKETONE_V2: 'V2', /// Same as V1 but minor changes regarding the purchase URL and Body for subscribe(), unsubscribe(), partnerSubscription()
    }

    static getApiVersion = (config = {}) => {
        return M1ApiVersion.convertApiVersion(config.marketone && config.marketone.apiVersion)
    }

    static convertApiVersion = (apiVersionStr) => {
        switch (apiVersionStr) {
            case 'V2':
                return M1ApiVersion.API_VERSION.MARKETONE_V2
            default:
            case 'V1':
                return M1ApiVersion.API_VERSION.MARKETONE_V1
        }
    }
}
