import { of, empty, throwError } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import {
    map,
    expand,
    concatMap,
    mergeMap,
    switchMap,
    toArray,
    catchError,
    timeoutWith,
    tap,
} from 'rxjs/operators'
import { RequestFilter } from 'models'
import DataHelper from 'framework/helpers/data'
import { canAccessProtectedHeaders } from 'helpers/device.js'
import { serialize } from 'helpers/index.js'
import { hasMinimumVersion } from './helpers/index.js'
import VoltError from 'VoltError'
import Constants from 'api-constants'
import MockLogger from 'MockLogger'

export default class Fetch {
    serverLocalTimestamp = 0
    serverTimestampOffset = 0

    constructor(config, { commonApi } = {}) {
        this.config = config
        this.locale = Fetch.DEFAULT_PARAMS.locale
        this.logger = (config.logger || MockLogger).createChildInstance('proxy')
        this.commonApi = commonApi
        this.canParseProtectedHeader = canAccessProtectedHeaders()
    }

    static HTTP_SETTINGS = {
        SUCCESS_CODE: 200,
        NO_CONTENT: 204,
        RESPONSE_TYPE: 'json',
    }

    static DEFAULT_PARAMS = {
        device: '62',
        locale: 'en_US',
    }

    static PARAMS_TO_FILTER = {
        /**
         * Version of the proxy for paramter filtering
         */
        proxyV1: [
            {
                name: 'eligibility',
                minimumVersion: {
                    major: 1,
                    minor: 8,
                    patch: 18,
                },
            },
            {
                name: 'episode_expired_out',
                minimumVersion: {
                    major: 1,
                    minor: 8,
                    patch: 21,
                },
            },
        ],
        /**
         * Version 3 of the proxy for paramter filtering
         */
        proxyV3: [],
    }

    static COMMON_DEVICE_ID = 2
    /**
     * Retrieve version of current proxy
     */
    getVersion = () => {
        return this.config.proxyVersion
    }

    getProxyRevision = () => {
        return this.config.proxyRevision
    }

    getProxyConfig() {
        const { proxy = {} } = this.config || {}
        return proxy
    }

    /**
     * Return the list of categories per proxy version
     * @param {String} category
     */
    getCategoryKey(category) {
        if (!category || typeof category !== 'string') return undefined
        const categories = category.split(',')
        if (categories.length > 1) {
            return this.getVersion() >= 3 ? 'in_categories' : 'in_category'
        }

        return this.getVersion() >= 3 ? 'like_categories' : 'category'
    }

    parseMaculosaResponse = (body = {}) => {
        const { HTTP_SETTINGS } = Fetch
        const status = parseInt(body.status, 10)
        switch (status) {
            case HTTP_SETTINGS.SUCCESS_CODE:
                return body.response
            case HTTP_SETTINGS.NO_CONTENT:
                return {}
            default:
                throw new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                    extraLog: body.status,
                })
        }
    }

    buildUrl = (endpoint, params = {}) => {
        const { DEFAULT_PARAMS } = Fetch
        const {
            proxy: {
                device = DEFAULT_PARAMS.device,
                dataset: datasetFromConfig,
                useCommonDeviceForEpgSchedules = true,
            } = {},
            enableMultiLocalesMetadatas = false,
            multiLocalesExceptions = ['/search'],
        } = this.config
        // When dataset is provided by the upper layers then override dataset from configuration
        const dataset = params.dataset || datasetFromConfig

        // locale management should be enable only is explicitly defined by the configuration
        // as it requires proxy update to support 'locale_default' introduced recently
        /*
        TODO: remove multiLocalesExceptions
        once https://git.ifeelsmart.net/projects/BKD/repos/maculosa-api/commits/5deee31a0752451464f7d3f3426fb0a16b1973e2
        is deployed in all clients production backends
        */
        const locale =
            enableMultiLocalesMetadatas && !multiLocalesExceptions.includes(endpoint)
                ? (this.commonApi && this.commonApi.getLocale()) || DEFAULT_PARAMS.locale
                : DEFAULT_PARAMS.locale

        const locale_default = enableMultiLocalesMetadatas ? DEFAULT_PARAMS.locale : undefined
        const isEpgScheduleEndpoint = endpoint === '/epg/schedules'
        const _device =
            isEpgScheduleEndpoint && useCommonDeviceForEpgSchedules
                ? Fetch.COMMON_DEVICE_ID
                : device
        return (
            this.config.urls.proxyUrl +
            endpoint +
            serialize({
                locale,
                locale_default,
                device: _device,
                ...params,
                dataset,
            })
        )
    }

    /**
     * This method revolves proxy version before querying the proxy
     * Thus it helps to adjust the query parameter before API call
     * @returns {Observable{Object}}
     */
    _resolveVersion() {
        if (this.config.proxyVersion && this.config.proxyRevision) {
            return of({
                proxyVersion: this.config.proxyVersion,
                proxyRevision: this.config.proxyRevision,
            })
        }
        return this._fetch({ url: '/version' }).pipe(
            mergeMap((response) => {
                const apiVersion =
                    response.api_version || (response.build && response.build.version) || undefined
                const { proxyVersion, proxyRevision } = this._extractVersion(apiVersion)
                if (proxyVersion) {
                    this.config = {
                        ...this.config,
                        proxyVersion,
                        proxyRevision,
                    }
                }

                this.logger.info(
                    `[Proxy] Fetching proxy fetched performing api calls:: Version= ${apiVersion}`
                )

                return of({
                    proxyVersion,
                    proxyRevision,
                }).pipe(
                    tap(() =>
                        DataHelper.getInstance().storeData(
                            [
                                apiVersion && [DataHelper.STORE_KEY.PROXY_REVISION, apiVersion],
                            ].filter(Boolean)
                        )
                    )
                )
            }),
            catchError((err) => {
                /**
                 * In case of failure, to fetch version, try a fallback mode use cache values
                 * Thus it will reduce the failure rates.
                 * We can easily make the asumption that for ONE application, meaning ONE project that
                 * the version of the proxy will remains the same among all the different URLs
                 */
                return DataHelper.getInstance()
                    .readData([DataHelper.STORE_KEY.PROXY_REVISION])
                    .pipe(
                        mergeMap(() => {
                            const apiVersion = DataHelper.getInstance().getData(
                                DataHelper.STORE_KEY.PROXY_REVISION
                            )
                            if (!apiVersion) {
                                this.logger.error(
                                    `[Proxy] Cannot fetch the api version, including from cache`
                                )
                                return throwError(err)
                            }

                            const { proxyVersion, proxyRevision } = this._extractVersion(apiVersion)
                            if (proxyVersion) {
                                this.config = {
                                    ...this.config,
                                    proxyVersion,
                                    proxyRevision,
                                }
                            }

                            this.logger.info(
                                `[Proxy] Proxy Fetched from CACHE... to avoid API call failure:: Version= ${apiVersion}`
                            )

                            return of({
                                proxyVersion,
                                proxyRevision,
                            })
                        })
                    )
            })
        )
    }

    getUrl(endpoint, version) {
        return version >= 3 ? endpoint.default : endpoint.proxyV1 || endpoint.default || endpoint
    }

    filterParams(params, version, revision) {
        const { PARAMS_TO_FILTER } = Fetch
        const PARAMS_TO_FILTER_BY_VERSION =
            version >= 3 ? PARAMS_TO_FILTER['proxyV3'] : PARAMS_TO_FILTER['proxyV1'] || []

        const {
            paramsFiltered: PARAMS_TO_FILTER_BY_CONFIG = [
                /* By default filter out this parameter for all of our customer except if set from the config
                    This parameter has been introduced for Wow through a wrong initial design :x */
                'episode_expired_out',
            ],
        } = this.getProxyConfig()
        const paramsKeys = Object.keys(params)
        return paramsKeys.reduce((acc, paramKey) => {
            if (PARAMS_TO_FILTER_BY_CONFIG.includes(paramKey)) {
                return acc
            }
            const paramToFilter = PARAMS_TO_FILTER_BY_VERSION.find(
                (param) => param.name === paramKey
            )
            if (!paramToFilter || hasMinimumVersion(paramToFilter.minimumVersion, revision)) {
                acc[paramKey] = params[paramKey]
            }
            return acc
        }, {})
    }

    fetch({ params = {}, endpoint, log = '', requestFilter }) {
        if (requestFilter && requestFilter instanceof RequestFilter) {
            params = {
                ...params,
                ...requestFilter.getProxyParams(),
            }
        }

        return this._resolveVersion().pipe(
            switchMap(({ proxyVersion, proxyRevision }) => {
                if (
                    endpoint?.minVersion &&
                    !hasMinimumVersion(endpoint?.minVersion, proxyRevision)
                ) {
                    return throwError(new VoltError(VoltError.codes.SERVICE_UNAVAILABLE))
                } else {
                    const _url = this.getUrl(endpoint, proxyVersion)
                    const _params = this.filterParams(params, proxyVersion, proxyRevision)
                    return this._fetch({ url: _url, params: _params, log })
                }
            })
        )
    }

    _fetch({ url, params, timeout, log = '' }) {
        const { HTTP_SETTINGS } = Fetch

        // Temp: to remove once https://projects.ifeelsmart.net/browse/MAC-384 is resolved
        // If subscription_id is provided as an empty array we must filter out ALL TVods items
        // we force this behaviour by providing a single id which DO NOT EXISTS
        if (params && Array.isArray(params.subscription_id) && !params.subscription_id.length) {
            params.subscription_id = ['dummyIdToFilterOutAllTvods']
        }

        const proxyUrl = this.buildUrl(url, params)

        const method = 'GET'

        this.logger.debug(`[${log}] HttpRequest ${proxyUrl}`, { method })
        return ajax({
            url: this._parseUrl(proxyUrl),
            async: true,
            crossDomain: true,
            method,
            responseType: HTTP_SETTINGS.RESPONSE_TYPE,
        }).pipe(
            timeoutWith(
                timeout || this.config.timeout.proxy || Constants.fallbackTimeout.proxy,
                throwError(new VoltError(VoltError.codes.REQUEST_TIMEOUT))
            ),
            catchError((err) => {
                this.logger.error(`[${log}] HttpRequest`, {
                    url,
                    method,
                    error: err,
                })

                if (err instanceof VoltError) return throwError(err)

                switch (err.status) {
                    case 404:
                        return throwError(new VoltError(VoltError.codes.HTTP_404))
                    default:
                        return throwError(
                            new VoltError(VoltError.codes.UNKNOWN_API_ERROR, {
                                inheritedError: err,
                            })
                        )
                }
            }),
            map((response) => {
                this.canParseProtectedHeader &&
                    this._saveServerDateAndOffset(this._getServerDate(response))
                this.logger.debug(`[${log}] HttpRequest response`, response && response.response)
                return this.parseMaculosaResponse(response)
            })
        )
    }

    recursiveFetch = ({ params, resultField, endpoint, log = '' }) => {
        let page = 1
        return this.fetch({ endpoint, params: { ...params, page }, log }).pipe(
            mergeMap((response) => {
                // Tricky proxy version is checked during first proxy fetch. Then field to be parsed can only
                // be determined after first fetch result is received
                const resultKey =
                    this.getVersion() >= 3
                        ? (resultField && resultField.default) || 'resources'
                        : (resultField && resultField.proxyV1) || 'contents'

                const pageKey = this.getVersion() >= 3 ? 'page' : 'pages'
                // not a list, simply return the response
                if (!response[pageKey]) {
                    return of(response[resultKey] || [])
                }

                return of(response).pipe(
                    expand((response) => {
                        const pageKey = this.getVersion() >= 3 ? 'page' : 'pages'
                        return response[pageKey].current >= response[pageKey].total
                            ? empty()
                            : this.fetch({
                                  endpoint,
                                  params: { ...params, page: ++page },
                                  log: `${log}_${page}`,
                              }).pipe(
                                  catchError((error) => {
                                      if (error.status !== 404) {
                                          return throwError(error)
                                      }

                                      return of({
                                          [pageKey]: { ...response[pageKey], current: page },
                                          [resultKey]: [],
                                      })
                                  })
                              )
                    }),
                    concatMap((result) => result[resultKey] || []),
                    toArray()
                )
            }),
            catchError((error) => {
                if (error.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(error)
                }

                return of([])
            })
        )
    }

    /**
     * Extract version of the proxy from the response
     * @param {Object} response
     * @returns {Object}
     */
    _extractVersion(apiVersion) {
        let major
        let minor
        let patch
        if (apiVersion && typeof apiVersion === 'string') {
            const version = apiVersion.split('.')
            major = version.length > 0 ? parseInt(version[0], 10) : undefined
            minor = version.length > 1 ? parseInt(version[1], 10) : undefined
            patch = version.length > 2 ? parseInt(version[2].split(/[a-zA-Z]/)[0], 10) : undefined
        }

        return {
            proxyVersion: parseInt(major, 10),
            proxyRevision: {
                major,
                minor,
                patch,
            },
        }
    }

    /**
     * Remove // as security because sometimes Proxy throws an error
     * @param {String} url
     * @returns String
     */
    _parseUrl(url) {
        return url && url.replace(/([^:]\/)\/+/g, '$1')
    }

    /**
     * Gets proxy server request date
     *
     * @param {Object} proxyResponse proxy server response object
     * @returns {Date} proxy server local date
     */
    _getServerDate(proxyResponse) {
        const serverDate =
            proxyResponse?.xhr?.getResponseHeader('Date') ||
            proxyResponse?.xhr?.getResponseHeader('date')
        if (!serverDate) {
            this.logger.warn('Proxy response did not provide server date')
            return 0
        }

        return new Date(serverDate)
    }

    /**
     * Sets proxy server request timestamp and offset for using in different APIs
     *
     * @param {Date|string|number} serverDateTime proxy server date/time retrieved from a response
     * @returns
     */
    _saveServerDateAndOffset(serverDateTime) {
        if (serverDateTime instanceof Date) {
            this.serverLocalTimestamp = serverDateTime.getTime()
            this.serverTimestampOffset = Date.now() - serverDateTime.getTime()
            return
        }

        const serverTimestamp = new Date(serverDateTime).getTime()
        if (serverTimestamp > 0) {
            this.serverLocalTimestamp = serverTimestamp
            this.serverTimestampOffset = Date.now() - serverTimestamp
            return
        }

        this.logger.warn(`Invalid server response Date/Time: ${serverDateTime}`)
    }
}
