import {useContext} from 'solid-js'
import {ConfigContext} from './ConfigContext'
import {ConfigProviderBehavior} from './ConfigProvider'

export function useConfig() {
    const contextValue: ConfigProviderBehavior = useContext(ConfigContext)
    if (!contextValue) {
        throw new Error(
            'could not find redux context value; please ensure the component is wrapped in a <ReduxProvider>'
        )
    }
    return contextValue
}

export default useConfig
