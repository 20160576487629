import googleAnalytics from '@analytics/google-analytics'
import {Analytics, AnalyticsInstance} from 'analytics'

export interface GoogleAnalyticsConfig {
    enabled: boolean
    measurementId: string
}

export class GoogleAnalytics {
    private static _instance: GoogleAnalytics
    public analytics?: AnalyticsInstance

    private constructor(config: {googleAnalyticsConfig: GoogleAnalyticsConfig}) {
        if (config.googleAnalyticsConfig?.enabled) {
            this.analytics = Analytics({
                plugins: [
                    googleAnalytics({
                        measurementIds: [config.googleAnalyticsConfig.measurementId],
                    }),
                ],
            })
        }
    }

    public static getInstance(config: {googleAnalyticsConfig: GoogleAnalyticsConfig}) {
        if (!GoogleAnalytics._instance) {
            GoogleAnalytics._instance = new GoogleAnalytics(config)
            return GoogleAnalytics._instance
        }
        return GoogleAnalytics._instance
    }

    public emit(eventName: string, eventParams?: {[key: string]: any} | undefined) {
        this.analytics && this.analytics.track(eventName, eventParams)
    }
}
