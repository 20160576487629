import { Tile } from './Tile'
import { ProgramDetails } from './ProgramDetails'
import { IProgram, ProgramContent } from '@typings/tile'
import Constants from 'api-constants'

/**
 * @typedef {Object} ProgramProps
 */

/**
 * @typedef {Object} ProgramContent
 * @property {String|Number} id Content id (different from the program id)
 * @property {Boolean} isHD Is the content in High Definition
 * @property {Array<String|Number>} productIds Array of products identifier related to this content
 */

/**
 * Abstract class for any Program
 * @property {Boolean} canWatch=false Is the program watchable. **Important** `false` does not necessarily means that program must be bought/rent. It depends on `hasEntitlementState` which indicate if entitlement has been retrieved (and thus if we can deduce if program is wachable).
 * @property {Boolean} [hasEntitlementState=false]
 * @property {Boolean} [isFavourite=false]
 * @property {Boolean} [isAdult=false]
 * @property {String} country
 * @property {Array} [related=[]]
 * @property {Number} duration Program duration in milliseconds
 * @property {Number} bookmark **To confirm:** Bookmark position in seconds
 * @property {Number} pcValue Program parental control value
 * @property {String} ratingId Program parental control rating id (provided by the proxy)
 * @property {Array} products
 * @property {*} playbacks
 * @property {*} streams
 * @property {String} country
 * @property {Boolean} pending General purpose pending flag
 * @property {Constants.videoFormat} videoFormat Available yet for VOD only but to be extended later
 * @property {Constants.audioFormat} audioFormat Available yet for VOD only but to be extended later
 * @property {Array<ProgramContent>} content A Program could be related to several Contents (mainly for encoding and/or definition purposes). The "content" notion is the entity representing the stream, the playback.
 */
export class Program extends Tile {
    country: string
    duration: number
    bookmark: number
    pcValue: number
    ratingId: string
    canWatch: boolean
    hasEntitlementState: boolean
    isFavourite: boolean
    isAdult: boolean
    related: any[]
    contents: ProgramContent[]
    playbacks: any
    streams: any
    pending: boolean
    details: ProgramDetails
    videoFormat: string
    audioFormat: string
    scheduleType: string

    constructor(props: IProgram) {
        super(props)

        this.country = props.country
        this.duration = props.duration
        this.bookmark = props.bookmark
        this.pcValue = props.pcValue
        this.ratingId = props.ratingId

        const computePropValue = this._makeComputePropValue(props)

        this.canWatch = computePropValue('canWatch', false)
        this.hasEntitlementState = computePropValue('hasEntitlementState', false)
        this.isFavourite = computePropValue('isFavourite', false)
        this.isAdult = computePropValue('isAdult', false)
        this.related = computePropValue('related', [])
        this.contents = computePropValue('contents', [])
        this.playbacks = computePropValue('playbacks', [])
        this.streams = computePropValue('streams', [])
        this.pending = computePropValue('pending', false)
        this.details = computePropValue('details', new ProgramDetails({}))
        this.videoFormat = computePropValue('videoFormat', Constants.videoFormat.V_UNKNOWN)
        this.audioFormat = computePropValue('audioFormat', Constants.audioFormat.A_UNKNOWN)
        this.scheduleType = computePropValue('scheduleType', Constants.scheduleType.original)
    }

    /**
     * @return {Boolean} always `true` since this class represent a Program
     */
    isProgram() {
        return true
    }

    /**
     * Check if program is restricted comparing program parental control value and
     * user parental controle level value.
     *
     *  @example
     *    pcValue    userPcLevel   isRestricted
     *  ----------- ------------- --------------
     *   undefined            -1   false
     *   8                    -1   false
     *   8                     8   true
     *   14                    8   true
     *   8                    14   false
     *
     */
    isRestricted(param?: number | { userPcLevel: number }): boolean
    isRestricted(param?: number | { userPcLevel: number }) {
        // Workaround to keep other plateform usage
        let userPcLevel = -1
        if (typeof param === 'number') userPcLevel = param
        else if (typeof param === 'object') userPcLevel = param.userPcLevel

        if (this.pcValue === undefined || userPcLevel < 0) {
            return false
        }

        return this.pcValue >= userPcLevel
    }

    /**
     * @return {Boolean}
     */
    isRecordable() {
        return false
    }

    isRerun() {
        return this.scheduleType === Constants.scheduleType.rerun
    }
}
