export default (props = {}) => <svg width="48" height="33" viewBox="0 0 48 33" fill="none" xmlns="http://www.w3.org/2000/svg"{...props}>
<g clip-path="url(#clip0_26458_18695)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.5C0 1.25736 1.00736 0.25 2.25 0.25H45.75C46.9926 0.25 48 1.25736 48 2.5V29.5C48 30.7426 46.9926 31.75 45.75 31.75H2.25C1.00736 31.75 0 30.7426 0 29.5V2.5Z" fill="#F4F6F7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.5C0 1.25736 1.00736 0.25 2.25 0.25H45.75C46.9926 0.25 48 1.25736 48 2.5V29.5C48 30.7426 46.9926 31.75 45.75 31.75H2.25C1.00736 31.75 0 30.7426 0 29.5V2.5Z" fill="#E5E5E5" fill-opacity="0.01"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.9701 23.4703C30.0247 23.4899 33.7256 20.1334 33.7256 16.0502C33.7256 11.585 30.0247 8.49852 25.9701 8.5H22.4806C18.3774 8.49852 15 11.5859 15 16.0502C15 20.1343 18.3774 23.4899 22.4806 23.4703H25.9701Z" fill="url(#paint0_linear_26458_18695)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.4936 9.12402C18.7442 9.12521 15.706 12.192 15.7051 15.9771C15.706 19.7615 18.7442 22.8281 22.4936 22.8293C26.2439 22.8281 29.2828 19.7615 29.2833 15.9771C29.2828 12.192 26.2439 9.12521 22.4936 9.12402ZM18.1909 15.9773C18.1945 14.1277 19.3392 12.5505 20.9534 11.9237V20.0299C19.3392 19.4035 18.1944 17.8272 18.1909 15.9773ZM24.0342 20.0316V11.9233C25.649 12.5486 26.7955 14.1267 26.7984 15.9772C26.7955 17.8283 25.649 19.4052 24.0342 20.0316Z" fill="#F4F6F7"/>
</g>
<defs>
<linearGradient id="paint0_linear_26458_18695" x1="37.6115" y1="20.6054" x2="32.8091" y2="4.91132" gradientUnits="userSpaceOnUse">
<stop stop-color="#009BE0"/>
<stop offset="1" stop-color="#006BA8"/>
</linearGradient>
<clipPath id="clip0_26458_18695">
<rect width="48" height="32" fill="white" transform="translate(0 0.25)"/>
</clipPath>
</defs>
</svg>
