import Fetch from './fetch'
import { of, throwError } from 'rxjs'
import { mergeMap, map, catchError, toArray, concatMap } from 'rxjs/operators'
import { chunk, groupBy, sortBy } from 'lodash'
import Utils from '@ifs/volt-utils'

import VoltError from 'VoltError'
import { vodFactory } from 'services/proxy/factories/vod'
import { liveFactory } from 'services/proxy/factories/live'
import { channelFactory } from 'services/proxy/factories/channel'
import { productFactory } from 'services/proxy/factories/subscription'
import { flattenTree, buildNodesMap } from 'services/proxy/factories/vodNodes'
import nodeIds from 'services/emi/config/nodeIds'
import {
    channelsRevisionsFactory,
    channelsRevisionsErrFactory,
} from 'services/proxy/factories/channelsRevisions'
import { Category, ChannelsRevision, Tile } from 'models'
import Constants from 'api-constants'

import {
    plugEpgHoles,
    formatEpgRequestParams,
    orderEpgProgramsByDay,
    getTimestampToTheMinute,
} from './helpers/epg'
import { transformVodTypes, hasMinimumVersion } from './helpers'

import proxyConfig from './config'

import { timestampToIsoDateTimeString } from 'helpers/date'

const RESOURCES = 'resources'
const CONTENTS = 'contents'
const PAGE = 'page'
const PAGES = 'pages'

// Define which endpoint to be used according to proxy version
export const url = {
    vod: { default: '/vod/contents' },
    epg: { proxyV1: '/epg/programs', default: '/epg/schedules' },
    offers: {
        default: '/products',
        proxyV1: '/offers',
    },
    vodNodes: { default: '/vod/nodes' },
    channels: { default: '/epg/channels' },
    chanelsRevisions: {
        default: '/epg/channels/revisions',
        minVersion: { major: 3, minor: 1, patch: 11 },
    },
    epgGenre: { default: 'epg/schedules/genres', proxyV1: '/epg/programs/categories' },
    search: { default: '/search' },
}

// Define specific key to be parsed in proxy response according to type and to proxy version
const resultField = {
    offers: {
        default: RESOURCES,
        proxyV1: 'offers',
    },
    channels: {
        default: RESOURCES,
        proxyV1: 'channels',
    },
    categories: {
        default: RESOURCES,
        proxyV1: 'categories',
    },
}

export default class ProxyApi extends Fetch {
    // List of channel IDs
    _channelIds = []

    /** Important Note ------
     * this init to Date.now is MANDATORY !
     * it avoids to make extra get calls due to false update
     * e.g. it avoids a double getChannels on box start
     * --------------------- */
    _channelsRevisionsTs = Date.now()

    /**
     * Helper used to parsed proxy response according to it's version
     * @param {*} response received from proxy
     * @returns content and pages
     */
    getResult = (response) => {
        const resultField = this.getVersion() >= 3 ? RESOURCES : CONTENTS
        const pageFiled = this.getVersion() >= 3 ? PAGE : PAGES
        return {
            contents: (response && response[resultField]) || [],
            pages: (response && response[pageFiled]) || {
                current: 0,
                total: 0,
                total_items_count: 0,
            },
        }
    }

    /**
     * Fetches tree of VOD nodes and returns it flattened with instances of {@link ContentNode}, {@link NavigationNode}
     * and {@link EmiRequest}.
     *
     * If the highest level node in the tree has no siblings, it is considered the entry point and its children
     * are considered to be the first level in the flattened tree.
     *
     * @returns {Observable<EmiModuleConfiguration>} flattened map and associated requests
     */
    getVodNodes = () => {
        return this.fetch({
            endpoint: url.vodNodes,
            log: 'VOD_NODES',
        }).pipe(
            map((data) => {
                if (this.getVersion() >= 3) {
                    return buildNodesMap(data.resources)
                } else {
                    const rootVod = {
                        id: nodeIds.vod,
                        intlKey: { tag: nodeIds.vod },
                        parent: nodeIds.root,
                        children:
                            data.nodes.length === 1 && data.nodes[0].children
                                ? data.nodes[0].children
                                : data.nodes,
                    }
                    return flattenTree(rootVod)
                }
            }),
            catchError(() =>
                of({
                    navMap: {},
                    requestMap: {},
                })
            )
        )
    }

    /**
     *
     * @param {Object} args
     * @param {number} args.id node identifier
     * @param {number} args.limit
     * @param {number} args.page 0-index based
     * @param {string} args.query
     * @param {string} args.categoryId use as category identifier when building final category
     * @param {Array<string|number>} [args.subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     */
    getVodsFromNode = ({
        id,
        limit,
        page = 0,
        query,
        categoryId,
        subscriptions,
        requestFilter,
    }) => {
        const { defaultNodeOrdering = 'node_pos' } = this.getProxyConfig()
        return this.fetch({
            endpoint: url.vod,
            params: {
                limit,
                page: page + 1,
                query,
                order: defaultNodeOrdering,
                subscription_id: subscriptions,
                subscription_mode: subscriptions && 'tvod_in_svod_out',
                [this.getVersion() >= 3 ? 'node_id' : 'node']: id,
                eligibility: this.config.permitOutOfHomeEligibility,
                episode_expired_out: 'y', // retrieve only series with active episodes
            },
            requestFilter,
            log: 'VOD_FROM_NODE',
        }).pipe(
            map((response) => {
                const { contents, pages } = this.getResult(response)
                const programs = contents.map((x) => vodFactory(x, this.config))

                return {
                    category: new Category({
                        id: categoryId,
                        children: programs.map((x) => x.id),
                        size: pages.total * programs.length,
                        type: 'program',
                        retrieved: pages.current >= pages.total,
                        totalItemsSize: pages.total_items_count,
                    }),

                    programs,
                }
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of({
                    category: new Category({ id: categoryId, retrieved: true }),
                    programs: [],
                })
            })
        )
    }

    /**
     *
     * @param {Object} args
     * @param {number} args.id string, or array of ids
     */
    getSeriesIdFromNodePlatformID = ({ id }) => {
        return this.fetch({
            endpoint: url.vod,
            params: {
                node_platform_id: Array.isArray(id) ? id.toString() : id,
                in_type: 1,
            },
            log: 'SERIESID_FROM_NODE',
        }).pipe(
            map((result) => {
                return result?.resources?.map((r) => r.platform_id) || []
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     *
     * @param {Object} args
     * @param {String} args.nodePlatformId node identifier
     * @param {number} args.limit
     * @param {number} args.page 0-index based
     * @param {string} args.query
     * @param {string} args.categoryId use as category identifier when building final category
     * @param {Array<string|number>} [args.subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @param {String} [args.category] Category of the request
     * @param {String} [args.dataset=''] Dataset related to the content
     * @param {vodTypes} [args.type=[proxyConfig.vodTypes.movie, proxyConfig.vodTypes.series]] Type of vods to be retrieved
     * @param {Object} [args.optaDetails] Parameters for opta API
     * @param {string} args.optaDetails.sportType The name of the sport when content is associated
     *      to Opta Statistics Event (soccer, volley, etc...). Only one sport supported for now: soccer
     * @param {Array<String>|String} [args.optaDetails.gameIds] The name of game ID or Fixture ID from
     *      Opta Server to be able to query detail for a particular Match from the content (EPG/VOD)
     * @param {Array<String>|String} [args.optaDetails.teamIds] The ID of the Team where the sport
     *      event is attached (any game involved multiple teams. Example for Game 1 Arsenal and Manchester)
     * @param {String} [args.optaDetails.competitionCode] The Code of the competition (EPL: English Premier League, WOC: FIFA World Cup, BUN: German Bundesliga, UCL: UEFA Champions League)
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     */
    getVodsFromNodePlatformID = ({
        id,
        node_type,
        category,
        limit = 15,
        page = 0,
        query,
        categoryId,
        subscriptions,
        dataset = '',
        type = [proxyConfig.vodTypes.movie, proxyConfig.vodTypes.series],
        requestFilter,
        optaDetails,
    }) => {
        const { defaultNodeOrdering = 'node_pos' } = this.getProxyConfig()
        const nodeName = node_type === 'parent' ? 'node_parent_platform_id' : 'node_platform_id'
        let params = {
            limit,
            page: page + 1,
            query,
            [nodeName]: id,
            order: defaultNodeOrdering,
            subscription_id: subscriptions,
            subscription_mode: subscriptions && 'tvod_in_svod_out',
            [this.getCategoryKey(category)]: category,
            type: transformVodTypes(type),
            eligibility: this.config.permitOutOfHomeEligibility,
            episode_expired_out: 'y', // retrieve only series with active episodes
        }

        if (optaDetails) {
            if (optaDetails.sportType) params = { ...params, opta_sport: optaDetails.sportType }
            if (optaDetails.gameIds) params = { ...params, opta_fixture_id: optaDetails.gameIds }
            if (optaDetails.teamIds) params = { ...params, opta_team_id: optaDetails.teamIds }
            if (optaDetails.competitionCode)
                params = { ...params, opta_competition_code: optaDetails.competitionCode }
        }
        const mergedParams = dataset ? { ...params, dataset } : params
        return this.fetch({
            endpoint: url.vod,
            params: mergedParams,
            requestFilter,
            log: 'VOD_NODE_PLTF_ID',
        }).pipe(
            map((response) => {
                const { contents, pages } = this.getResult(response)

                const programs = contents.map((x) => vodFactory(x, this.config))

                return {
                    category: new Category({
                        id: categoryId,
                        children: programs.map((x) => x.id),
                        size: pages.total * programs.length,
                        type: 'program',
                        format: programs.some((x) => !x.isEpisode() && !x.isOrphanEpisode())
                            ? Tile.FORMAT.PORTRAIT
                            : Tile.FORMAT.LANDSCAPE,
                        retrieved: pages.current >= pages.total,
                        totalItemsSize: pages.total_items_count,
                    }),
                    programs,
                }
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of({
                    category: new Category({ id: categoryId, retrieved: true }),
                    programs: [],
                })
            })
        )
    }

    /**
     * Returns all episodes for a given serie
     *
     * @param  {String} serieId the serie id
     * @param {Array<string|number>} [subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @param {Number} limit
     * @returns {Object} An object made of keys being the season id and for each key an associated array of episodes
     * { s1Id : [ep1,ep2], s2Id : [ep1,ep2] }
     */
    getVodEpisodesOfSerie(serieId, subscriptions, limit) {
        return this.recursiveFetch({
            endpoint: url.vod,
            params: {
                serie_id: serieId,
                subscription_id: subscriptions,
                subscription_mode: subscriptions && 'tvod_in_svod_out',
                limit: limit || 20,
                type: [proxyConfig.vodTypes.episode],
                eligibility: this.config.permitOutOfHomeEligibility,
                episode_expired_out: 'y', // retrieve only series with active episodes
            },
            log: `VOD_EPISODE_OF_SERIE_${serieId}`,
        }).pipe(
            mergeMap((episodes_) => {
                // Update the episodes by passing theme through the vodFactory
                const episodes = (episodes_ || [])
                    .map((episode) => {
                        const _episode = vodFactory(episode, this.config)
                        return _episode.isEpisode() && !!_episode.id && _episode
                    })
                    .filter(Boolean)

                // Group episodes by seasons
                const episodesGroupedBySeason = groupBy(episodes, 'seasonNumber')

                // Order episodes by number
                const serieSortedByEpisodes = Object.keys(episodesGroupedBySeason).map((seasonId) =>
                    sortBy(episodesGroupedBySeason[seasonId], 'number')
                )

                return of(serieSortedByEpisodes)
            })
        )
    }

    /**
     * Returns seasons programs
     *
     * @param  {String} seriesId the serie id
     * @param {Array<string|number>} [subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @returns {Array} Array of contents
     */
    getVodSeasonsOfSeries(seriesId, subscriptions) {
        return this.recursiveFetch({
            endpoint: url.vod,
            params: {
                serie_id: seriesId,
                subscription_id: subscriptions,
                subscription_mode: subscriptions && 'tvod_in_svod_out',
                limit: 20,
                type: [proxyConfig.vodTypes.season],
                eligibility: this.config.permitOutOfHomeEligibility,
                episode_expired_out: 'y', // retrieve only series with active episodes
            },
            log: `VOD_SEASON_OF_SERIE_${seriesId}`,
        }).pipe(
            map((contents) => {
                return contents
                    .map((season) => {
                        // VOLT-396 (browser): if children_count provided, keep only seasons with children
                        // otherwise (children_count missing) keep the season (backward compatibility)
                        if (!Number.isFinite(season.children_count) || season.children_count > 0) {
                            const _season = vodFactory(season, this.config)

                            return _season.isSeason() && !!_season.id && _season
                        }

                        return null
                    })
                    .filter(Boolean)
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     * Returns episodes programs
     *
     * @param  {Array<String>| string} seasonsId Array of season ids or a string as a single id
     * @param {Array<string|number>} [subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @returns {Array} Array of contents
     */
    getVodEpisodesOfSeason(seasonIds, subscriptions) {
        return this.recursiveFetch({
            endpoint: url.vod,
            params: {
                [`${Array.isArray(seasonIds) ? 'in_' : ''}season_id`]: seasonIds,
                extended_data: 'y',
                subscription_id: subscriptions,
                subscription_mode: subscriptions && 'tvod_in_svod_out',
                limit: 20,
                eligibility: this.config.permitOutOfHomeEligibility,
                episode_expired_out: 'y', // retrieve only series with active episodes
            },
            log: `VOD_EPISODE_OF_SEASON`,
        }).pipe(
            map((contents) => {
                return contents
                    .map((episode) => {
                        const _episode = vodFactory(episode, this.config)

                        return _episode.isEpisode() && !!_episode.id && _episode
                    })
                    .filter(Boolean)
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     * Retrieves the series the given season belongs to
     * @param {String|Number} seasonId
     * @param {Array<string|number>} [subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @returns {Observable<SeriesOfSeason>}
     */
    getSeriesOfSeason(seasonId, subscriptions) {
        return this.getPrograms({
            ids: [seasonId],
            isPlatform: false,
            subscriptions,
        }).pipe(
            mergeMap((result) => {
                const season = result[0]
                if (!season) {
                    return throwError(
                        new VoltError(VoltError.codes.UNHANDLED_API_RESPONSE_STRUCTURE, {
                            extraLog: 'Season not found',
                        })
                    )
                }

                return this.getPrograms({
                    ids: [season.serieId],
                    isPlatform: false,
                    subscriptions,
                }).pipe(
                    map((seriesList) => {
                        return {
                            series: seriesList[0],
                            seasonPlatformId: season.id,
                        }
                    })
                )
            })
        )
    }

    /**
     * filter ici
     *
     * Retrieves VODs of a given category
     * @param {Object} args
     * @param {String} args.category The category name
     * @param {Array<string|number>} [args.subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @param {Number} [args.page=0] The page number (0 based)
     * @param {Number} [args.limit=15] The page size
     * @param {String} [args.dataset=''] Dataset related to the content
     * @param {vodTypes} [args.type=[proxyConfig.vodTypes.movie, proxyConfig.vodTypes.series]] Type of vods to be retrieved
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<ProgramListResponse>} List of {@link Vod} and "done" flag
     */
    getVodsOfCategory({
        category,
        subscriptions,
        page = 0,
        limit = 15,
        dataset = '',
        type = [proxyConfig.vodTypes.movie, proxyConfig.vodTypes.series],
        requestFilter,
    }) {
        const { defaultCategoryOrdering } = this.getProxyConfig()
        const params = {
            [this.getVersion() >= 3 ? 'like_categories' : 'category']: category,
            limit,
            page: page + 1,
            subscription_id: subscriptions,
            subscription_mode: subscriptions && 'tvod_in_svod_out',
            type: Array.isArray(type) ? type : [type],
            eligibility: this.config.permitOutOfHomeEligibility,
            order: defaultCategoryOrdering,
            episode_expired_out: 'y', // retrieve only series with active episodes
        }
        const mergedParams = dataset ? { ...params, dataset } : params
        return this.fetch({
            endpoint: url.vod,
            params: mergedParams,
            requestFilter,
            log: 'VOD_OF_CATEGORY',
        }).pipe(
            map((response) => {
                const { contents, pages } = this.getResult(response)
                const programs = contents.map((x) => vodFactory(x, this.config))

                return {
                    programs,
                    done: pages.current >= pages.total,
                    totalItemsSize: pages.total_items_count,
                }
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of({ programs: [], done: true })
            })
        )
    }

    /**
     * Retrieves all VODs of a given type
     * @param {Object} args
     * @param {Number} [args.page] The page number (default value: 0)
     * @param {Number} [args.limit] The page size (default value: 15)
     * @param {Array<String>|String} args.type The VOD type (one of `Constants.programType.*`)
     * @param {Array<string|number>} [args.subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @param {String} [args.extendedData] when provided, proxy results contain additional data
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<ProgramListResponse>}
     */
    getAllVods = ({ page = 0, limit = 15, type, subscriptions, extendedData, requestFilter }) => {
        const _type = Array.isArray(type)
            ? type.map((x) => proxyConfig.vodTypes[x]).filter((x) => x !== undefined)
            : proxyConfig.vodTypes[type]

        if (!_type && _type !== 0) {
            return throwError(new VoltError(VoltError.codes.INVALID_VOLT_API_ARGUMENT))
        }

        return this.fetch({
            endpoint: url.vod,
            params: {
                limit,
                page: page + 1,
                type: _type,
                subscription_id: subscriptions,
                subscription_mode: subscriptions && 'tvod_in_svod_out',
                eligibility: this.config.permitOutOfHomeEligibility,
                extended_data: extendedData,
                episode_expired_out: 'y', // retrieve only series with active episodes
            },
            requestFilter,
            log: 'ALL VODS',
        }).pipe(
            map((response) => {
                const { contents, pages } = this.getResult(response)
                return {
                    programs: contents.map((x) => vodFactory(x, this.config)),
                    done: pages.current >= pages.total,
                    totalItemsSize: pages.total_items_count,
                }
            })
        )
    }

    /**
     * Retrieves Product Subscription by ID.
     * The REAL NAME of this function should be 'getSubscriptionsByIdWithoutEntitlements'
     * IMPORTANT : Proxy contains only Products/Packs description and does not handle entitlement metadatas,
     * this information should be parsed using external backend.
     * PENDING REFACTOR OF PRODUCT (Moving outside the class entitlements):
     * THIS API SHOULD NOT BE USED DIRECTLY FROM THE UI TO GET OFFERS CONTENT BUT THROUGH PURCHASE API
     * IN ORDER TO RESOLVE ENTITLEMENTS !!!
     * @param {Object} options
     * @param {String|Array<String>} options.ids Array of identifier (proxy identifer or platform identifier)
     * @returns {Observable<Array<Subscription>>} A list of {@link Subscription} products
     */
    getSubscriptionsById = (args = {}) => {
        return this.getProductsById(Constants.productTypes.SUBSCRIPTION, args)
    }
    getProductsById = (productType = Constants.productTypes.SUBSCRIPTION, args = {}) => {
        const { ids, showAll = true } = args
        if (!ids) {
            return of([])
        }
        const platform_ids = Array.isArray(ids) ? ids.join(',') : ids
        let params = { limit: 50, in_platform_id: platform_ids }
        // Fields only supported by proxy v3
        if (this.getVersion() >= 3) {
            if (showAll) {
                params = { ...params, show_all: true }
            }
            if (productType !== Constants.productTypes.ALL) {
                params = { ...params, product_type: productType }
            }
        }
        return this.recursiveFetch({
            endpoint: url.offers,
            params,
            resultField: resultField.offers,
            log: `PRODUCT_BY_ID_${platform_ids}`,
        }).pipe(
            map((response) => {
                return (response || []).map((x) => productFactory(x, this.config, productType))
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     * Retrieves Product Subscription per page.
     * The REAL NAME of this function should be 'getSubscriptionsWithoutEntitlements'
     * IMPORTANT : Proxy contains only Products/Packs description and does not handle entitlement metadatas,
     * this information should be parsed using external backend.
     * PENDING REFACTOR OF PRODUCT (Moving outside the class entitlements):
     * THIS API SHOULD NOT BE USED DIRECTLY FROM THE UI TO GET OFFERS CONTENT BUT THROUGH PURCHASE API
     * IN ORDER TO RESOLVE ENTITLEMENTS !!!
     * @param {Constants.productTypes} [productType] Type of Product
     * @param {Object} args
     * @param {Number} [args.page] The page number (default value: 0)
     * @param {Number} [args.limit] The page size (default value: 15)
     * @param {Number} [args.showAll=true] Show all products including nested ones
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<SubscriptionResponse>} List of {@link Subscription} and "done" flag
     */
    getSubscriptions = (args = {}) => {
        return this.getProducts(Constants.productTypes.SUBSCRIPTION, args)
    }
    getProducts = (
        productType = Constants.productTypes.SUBSCRIPTION,
        { page = 0, limit = 15, showAll = true, requestFilter }
    ) => {
        let params = { limit, page: page + 1 }
        // Fields only supported by proxy v3
        if (this.getVersion() >= 3) {
            if (showAll) {
                params = { ...params, show_all: true }
            }
            if (productType !== Constants.productTypes.ALL) {
                params = { ...params, product_type: productType }
            }
        }
        return this.fetch({
            endpoint: url.offers,
            params,
            requestFilter,
            log: 'PRODUCTS',
        }).pipe(
            map((response) => {
                const resultKey =
                    this.getVersion() >= 3
                        ? resultField.offers.default
                        : resultField.offers.proxyV1 || resultField

                const { pages } = this.getResult(response)
                return {
                    products: (response[resultKey] || []).map((x) =>
                        productFactory(x, this.config, productType)
                    ),
                    done: pages.current >= pages.total,
                    totalItemsSize: pages.total_items_count,
                }
            })
        )
    }

    /**
     * Retrieves ALL Product Subscription
     * The REAL NAME of this function should be 'getAllProductsWithoutEntitlements'
     * IMPORTANT : Proxy contains only Products/Packs description and does not handle entitlement metadatas,
     * this information should be parsed using external backend.
     * PENDING REFACTOR OF PRODUCT (Moving outside the class entitlements):
     * THIS API SHOULD NOT BE USED DIRECTLY FROM THE UI TO GET OFFERS CONTENT BUT THROUGH PURCHASE API
     * IN ORDER TO RESOLVE ENTITLEMENTS !!!
     * @param {Constants.productTypes} [productType] Type of Product
     * @param {Object} args
     * @param {Number} [args.limit] The page size (default value: 50)
     * @param {Number} [args.showAll=true] Show all products including nested ones
     * @param {RequestFilter} [args.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<Array<Subscription>>}
     */
    getAllSubscriptions = (args = {}) => {
        return this.getAllProducts(Constants.productTypes.SUBSCRIPTION, args)
    }
    getAllProducts = (
        productType = Constants.productTypes.SUBSCRIPTION,
        { limit = 50, showAll = true, requestFilter = undefined } = {}
    ) => {
        let params = { limit }
        // Fields only supported by proxy v3
        if (this.getVersion() >= 3) {
            if (showAll) {
                params = { ...params, show_all: true }
            }
            if (productType !== Constants.productTypes.ALL) {
                params = { ...params, product_type: productType }
            }
        }
        return this.recursiveFetch({
            endpoint: url.offers,
            params,
            resultField: resultField.offers,
            requestFilter,
            log: 'ALL_PRODUCTS',
        }).pipe(
            map((response) => {
                if (!response || !response.length) return null

                return response.map((x) => productFactory(x, this.config, productType))
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     * @param {Object} options
     * @param {Array<String|Number>} options.ids Array of identifier (proxy identifer or platform identifier depending on `isPlatform`)
     * @param {Boolean} [options.isPlatform=true] Indicate if provided ids are Platform identifiers (true) or proxy identifier (false)
     * @param {Boolean} [options.isVod=true] Indicate if ids refers to Vod (true) or EPG (false)
     * @param {Array<string|number>} [args.subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs. Only used when `isVod` equals `true`.
     * @param {Array<String>|string} [args.dataset] Array of datasets (string)
     * @param {Date|String|Number} [options.startTime] lower datetime boundary
     * @param {Date|String|Number} [options.endTime] upper datetime boundary
     * @param {String|Array<String>} [options.dataset] dataset (if an array duplicate are remove in this function)
     * @param {Object} [options.catchupParameters] a set of parameters related to catchup parameters
     * @param {Boolean} [options.catchupParameters.watch_again]
     * @param {String} [options.catchUpParameters.order] sorting order
     * @param {String} [options.catchUpParameters.in_channel] The list of comma seperated channel IDs
     * @param {String} [options.catchupParameters.channel] channel ID
     * @param {String} [options.catchupParameters.gt_begin] the lower end of the date range filter (in ISO datetime format)
     * @param {String} [options.catchupParameters.lt_begin] the higher end of the date range filter (in ISO datetime format)
     * @param {RequestFilter} requestFilter Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<Array<Season|Episode|Movie|Live>>}
     */
    _getPrograms = ({
        ids,
        isPlatform = true,
        isVod = true,
        device,
        subscriptions,
        startTime,
        endTime,
        dataset,
        catchupParameters = {},
        requestFilter,
    }) => {
        if (!ids) return of([])
        let idParam
        let limit

        let paramKey = 'id'
        if (isPlatform) {
            paramKey = 'platform_' + paramKey
        }

        if (Array.isArray(ids)) {
            if (!ids.length) return of([])
            paramKey = 'in_' + paramKey
            const encodedIds = ids.map((i) => i.toString().replace(',', ',,'))
            idParam = { [paramKey]: encodedIds.join(',') }
            limit = ids.length
        } else {
            const encodedId = ids.toString().replace(',', ',,')
            idParam = { [paramKey]: encodedId }
            limit = 1
        }

        const params = {
            ...idParam,
            page: 1,
            limit,
            extended_data: 'y',
            ...catchupParameters,
        }
        if (device) {
            params.device = device
        }

        if (isVod) {
            params.episode_expired_out = 'y' // retrieve only series with active episodes
            params.subscription_id = subscriptions
            params.subscription_mode = subscriptions && 'tvod_in_svod_out'
        }
        if (startTime) {
            params.gte_begin = timestampToIsoDateTimeString(startTime, {
                utc: true,
                withTimezone: false,
            })
        }
        if (endTime) {
            params.lte_end = timestampToIsoDateTimeString(endTime, {
                utc: true,
                withTimezone: false,
            })
        }

        const datasets = Array.isArray(dataset)
            ? [...new Set(dataset)].filter(Boolean).join(',')
            : [dataset].filter(Boolean)
        if (datasets.length > 0) {
            params.dataset = datasets
        }

        return this.fetch({
            endpoint: isVod ? url.vod : url.epg,
            requestFilter,
            params,
            log: `PROGRAMS ${isVod ? 'VOD' : 'EPG'}`,
        }).pipe(
            map((response) => {
                const factoryFunc = isVod ? vodFactory : liveFactory
                const { contents } = this.getResult(response)
                const programs = contents.reduce((acc, program) => {
                    acc[isPlatform ? program.platform_id : program.id] = factoryFunc(
                        program,
                        this.config
                    )
                    return acc
                }, {})

                return ids.reduce((acc, id) => {
                    if (programs[id]) acc.push(programs[id])
                    return acc
                }, [])
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     * Fetches a list of programs in batches (of size equal to the limit parameter) retrieved sequentially
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.ids Array of identifier (proxy identifer or platform identifier depending on `isPlatform`)
     * @param {Boolean} [args.isPlatform=true] Indicate if provided ids are Platform identifiers (true) or proxy identifier (false)
     * @param {Boolean} [args.isVod=true] Indicate if ids refers to Vod (true) or EPG (false)
     * @param {Array<string|number>} [args.subscriptions] when provided restrict results to the TVODs
     *      associated to this/these subscription(s) (SVOD are always retrieved). Empty array filter
     *      out all TVODs.
     * @param {Array<String>|string} [args.dataset] Array of dataset
     * @param {Date|String|Number} [args.startTime] lower datetime boundary
     * @param {Date|String|Number} [args.endTime] upper datetime boundary
     * @returns {Observable<Array<Season|Episode|Movie|Live>>}
     */
    getPrograms = (args) => {
        if (!Array.isArray(args.ids)) {
            return this._getPrograms(args)
        }

        const nbItems = args.ids.length
        const batchSize = nbItems < 40 ? 20 : nbItems <= 150 ? 50 : 100
        const _ids = chunk(args.ids, batchSize)

        return of(..._ids).pipe(
            concatMap((batch) =>
                this._getPrograms({
                    ...args,
                    ids: batch,
                })
            ),
            mergeMap((x) => x), // merge arrays of results
            toArray()
        )
    }

    /**
     * Fetch channel information from Proxy service.
     *
     * @param {Object} [args]
     * @param {string} [args.market] market of the user, specific to WOW/Ooredoo (Channel Namespace)
     * @param {string} [args.sourceMode] Array of dataset
     * @param {string} [args.device] device idt
     * @param {string} [args.channelNumber] channel number
     * @param {string} [args.sourceIds] channel source id(s)
     * @param {string} [args.platformIds] channel platform id(s)
     * @param {string} [args.population] emi population
     *
     * @return {Observable<ChannelsData>}
     */
    getChannels({
        sourceMode,
        market,
        device,
        channelNumber,
        platformIds,
        sourceIds,
        population,
    } = {}) {
        let params = { limit: 50 }
        if (sourceMode) {
            params = { ...params, source_mode: sourceMode }
        }
        if (channelNumber) {
            params = {
                ...params,
                number: channelNumber,
            }
        }
        if (sourceIds) {
            params = {
                ...params,
                source_id: (Array.isArray(sourceIds) ? sourceIds : [sourceIds]).join(','),
            }
        }
        if (platformIds) {
            params = {
                ...params,
                platform_id: (Array.isArray(platformIds) ? platformIds : [platformIds]).join(','),
            }
        }
        if (device) {
            params = { ...params, device }
        }
        if (market) {
            params = { ...params, market: market }
        }

        if (population) {
            params = {
                ...params,
                populations: (Array.isArray(population) ? population : [population]).join(','),
            }
        }

        this._channelIds = []
        return this.recursiveFetch({
            endpoint: url.channels,
            params,
            resultField: resultField.channels,
            log: 'CHANNELS',
        }).pipe(
            map((response) => {
                if (!response || !response.length) return null

                return response.reduce((acc, c) => {
                    const channel = channelFactory(c, this.config)
                    acc[channel.id] = channel
                    this._channelIds.push(channel.id)
                    return acc
                }, {})
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of({})
            })
        )
    }

    /**
     * Returns contents matching given criteria.
     *
     * Channel identifier can be given as a single identifier (channelId) or by
     * an array of identifier (channelIds), or even both !
     *
     * @param  {Object} opts
     * @param  {Number|Array<Number>} opts.channelId Channel identifier or an array of channel identifiers
     * @param  {Array<Number>} opts.channelIds Channel identifiers
     * @param  {Number} opts.limit Limit of records per page (linked to the number of pagesavailable)
     * @param  {Number} [opts.startTimeStamp] Match content which begin after this timestamp
     * @param  {Number} [opts.endTimeStamp] Match content which behin before this timestamp
     *
     * @returns {Observable<Array<Live>>} Observable which emit an array of {@link Live}
     */
    getChannelsData({ channelId, limit, startTimeStamp, endTimeStamp }) {
        const params = {
            ...formatEpgRequestParams({
                proxyVersion: this.getVersion(),
                channelIds: channelId,
                boundaries: {
                    gt_end: startTimeStamp,
                    [this.getVersion() >= 3 ? 'lt_start' : 'lt_begin']: endTimeStamp,
                },
            }),
            limit,
        }

        return this.recursiveFetch({
            endpoint: url.epg,
            params,
            log: 'CHANNEL_DATA',
        }).pipe(map((contents) => contents.map((x) => liveFactory(x, this.config))))
    }

    isChannelsRevisionsHandled() {
        const proxyRev = this.getProxyRevision()
        return proxyRev && hasMinimumVersion(url.chanelsRevisions.minVersion, proxyRev)
    }

    /**
     * internal function to get channels revisions data
     */
    _getChannelsRevisions(lastChannelsRevisions) {
        return this.fetch({
            endpoint: url.chanelsRevisions,
            log: 'CHANNEL_REV',
        }).pipe(
            map((channelsRevs) => {
                const data = channelsRevisionsFactory(channelsRevs, lastChannelsRevisions)
                return data
            }),
            catchError((err) => {
                return of(channelsRevisionsErrFactory(err))
            })
        )
    }

    /**
     * Fetch channels revisions informations from Proxy service.
     *
     * @param  {Number|undefined} dateNow : current date time stamp in millisecond
     * @param  {Number|undefined} revisionValidity : interval between two calls to B-E-Proxy in millisecond,
     *
     * @returns {Observable<ChannelsRevision>} Observable with revisions information for channel-list and EPG
     *     */
    getChannelsRevisions({ dateNow = Date.now(), revisionValidity = 15 * 60 * 1000 } = {}) {
        if (this._channelsRevisionsTs < dateNow - revisionValidity) {
            const lastChannelsRevisions = this._channelsRevisionsTs
            this._channelsRevisionsTs = dateNow // avoid repeat while ongoing

            return this._getChannelsRevisions(lastChannelsRevisions)
        }
        const dataBetweenRequest = new ChannelsRevision({
            status: Constants.revisionStatus.CHANNELS_REVISION_EMPTY,
            hasChannelListUpdate: false,
            hasEPGUpdate: false,
        })
        return of(dataBetweenRequest)
    }

    // TODO tests for getOrderedChannelsData()

    /**
     * Retrieves EPG programs for a given date range and for a given list of channels.
     * The result is a list of {@link Live} programs and an organised map of {@link Live} program ids
     * grouped first by day then by {@link Channel} id, and sorted in chronological order.
     * Holes in the EPG schedule are plugged.
     *
     * Channel identifier can be given as a single identifier (channelId) or by
     * an array of identifiers (channelIds), or even both !
     *
     * @param  {Object} opts
     * @param  {Number|Array<Number>} opts.channelId Channel identifier or an array of channel identifiers
     * @param  {Number} opts.limit Limit of results per page (linked to the number of pagesavailable)
     * @param  {Number} [opts.startTimeStamp] Match content which begin after this timestamp
     * @param  {Number} [opts.endTimeStamp] Match content which begin before this timestamp
     *
     * @returns {Observable<OrderedChannelData>} Observable which emits a {@link OrderedChannelData} object
     */
    getOrderedChannelsData({ channelId, limit, startTimeStamp, endTimeStamp }) {
        return this.getChannelsData({
            channelId,
            limit,
            startTimeStamp,
            endTimeStamp,
        }).pipe(
            map((programs) => {
                const programsMap = programs.reduce((acc, program) => {
                    acc[program.id] = program
                    return acc
                }, {})

                const orderedByTimestamp = orderEpgProgramsByDay({
                    programs,
                    startTime: startTimeStamp,
                    endTime: endTimeStamp,
                })

                const orderedByChannel = Object.keys(orderedByTimestamp).reduce(
                    (acc, timestamp) => {
                        acc[timestamp] = orderedByTimestamp[timestamp].reduce(
                            (channelsMap, program) => {
                                if (!channelsMap[program.channelId]) {
                                    channelsMap[program.channelId] = []
                                }

                                channelsMap[program.channelId].push(program.id)

                                return channelsMap
                            },
                            {}
                        )

                        return acc
                    },
                    {}
                )

                const _orderedByChannel = Object.keys(orderedByChannel).reduce((acc, timestamp) => {
                    timestamp = parseInt(timestamp, 10)

                    acc[timestamp] = Object.keys(orderedByChannel[timestamp]).reduce(
                        (channels, channelId) => {
                            channels[channelId] = plugEpgHoles(
                                orderedByChannel[timestamp][channelId],
                                programsMap,
                                channelId,
                                timestamp,
                                Utils.date.getEndTime(timestamp)
                            )
                            return channels
                        },
                        {}
                    )
                    return acc
                }, {})

                return {
                    ids: _orderedByChannel,
                    data: Object.values(programsMap),
                }
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of({ ids: {}, data: [] })
            })
        )
    }

    /**
     * Retrieves the genres of all the programs for a list of channels for a given date range
     *
     * @param {Object} params
     * @param {String|Number|Array<String|Number>} params.channelIds channel ID or list of channel IDs
     * @param {Number} params.startTimeStamp The start time in timestamp format
     * @param {Number} params.endTimeStamp The end time in timestamp format
     * @returns {Observable<Array<String>>} Observable emitting a list of genres
     */
    getAllEpgGenresOfDate({ channelIds, startTimeStamp, endTimeStamp }) {
        return this.recursiveFetch({
            endpoint: url.epgGenre,
            params: formatEpgRequestParams({
                proxyVersion: this.config.proxyVersion,
                channelIds,
                boundaries: {
                    gt_end: getTimestampToTheMinute(startTimeStamp),
                    [this.getVersion() >= 3 ? 'lt_start' : 'lt_begin']:
                        getTimestampToTheMinute(endTimeStamp),
                },
            }),
            resultField: resultField.categories,
            log: 'EPG_GENRES',
        }).pipe(
            map((result) => {
                return this.getVersion() >= 3 ? result.map((g) => g.title) : result
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                return of([])
            })
        )
    }

    /**
     * Retrieves all the programs of a given genre for a list of channels for a given date range
     *
     * @param {Object} params
     * @param {String|Number|Array<String|Number>} params.channelIds channel ID or list of channel IDs
     * @param {String} params.genre the genre
     * @param {Number} params.limit the page size
     * @param {Number} params.startTimeStamp The start time in timestamp format
     * @param {Number} params.endTimeStamp The end time in timestamp format
     * @returns {Observable<Array<Live>>} Observable emitting a list of {@link Live} programs
     */
    getEpgProgramsOfGenre = ({ channelIds, genre, limit, startTimeStamp, endTimeStamp }) => {
        const params = {
            ...formatEpgRequestParams({
                proxyVersion: this.getVersion(),
                channelIds,
                boundaries: {
                    gt_end: getTimestampToTheMinute(startTimeStamp),
                    [this.getVersion() >= 3 ? 'lt_start' : 'lt_begin']:
                        getTimestampToTheMinute(endTimeStamp),
                },
            }),
            limit,
            [this.getVersion() >= 3 ? 'like_categories' : 'category']: genre,
        }

        return this.recursiveFetch({
            endpoint: url.epg,
            params,
            log: 'EPG_PROGRAM_OF_GENRE',
        }).pipe(map((result) => result.map((x) => liveFactory(x, this.config))))
    }

    /**
     * Retrieves the programs broadcasting at a given time for a given list of channels
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel IDs
     * @param {Number} args.timestamp Timestamp used as comparison value
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<Array<String|Number>>} The list of live programs
     */
    _getLiveAt = ({ channelIds, timestamp, requestFilter, log = 'LIVE_AT' }) => {
        const proxyVersion = this.getVersion()
        const params = {
            ...formatEpgRequestParams({
                proxyVersion: proxyVersion,
                channelIds,
                boundaries: {
                    [proxyVersion >= 3 ? 'lte_start' : 'lte_begin']: new Date(timestamp).getTime(),
                    gt_end: timestamp,
                },
            }),
            limit: channelIds.length,
        }

        return this.fetch({
            endpoint: url.epg,
            params,
            requestFilter,
            log: `${log} TS: ${timestamp} for ${
                Array.isArray(channelIds) ? channelIds.length : channelIds
            } Channels`,
        }).pipe(
            map((response) => {
                const { contents } = this.getResult(response)
                return {
                    programs: contents.map((x) => liveFactory(x, this.config)),
                    channelIds,
                }
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                // Return an empty array to simulate "no identifiers"
                return of({ programs: [], channelIds })
            })
        )
    }

    /**
     * Retrieves the programs broadcasting at a given range for a given list of channels
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel IDs
     * @param {Number} start The start of the range
     * @param {Number} end The end of the range
     * @returns {Observable<Array<String|Number>>} The list of live programs
     */
    _getLiveBetween = ({ channelIds, start, end, log = 'LIVE_BETWEEN' }) => {
        const proxyVersion = this.getVersion()
        const params = {
            ...formatEpgRequestParams({
                proxyVersion,
                channelIds,
                boundaries: {
                    [proxyVersion >= 3 ? 'lte_start' : 'lte_begin']: end,
                    gt_end: start,
                },
            }),
            limit: 200,
        }
        return this.recursiveFetch({
            endpoint: url.epg,
            params,
            log: `${log} : [${start} - ${end}] for ${
                Array.isArray(channelIds) ? channelIds.length : channelIds
            } Channels`,
        }).pipe(
            mergeMap((programs) => {
                return of({
                    programs: programs.map((x) => liveFactory(x, this.config)),
                    channelIds,
                })
            }),
            catchError((err) => {
                if (err.code !== VoltError.codes.HTTP_404.code) {
                    return throwError(err)
                }
                // Return an empty array to simulate "no identifiers"
                return of({ programs: [], channelIds })
            })
        )
    }

    /**
     * Progressively retrieves the programs broadcasting at a given time for a given list of channels, in chunks.
     * The result is not returned all at once but in batches (multiple emitted values).
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel proxy IDs
     * @param {Number} args.timestamp Timestamp used as comparison value
     * @param {Number} [args.batchSize] The maximum chunk size (= the number of channel IDs in a single request)
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {ProgressiveLiveAtResult}
     */
    getProgressiveLiveAt = ({
        channelIds,
        timestamp,
        batchSize = 50,
        requestFilter,
        log = 'PROGRESSIVE_LIVE_AT',
    }) => {
        const _channelIds = chunk(channelIds, batchSize)

        const lastPage = _channelIds.length - 1

        return of(..._channelIds).pipe(
            concatMap((batch) =>
                this._getLiveAt({
                    channelIds: batch,
                    timestamp,
                    requestFilter,
                    log,
                })
            ),
            map(({ programs, channelIds: batch }, i) => {
                return {
                    programs: programs.reduce((acc, p) => {
                        acc[p.channelId] = p
                        return acc
                    }, {}),
                    allPrograms: programs,
                    channelIds: batch,
                    done: i >= lastPage,
                }
            })
        )
    }

    /**
     * Progressively retrieves the currently broadcasting programs
     * included in a range delimited by timestamp 'start' to timestamp 'end' of a given list of channels, in chunks.
     * The result is not returned all at once but in batches (multiple emitted values).
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel proxy IDs
     * @param {Number} start The start of the range
     * @param {Number} end The end of the range
     * @param {Number} [args.batchSize] The maximum chunk size (= the number of channel IDs in a single request)
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {ProgressiveLiveAtResult}
     */
    getLiveBetween = ({ channelIds, start, end, batchSize = 50, log = 'LIVE_BETWEEN' }) => {
        const _channelIds = chunk(channelIds, batchSize)
        const lastPage = _channelIds.length - 1
        return of(..._channelIds).pipe(
            concatMap((batch) =>
                this._getLiveBetween({
                    channelIds: batch,
                    start,
                    end,
                    log,
                })
            ),
            map(({ programs, channelIds }, i) => {
                return {
                    allPrograms: programs,
                    channelIds,
                    done: i >= lastPage,
                }
            })
        )
    }
    /**
     * Progressively retrieves the currently broadcasting programs of a given list of channels, in chunks.
     * The result is not returned all at once but in batches (multiple emitted values).
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel proxy IDs
     * @param {Number} [args.batchSize] The maximum chunk size (= the number of channel IDs in a single request)
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {ProgressiveLiveAtResult}
     */
    getProgressiveLiveNow = ({ channelIds, batchSize = 50, requestFilter }) => {
        return this.getProgressiveLiveAt({
            channelIds,
            timestamp: getTimestampToTheMinute(),
            batchSize,
            requestFilter,
            log: 'LIVE_NOW',
        })
    }

    /**
     * Progressively retrieves the broadcasting programs tonight of a given list of channels, in chunks.
     * The result is not returned all at once but in batches (multiple emitted values).
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel proxy IDs
     * @param {Number} [args.batchSize] The maximum chunk size (= the number of channel IDs in a single request)
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {ProgressiveLiveAtResult}
     */
    getProgressiveLiveTonight = ({ channelIds, batchSize = 50, requestFilter }) => {
        const { eveningTime = 21 } = this.config

        let eveningDate = new Date()
        eveningDate.setDate(eveningDate.getDate())
        eveningDate.setHours(eveningTime, 0, 0, 0)

        return this.getProgressiveLiveAt({
            channelIds,
            timestamp: eveningDate.getTime(),
            batchSize,
            requestFilter,
            log: 'LIVE_TONIGHT',
        })
    }

    /**
     * Retrieves the programs broadcasting at a given time for a given list of channels, in chunks
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel proxy IDs
     * @param {Number} args.timestamp Timestamp used as comparison value
     * @param {Number} [args.batchSize] The maximum chunk size (= the number of channel IDs in a single request)
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {LiveProgramByChannel} The list of live programs indexed by channelId
     */
    getLiveAt = ({ channelIds, timestamp, batchSize = 50, requestFilter, log = 'LIVE_AT' }) => {
        const _channelIds = chunk(channelIds, batchSize)
        return of(..._channelIds).pipe(
            concatMap((batch) =>
                this._getLiveAt({ channelIds: batch, timestamp, requestFilter, log })
            ),
            mergeMap((x) => x.programs), // merge arrays of results
            toArray(),
            map((programs) =>
                programs.reduce((acc, p) => {
                    acc[p.channelId] = p
                    return acc
                }, {})
            )
        )
    }

    /**
     * Retrieves the programs broadcasting at a given time for a given list of channels, in chunks
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.platformChannelIds Channel platform IDs
     * @param {String} args.category The category name
     * @param {Number} [args.page=0] The page number (0 based)
     * @param {Number} [args.limit=15] The page size
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {LiveProgramByChannel} The list of live programs indexed by channelId
     */
    getCatchupTV = ({ platformChannelIds, category, page = 0, limit = 15, requestFilter }) => {
        const proxyVersion = this.getVersion()
        let pastDays
        if (
            this.config.epg &&
            this.config.epg.numOfDaysPast &&
            typeof this.config.epg.numOfDaysPast === 'number'
        ) {
            pastDays = new Date()
            pastDays.setDate(pastDays.getDate() - this.config.epg.numOfDaysPast)
        }

        let params = {
            ...formatEpgRequestParams({
                proxyVersion: proxyVersion,
                platformChannelIds,
                boundaries: {
                    [proxyVersion >= 3 ? 'lte_start' : 'lte_begin']: getTimestampToTheMinute(),
                    [proxyVersion >= 3 ? 'gt_start' : 'gt_begin']:
                        getTimestampToTheMinute(pastDays),
                },
            }),
            watch_again: true,
            [this.getVersion() >= 3 ? 'like_categories' : 'category']: category,
            order: this.getVersion() >= 3 ? 'start_desc' : 'begin_desc',
            page: page + 1,
            limit,
        }
        if (proxyVersion >= 3) {
            // Field not supported in V1
            params = {
                ...params,
                watch_again: true,
            }
        }

        return this.fetch({
            endpoint: url.epg,
            params,
            requestFilter,
            log: `CATCHUPTV`,
        }).pipe(
            map((response) => {
                const { contents, pages } = this.getResult(response)
                return {
                    programs: contents.map((x) => liveFactory(x, this.config)),
                    done: pages.current >= pages.total,
                    totalItemsSize: pages.total_items_count,
                }
            })
        )
    }

    /**
     * Retrieves catchup programs metadata by its ids
     *
     * @param {Array<String>} programIds program ids
     * @returns
     */
    getCatchupTvPrograms(programIds) {
        const proxyVersion = this.getVersion()
        let pastDays
        if (
            this.config.epg &&
            this.config.epg.numOfDaysPast &&
            typeof this.config.epg.numOfDaysPast === 'number'
        ) {
            pastDays = new Date()
            pastDays.setDate(pastDays.getDate() - this.config.epg.numOfDaysPast)
        }
        let catchupParameters = {
            ...formatEpgRequestParams({
                proxyVersion: proxyVersion,
                boundaries: {
                    [proxyVersion >= 3 ? 'lte_start' : 'lte_begin']: getTimestampToTheMinute(),
                    [proxyVersion >= 3 ? 'gt_start' : 'gt_begin']:
                        getTimestampToTheMinute(pastDays),
                },
            }),
            watch_again: true,
            order: proxyVersion >= 3 ? 'start_desc' : 'begin_desc',
        }

        return this.getPrograms({
            ids: programIds,
            isVod: false,
            catchupParameters,
        })
    }

    /**
     * Retrieves the genres of all the programs for a list of channels for a given date range
     *
     * @param {Object} params
     * @param {String|Number|Array<String|Number>} params.channelIds channel ID or list of channel IDs
     * @param {Number} params.startTimeStamp The start time in timestamp format
     * @param {Number} params.endTimeStamp The end time in timestamp format
     * @param {RequestFilter} [params.requestFilter] Filtering parameter that help to filter, sort and limit proxy query
     * @returns {Observable<Array<String>>} Observable emitting a list of genres
     */
    getLiveToday({ platformChannelIds, category, page = 0, limit = 15, requestFilter }) {
        const todayMidnight = new Date()
        todayMidnight.setHours(24, 0, 0, 0) // next midnight
        const params = {
            ...formatEpgRequestParams({
                proxyVersion: this.getVersion(),
                platformChannelIds,
                boundaries: {
                    [this.getVersion() >= 3 ? 'gte_start' : 'gte_begin']: getTimestampToTheMinute(),
                    lte_end: todayMidnight.getTime(), // Already bounded to the minute
                },
            }),
            [this.getVersion() >= 3 ? 'like_categories' : 'category']: category,
            order: this.getVersion() >= 3 ? 'start' : 'begin',
            page: page + 1,
            limit,
        }

        return this.fetch({
            endpoint: url.epg,
            params,
            requestFilter,
            log: 'LIVE TODAY',
        }).pipe(
            map((response) => {
                const { contents, pages } = this.getResult(response)
                return {
                    programs: contents.map((x) => liveFactory(x, this.config)),
                    done: pages.current >= pages.total,
                    totalItemsSize: pages.total_items_count,
                }
            })
        )
    }

    /**
     * Retrieves the currently broadcasting programs of a given list of channels, in chunks
     *
     * @param {Object} args
     * @param {Array<String|Number>} args.channelIds Channel proxy IDs
     * @param {Number} [args.batchSize] The maximum chunk size (= the number of channel IDs in a single request)
     * @returns {LiveProgramByChannel} The list of live programs indexed by channelId
     */
    getLiveNow = ({ channelIds, batchSize = 50 }) => {
        return this.getLiveAt({
            channelIds,
            timestamp: getTimestampToTheMinute(),
            batchSize,
            log: 'LIVE_NOW',
        })
    }

    /**
     * Tells if the proxy is available (fetch the proxy version)
     * @returns {Boolean}
     */

    isProxyAvailable = () => {
        return this._fetch({
            url: '/version',
            timeout: this.config.degradedMode.bootTimeout,
        }).pipe(
            mergeMap((response) => {
                const proxyVersion =
                    response.api_version || (response.build && response.build.version)

                let major
                let minor
                let patch
                if (proxyVersion && proxyVersion.split('.').length) {
                    major = proxyVersion.split('.')[0]
                    minor = proxyVersion.split('.')[1]
                    patch = proxyVersion.split('.')[2]
                }

                this.config = {
                    ...this.config,
                    proxyVersion:
                        proxyVersion &&
                        proxyVersion.split('.').length &&
                        proxyVersion.split('.')[0],
                    proxyRevision: {
                        major,
                        minor,
                        patch,
                    },
                }
                this.logger.info(`[PROXY] version ${proxyVersion}`)
                return of(!!proxyVersion)
            }),
            catchError((error) => {
                this.logger.error(`[PROXY] HttpRequest Error proxy not available`, {
                    error,
                })
                return of(false)
            })
        )
    }

    //#region ---------- EPL (English Premier League) ----------
    /**
     * Gets upcoming games EPGs
     *
     * @param {Object} args
     * @param {String} args.sportType - type of sport for getting games (only 'soccer' for now)
     * @param {Array<String>} args.[gameIds] - game ids for getting EPGs only for specific games
     * @param {Array<String>} args.[teamIds] - team ids for getting EPGs only for specific teams
     * @param {String} [args.competitionCode] The ID of the competition (EPL: English Premier League, WOC: FIFA World Cup, BUN: German Bundesliga, UCL: UEFA Champions League)
     * @param {Number} args.limit - page size
     * @param {Number} args.page - page number
     * @returns
     */
    getProgramGames = ({
        sportType,
        gameIds,
        teamIds,
        competitionCode,
        scheduleType = Constants.scheduleType.original,
        limit = 15,
        page = 0,
    }) => {
        if (!sportType && !gameIds && !teamIds && !competitionCode) {
            // Do not trigger the EPG request otherwise it will lead to all EPG display (65K)
            this.logger.warn(
                `[PROXY] getProgramGames missing parameters, do not trigger the request...`
            )
            return of({
                programs: [],
                done: true,
                totalItemsSize: 0,
            })
        }

        const params = {
            ...formatEpgRequestParams({
                proxyVersion: this.getVersion(),
                boundaries: {
                    gt_end: getTimestampToTheMinute(),
                },
            }),
            order: this.getVersion() >= 3 ? 'start' : 'begin',
            page: page + 1,
            limit,
            opta_sport: sportType,
            opta_fixture_id: gameIds,
            opta_team_id: teamIds,
            opta_competition_code: competitionCode,
            schedule_type: scheduleType,
        }

        return this.fetch({
            endpoint: url.epg,
            params,
            log: '[GETTING_UPCOMING_GAMES]',
        }).pipe(
            mergeMap((response) => {
                const { contents = [], pages = 0 } = this.getResult(response)
                return of({
                    programs: contents.map((program) => liveFactory(program, this.config)),
                    done: pages.current >= pages.total,
                    totalItemsSize: pages.total_items_count,
                })
            })
        )
    }
    //#endregion
}

/**
 * @typedef {Object} ChannelsData
 * @property {Object} hash of {@link Channel} indexed by their id
 */

/**
 * @typedef {Object<string, Array<String|Number>} ProgramsByChannel Map of programs platform identifiers indexed by channel proxy id
 */

/**
 * @typedef {Object<string, Live>} LiveProgramByChannel instance of {@link Live} programs indexed by channel proxy id
 */

/**
 * @typedef {Object} OrderedChannelData
 * @property {Array<Live>} data Array of {@link Live} programs
 * @property {Object<number,ProgramsByChannel>} ids Map indexed by timestamp
 */

/**
 * @typedef {Object} SeriesOfSeason
 * @property {TvShow} series The series the Season belongs to
 * @property {String|Number} seasonPlatformId The platform id of the given season
 */

/**
 * @typedef {Object} ProgressiveLiveAtResult
 * @property {LiveProgramByChannel} programs Map of live programs indexed by channelId
 * @property {Array<String|Number>} channels The list of IDs of the channels the returned live programs belong to
 * @property {Boolean} done
 */

/**
 * @typedef {Object} SubscriptionResponse
 * @property {Array<Programs>} products The list of related products (instances of {@link Subscription})
 * @property {Boolean} done Flag indicating whether there are more results (true: request completed)
 */
