import {GoogleAnalytics} from './GoogleAnalytics'
import {Firebase} from './firebase'
import {userConsentGranted} from './userConsentRequired'

const instances = {
    googleAnalytics: GoogleAnalytics,
    firebase: Firebase,
}

export const analyticsInstance = config => {
    const analyticsImplementation = instances[config?.analyticsInstanceType]

    if (!analyticsImplementation) {
        return () => {}
    }

    if (userConsentGranted()) {
        // The user has already given consent
        return analyticsImplementation.getInstance(config)
    }
    return null
}
